import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageUploader from 'react-images-upload';

import initData from '../../../utils/initData';
import RequiredIndicator from '../UsersManagement/RequiredIndicator';
import plusIcon from '../../../assets/icons/icon_plus.svg';
import helpIcon from '../../../assets/icons/help_icon.svg';
import ApiData from '../../../utils/Api';

import '../UsersManagement/ProfileForm.scss';
import './BrandForm.scss';
import FormSelect from '../../Form/FormSelect';
import hasPermission from '../../../utils/Permissions';
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";

export default class BrandForm extends Component {
    static propTypes = {
        cancelForm: PropTypes.func.isRequired,
        createBrand: PropTypes.func.isRequired,
        editMode: PropTypes.string.isRequired,
        fieldValues: PropTypes.object,
    }

    constructor(props) {
        super(props);
        const addBrandData = initData.getBrandData();

        const { form: { decipherName, displayName, decipherId, logo, industry }} = addBrandData;
        const fieldValues = this.props.fieldValues;

        this.state = {
            [decipherName.key]: fieldValues ? fieldValues[decipherName.key] : '',
            [displayName.key]: fieldValues ? fieldValues[displayName.key] : '',
            [decipherId.key]: fieldValues ? fieldValues[decipherId.key] : '',
            [logo.key]: fieldValues ? fieldValues[logo.key] : '',
            [industry.key]: fieldValues ? fieldValues[industry.key] : '',
            addBrandData,
            newLogo: null,
            industryList:[],
            fieldAttributes: {
                [decipherName.key]: {
                    required: true,
                    unlocked: false,
                    regex: /[^0-9a-zA-Z`@#$%&è*.,\-';+\s]/g,
                    message: "Only ASCII characters are allowed. Reach out to the Operations team for any brand name changes."
                }
            },
            validationMessages: {}
        }
    }

    componentDidUpdate(prevProps) {
        const { form: { decipherName }} = this.state.addBrandData;

        if (prevProps.editMode !== this.props.editMode) {
            this.setState((prevState) => ({
                fieldAttributes: {
                    ...prevState.fieldAttributes,
                    [decipherName.key]: {
                        ...prevState.fieldAttributes[decipherName.key],
                        unlocked: false,
                    }
                }
            }));
        }
    }

    initializeFormFields = () => {
        const { form: { decipherName, displayName, decipherId, logo, industry }} = this.state.addBrandData;
        const fieldValues = this.props.fieldValues;

        this.setState(prevState => ({
            [decipherName.key]: fieldValues ? fieldValues[decipherName.key] : '',
            [displayName.key]: fieldValues ? fieldValues[displayName.key] : '',
            [decipherId.key]: fieldValues ? fieldValues[decipherId.key] : '',
            [logo.key]: fieldValues ? fieldValues[logo.key] : '',
            [industry.key]: fieldValues ? fieldValues[industry.key] : '',
            newLogo: null,
            validationMessages: {},
            fieldAttributes: {
                ...prevState.fieldAttributes,
                [decipherName.key]: {
                    ...prevState.fieldAttributes[decipherName.key],
                    unlocked: false,
                }
            }
        }));
    }

    static getDerivedStateFromProps(props, state) {
        const { fieldValues } = props;
        const { form: { decipherName, displayName, decipherId, logo, industry }} = state.addBrandData;

        if(props.editMode === state.addBrandData.modes.profileDisplay && fieldValues && fieldValues[decipherId.key] !== state[decipherId.key]) {
            return {
                [decipherName.key]: fieldValues && fieldValues[decipherName.key] ? fieldValues[decipherName.key] : '',
                [displayName.key]: fieldValues && fieldValues[displayName.key] ? fieldValues[displayName.key] : '',
                [decipherId.key]: fieldValues && fieldValues[decipherId.key] ? fieldValues[decipherId.key]  : '',
                [logo.key]: fieldValues && fieldValues[logo.key] ? fieldValues[logo.key] : '',
                [industry.key]: fieldValues && fieldValues[industry.key] ? fieldValues[industry.key] : '',
                fieldAttributes: {
                    ...state.fieldAttributes,
                    [decipherName.key]: {
                        ...state.fieldAttributes[decipherName.key],
                        unlocked: false,
                    }
                }
            }
        } else return null;
    }

    componentDidMount() {
        ApiData.getIndustryList().then(
            (result) => {
                if(result) {
                    const industryList = result.industries.map(industry => ({
                        value: industry.id,
                        label: industry.name,
                    }));
                    this.setState({
                        industryList: industryList,
                    });
                }
            }
        ).catch(
            (err) => {
                console.log("get industry data error", err);
            }
        );
    }

    validateInputOnChange = (e, inputKey) => {
        const { value } = e.target;
        const fieldAttribute = this.state.fieldAttributes[inputKey];
        let validationMessage = '';

        if (fieldAttribute) {
            if (fieldAttribute.required && !value) {
                validationMessage = "Required Field";
            }

            if (fieldAttribute.regex && fieldAttribute.regex.test(value)) {
                validationMessage = fieldAttribute.message;
            }
        }

        this.setState({
            [inputKey]: value,
            validationMessages: {
                ...this.state.validationMessages,
                [inputKey]: validationMessage,
            }
        });
    }

    inputChange = (e, inputKey) => {
        this.setState({[inputKey]: e.target.value});
    }

    createBrand = () => {
        const { addBrandData: { form: { decipherName, displayName, industry }}} = this.state;
        const brand = {
            name: this.state[decipherName.key],
            displayName: this.state[displayName.key],
            imageData: this.state.newLogo ? this.state.newLogo : '',
            industry:this.state[industry.key]?.value,
        };

        const { createBrand } = this.props;
        if (this.validateFormFields() && typeof createBrand === 'function') {
            createBrand(brand, this.state);
        }
    }

    handleSelect = (e, inputKey) => {
        this.setState({[inputKey]: e});
    }

    validateFormFields = () => {
        const { addBrandData: { form: { decipherName }}, fieldAttributes} = this.state;
        let validationMessages = {};

        if (!this.state[decipherName.key]) {
            validationMessages[decipherName.key] = "Required Field";
        }

        if (fieldAttributes[decipherName.key].regex && fieldAttributes[decipherName.key].regex.test(this.state[decipherName.key])) {
            validationMessages[decipherName.key] = fieldAttributes[decipherName.key].message;
        }

        return Object.keys(validationMessages).length === 0;
    }

    clearValidationMessage = (inputKey) => {
        this.setState({
            validationMessages: {
                ...this.state.validationMessages,
                [inputKey]: '',
            }
        });
    }

    handleUploadLogo = (e) => {
        try {
          let reader = new FileReader();
          let file = e[e.length - 1];
          reader.onloadend = () => {
            this.setState({
                newLogo: reader.result,
            })
          };
          reader.readAsDataURL(file);
        } catch (err) {

        }
    }

    uploadLogo = () => {
        this.setState({isLogoChange:true, isLogoChangeValue:'profile_update'})
        const logoButton = document.querySelector('.chooseFileButton');
        logoButton && logoButton.click();
    }

    onCancelClick = () => {
        const { cancelForm } = this.props;

        this.initializeFormFields();
        cancelForm && cancelForm();
    }

    unlockField = (fieldKey) => {
        if (!hasPermission()) {
           return;
        }

        this.setState((prevState) => ({
            fieldAttributes: {
                ...prevState.fieldAttributes,
                [fieldKey]: {
                    ...prevState.fieldAttributes[fieldKey],
                    unlocked: !prevState.fieldAttributes[fieldKey].unlocked,
                }
            }
        }));
    }

    isFieldUnlocked = (fieldKey) => {
        return this.state.fieldAttributes[fieldKey].unlocked;
    }
  
    render() {
        const { validationMessages, addBrandData, newLogo, industryList, isLogoChange } = this.state;
        const { form: { decipherName, decipherId, displayName, logo, industry }, buttons, modes, inputMessages } = addBrandData;
        const {
            editMode,
        } = this.props;

        const cursor = editMode === modes.profileDisplay ? 'initial' : 'pointer';
        const logoStyle = newLogo ?
            {backgroundImage : `url("${newLogo}")`, cursor, backgroundSize: 'cover'} :
            this.state[logo.key] ? {backgroundImage: `url("${this.state[logo.key]}")`, cursor, backgroundSize: 'cover'} : { cursor };

        const uploadButtonStyle = {
            width: 350,
            height: 350,
            alignItems: "center",
            backgroundColor: editMode === modes.profileDisplay? '#F3F4F7' : 'transparent',
            color: "#0062FF",
            borderRadius: 0,
            boxShadow: "none",
            padding: 0,
            margin: 0,
            zIndex: 5,
            cursor,
        };
        const uploadContainerStyle = {
            height: 350,
            width: 350,
            border: '1px solid #DFE2EA',
            borderRadius: 0,
            backgroundColor: ( (editMode === 'profile_update'|| editMode === 'create') || !isLogoChange) ? '#F3F4F7':'#FFFFFF',
            padding: 0,
            margin: 0,
        };
        const LogoComponent = () => <div className="form-image-wrapper" style={logoStyle}/>;
        const isSubmitBtnDisabled = !this.validateFormFields();

        return (
            <div className={'profileForm'} style={{display: "flex", flexDirection: "column", width: "40%"}}>
                <div className="form-container">

                    <div className="form-group">
                        <div className="form-label-wrapper">
                            <label
                                htmlFor={decipherName.key}
                                className="form-label">{decipherName.label}<RequiredIndicator/>
                            </label>
                            <span className="form-icon">
                                <img src={helpIcon} alt=""/>
                            </span>
                        </div>
                        <div className="form-input-wrapper">
                            <div className={"form-input-block"}>
                                <input
                                    className={`form-input ${this.state.validationMessages[decipherName.key] ? 'warning' : ''}`}
                                    type="text"
                                    id={decipherName.key}
                                    placeholder={decipherName.placeholder}
                                    value={this.state[decipherName.key]}
                                    onChange={(e) => this.validateInputOnChange(e, decipherName.key)}
                                    disabled={(editMode === modes.profileDisplay) || (editMode === modes.profileUpdate && !this.isFieldUnlocked(decipherName.key))}
                                />
                                {
                                    (editMode === modes.profileUpdate)
                                        &&
                                            <span
                                                className={`form-input-icon--overlay ${ !hasPermission() ? 'override-disabled' : '' }`}
                                                onClick={(e) => this.unlockField(decipherName.key)}>
                                                    {
                                                        this.isFieldUnlocked(decipherName.key)
                                                            ? <CiUnlock className="icon" size="1.5em"/>
                                                            : <CiLock className="icon" size="1.5em"/>
                                                    }
                                            </span>
                                }
                            </div>
                            {
                                validationMessages[decipherName.key]
                                &&
                                <small className="form-input-message warning">
                                    {validationMessages[decipherName.key]}
                                </small>
                            }
                        </div>
                    </div>

                    {
                        (editMode === modes.profileDisplay || editMode === modes.profileUpdate)
                            &&
                                <div className="form-group">
                                    <div className="form-label-wrapper">
                                        <label
                                            htmlFor={decipherId.key}
                                            className="form-label">{decipherId.label}<RequiredIndicator/>
                                        </label>
                                    </div>
                                    <div className="form-input-wrapper">
                                        <div className={"form-input-block"}>
                                            <input
                                                className="form-input"
                                                type="text"
                                                id={decipherId.key}
                                                placeholder={decipherId.placeholder}
                                                value={this.state[decipherId.key]}
                                                onChange={(e) => this.inputChange(e, decipherId.key)}
                                                disabled={true}
                                            />
                                            {
                                                (editMode === modes.profileUpdate)
                                                    &&
                                                        <span className={`form-input-icon--overlay override-disabled`}>
                                                                <CiLock className="icon" size="1.5em"/>
                                                        </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                    }

                    <div className="form-group">
                        <div className="form-label-wrapper">
                            <label
                                htmlFor={displayName.key}
                                className="form-label">{displayName.label}
                            </label>
                        </div>
                        <div className="form-input-wrapper">
                            <input
                                className={`form-input ${this.state.validationMessages[displayName.key] ? 'warning' : ''}`}
                                type="text"
                                id={displayName.key}
                                placeholder={displayName.placeholder}
                                value={this.state[displayName.key]}
                                onChange={(e) => this.validateInputOnChange(e, displayName.key)}
                                disabled={(editMode === modes.profileDisplay)}
                            />
                            {
                                validationMessages[displayName.key]
                                &&
                                <small className="form-input-message warning">
                                    {validationMessages[displayName.key]}
                                </small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-label-wrapper">
                            <label
                                htmlFor={industry.key}
                                className="form-label">{industry.label}
                            </label>
                        </div>
                        <div className="form-input-wrapper">
                            <FormSelect
                                placeholder={"Select Industry"}
                                isDisabled={(editMode === modes.profileDisplay)}
                                isClearable={true}
                                options={industryList}
                                selectedOption={this.state[industry.key]}
                                onChange={(option) => this.handleSelect(option, industry.key)}
                            />
                            {
                                validationMessages[industry.key]
                                &&
                                <small className="form-input-message warning">
                                    {validationMessages[industry.key]}
                                </small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-label-wrapper">
                            <label
                                htmlFor={logo.key}
                                className="form-label">{logo.label}
                            </label>
                        </div>
                        <div className="form-input-wrapper">
                            <div className="form-upload-wrapper">
                                {
                                    (editMode === modes.create && !newLogo) || (editMode === modes.profileUpdate && !newLogo && !this.state[logo.key])
                                        ?
                                            <div className={"addContractBlock addBrandLogoBlock form-upload__detail"} onClick={this.uploadLogo}>
                                                <div className={'iconContainer'}>
                                                    <img className={'plusIcon'} src={plusIcon} alt={'Plus icon'}/>
                                                </div>
                                                <div className={'createLabel createLogoLabel'}>For best results, upload a
                                                    400x400 sized .jpg or .png
                                                </div>
                                            </div>
                                        :
                                            <LogoComponent/>
                                }
                                <ImageUploader
                                    withIcon={false}
                                    withLabel={false}
                                    buttonText={''}
                                    buttonStyles={(editMode === 'profile_update' || editMode === 'create' || logoStyle.backgroundImage === undefined) ? uploadButtonStyle : {}}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                    imgExtension={[".jpg", ".gif", ".png"]}
                                    fileContainerStyle={uploadContainerStyle}
                                    onChange={this.handleUploadLogo}
                                />
                            </div>
                            {
                                validationMessages[logo.key]
                                &&
                                <small className="form-input-message warning">
                                    {validationMessages[logo.key]}
                                </small>
                            }
                        </div>
                    </div>

                    {
                        (editMode === modes.create || editMode === modes.profileUpdate)
                        &&
                        <div className={'form-actions'}>
                            <button
                                className={"form-btn default"}
                                onClick={this.onCancelClick}>
                                {buttons.cancel.label}
                            </button>
                            <button
                                className={"form-btn primary"}
                                onClick={this.createBrand}
                                disabled={isSubmitBtnDisabled}>
                                {buttons.add.label(editMode)}
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
