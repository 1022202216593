import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { getIconPath } from "../../../config/configHelper";

const Tooltip = ({ tooltipContent, elementStyle, showTooltip }) => {
    const [visible, setVisible] = useState(showTooltip);

    useEffect(() => {
        setVisible(showTooltip);
    }, [showTooltip]);

    const handleMouseOver = () => {
        setVisible(true);
    };

    const handleMouseOut = () => {
        setVisible(false);
    };
    return (
        <Tippy
            content={tooltipContent}
            delay={500}
            placement="bottom"
            className="tooltipTextContainer"
            visible={visible}
        >
            <div style={{ display: "flex" }}>
                <img
                    src={getIconPath("QUESTION")}
                    className="svgIcon"
                    alt="Information Selector"
                    style={elementStyle}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                />
            </div>
        </Tippy>
    );
};

export default Tooltip;
