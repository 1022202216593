import filtersTransformations from "./filterTransformations";
import ApiData from "./Api";
import {
  safeParseJSONLocalStorage,
  safeParseJSONSessionStorage,
} from "./getBrowserStorageData";
import initData from "./initData";
import {
  getSessionStorage,
  setSessionStorage,
} from "./storageHelpers";
import { getURLPathParams } from "./URLParamsValidations";
import lookup from "country-code-lookup";
import { DateTime } from "luxon";

export const phoneConvert = (phone) => {
  if (!phone) return "";
  let converted = phone.replace(/[^0-9]/g, "");
  if (converted.length < 3) {
    // converted = converted;
  } else if (converted.length === 3) {
    converted = `(${converted})`;
  } else if (converted.length > 3 && converted.length < 6) {
    converted = `(${converted.substr(0, 3)})${converted.substr(3)}`;
  } else if (converted.length === 6) {
    converted = `(${converted.substr(0, 3)})${converted.substr(3, 3)}-`;
  } else if (converted.length > 6) {
    converted = `(${converted.substr(0, 3)})${converted.substr(
      3,
      3
    )}-${converted.substr(6, 4)}`;
  } else {
    // converted = converted;
  }
  return converted;
};

export const setUserSelectedMarketsByBrand = () => {
  let globalBrands = safeParseJSONLocalStorage("globalBrands");
  let userSelectedGlobalMarkets = globalBrands?.reduce((accu, ele) => {
    let result = ele?.marketGroups?.reduce((acc, current) => {
      let selectedMarkets = current?.marketGroupsList?.reduce(
        (innerAcc, innerCurrent) => {
          innerAcc.push(innerCurrent?.identity);
          return innerAcc;
        },
        []
      );
      let selectedURLMarkets = safeParseJSONSessionStorage(
        "userSelectedGlobalMarkets"
      )?.find(({ brandId }) => brandId == ele?.id)?.markets;
      selectedMarkets = selectedMarkets?.filter((ele) =>
        selectedURLMarkets?.some((id) => id == ele)
      );
      acc.push(...selectedMarkets);
      return acc;
    }, []);
    return accu.concat({ brandId: ele?.id, markets: [...new Set(result)] });
  }, []);
  setSessionStorage(
    "userSelectedGlobalMarkets",
    JSON.stringify(userSelectedGlobalMarkets)
  );
};

export const setUserSelectedBrandsByMarket = (
  selectedMarket,
  selectedBrands
) => {
  let brands = safeParseJSONLocalStorage("myBrands");
  let selectedUserBrands = safeParseJSONSessionStorage("userSelectedBrands");
  let userSelectedBrands =
    selectedUserBrands?.length > 0 ? selectedUserBrands : [];
  let findIndex = userSelectedBrands?.findIndex(
    ({ marketId }) => marketId == selectedMarket
  );
  if (findIndex > -1) {
    userSelectedBrands[findIndex].brands = selectedBrands;
  } else if (selectedBrands?.length > 0)
    userSelectedBrands.push({
      marketId: selectedMarket,
      brands: selectedBrands,
    });
  else {
    userSelectedBrands.push({
      marketId: selectedMarket,
      brands: brands?.map(({ id }) => id),
    });
  }
  setSessionStorage("userSelectedBrands", JSON.stringify(userSelectedBrands));
};

export const setUserSelectedMarkets = (
  selectedGlobalBrandId,
  selectedMarkets
) => {
  let userSelectedGlobalMarkets = safeParseJSONSessionStorage(
    "userSelectedGlobalMarkets"
  );
  let findIndex = userSelectedGlobalMarkets?.findIndex(
    ({ brandId }) => brandId == selectedGlobalBrandId
  );
  findIndex > -1 &&
    (userSelectedGlobalMarkets[findIndex].markets = [
      ...new Set(selectedMarkets),
    ]);
  setSessionStorage(
    "userSelectedGlobalMarkets",
    JSON.stringify(userSelectedGlobalMarkets)
  );
};

//get labels based on market
export const handleLabelsBasedonMarket = (
  labelsBasedonMarket,
  item,
  marketId,
  zFields
) => {
  let data = [];
  const labelsText = initData.getLabelsText();
  let { screenType, group } = getURLPathParams();

  if (Array.isArray(item)) {
    data = item.map((ele) => {
      let labelQTextKey =
        group?.toLowerCase() + labelsText[ele?.id ? ele?.id : ele?.name] + "Q";
      let screenQTextKey = group?.toLowerCase() + "Q";
      ele.name =
        labelsBasedonMarket[marketId] !== undefined
          ? labelsBasedonMarket[marketId][
              labelsText[ele?.id ? ele?.id : ele?.name]
            ]?.label
          : labelsBasedonMarket["default"][
              labelsText[ele?.id ? ele?.id : ele?.name]
            ] !== undefined
          ? labelsBasedonMarket["default"][
              labelsText[ele?.id ? ele?.id : ele?.name]
            ]?.label
          : ele?.id
          ? ele?.id
          : ele?.name;
      ele.qText = getQuestionText(
        labelsBasedonMarket,
        labelsText,
        labelQTextKey,
        screenQTextKey,
        marketId
      );

      return ele;
    });
  } else {
    for (let key in labelsText) {
      let labelQTextKey = screenType?.toLowerCase() + labelsText[key] + "Q";
      let screenQTextKey = screenType?.toLowerCase() + "Q";
      if (item[key] !== undefined) {
        data.push({
          label:
            labelsBasedonMarket[marketId] !== undefined
              ? labelsBasedonMarket[marketId][labelsText[key]]?.label
              : labelsBasedonMarket["default"][labelsText[key]]?.label !==
                undefined
              ? labelsBasedonMarket["default"][labelsText[key]].label
              : key,
          id: key,
          value: Number(item[key] ? item[key]?.toFixed(1) : 0),
          diff: item[key + "Diff"]
            ? Number(item[key + "Diff"] ? item[key + "Diff"]?.toFixed(1) : 0)
            : null,
          percentage: Number(
            item[key + "Percent"] ? item[key + "Percent"]?.toFixed(1) : 0
          ),
          zValue: zFields && zFields[key]?.toFixed(1),
          qText: getQuestionText(
            labelsBasedonMarket,
            labelsText,
            labelQTextKey,
            screenQTextKey,
            marketId
          ),
        });
      }
    }
  }
  return data;
};

export const getQuestionText = (
  labelsBasedonMarket,
  labelsText,
  labelQTextKey,
  screenQTextKey,
  marketId
) => {
  let qText =
    labelsBasedonMarket[marketId] !== undefined
      ? labelsBasedonMarket[marketId][labelsText[labelQTextKey]] !== undefined
        ? labelsBasedonMarket[marketId][labelsText[labelQTextKey]]?.label
        : labelsBasedonMarket[marketId][labelsText[screenQTextKey]]?.label
      : labelsBasedonMarket["default"][labelsText[labelQTextKey]] !==
          undefined ||
        labelsBasedonMarket["default"][labelsText[screenQTextKey]] !== undefined
      ? labelsBasedonMarket["default"][labelsText[labelQTextKey]] !== undefined
        ? labelsBasedonMarket["default"][labelsText[labelQTextKey]].label
        : labelsBasedonMarket["default"][labelsText[screenQTextKey]].label
      : labelQTextKey
      ? labelQTextKey
      : screenQTextKey;
  return qText;
};

export const getSegmentsList = (segments) => {
  let segmentsList = segments?.reduce((acc, sr) => {
    acc.push(...sr?.segments);
    return acc;
  }, []);
  return segmentsList;
};

export const getSelectedGlobalBrandMarketsIds = (selectedBrand) => {
  let selectedURLMarkets = safeParseJSONSessionStorage(
    "userSelectedGlobalMarkets"
  )?.find(({ brandId }) => brandId == selectedBrand?.id)?.markets;
  let useHSSLabels = selectedURLMarkets?.every((ele) => ele?.includes("HSS"))
    ? true
    : false;
  const sortedMarketAry = selectedBrand?.marketGroups
    ?.reduce((acc, mg) => {
      acc.push(...mg.marketGroupsList);
      return acc;
    }, [])
    .sort((a, b) => a.position - b.position);
  selectedURLMarkets = sortedMarketAry
    ?.filter((ele) => selectedURLMarkets?.some((id) => id == ele?.identity))
    ?.map((marketObj) => {
      return { marketId: marketObj?.marketId, position: marketObj?.position };
    });
  let marketsList =
    selectedURLMarkets?.length > 0
      ? selectedURLMarkets
      : sortedMarketAry?.map((marketObj) => {
          return {
            marketId: marketObj?.marketId,
            position: marketObj?.position,
          };
        });
  let geoIds = marketsList?.map((obj) => obj?.marketId);
  geoIds = [...new Set(geoIds)];
  return { geoIds, useHSSLabels, marketsList };
};

export const handleSegmentsValidation = async (filters, geoIds) => {
  //get all filter Ids from filter query
  let segmentIds = filters
    ?.find(
      ({ categoryId, categoryName }) =>
        categoryId === null && categoryName === "Segments"
    )
    ?.filterResponse?.map(({ filterId }) => filterId);
  if (segmentIds?.length > 0) {
    let validateSegmentsList = await ApiData.validateSegments({
      segmentIds,
      geoIds,
    });
    //handling validations[detachedFilters,expiredFilters,segmentMarkets] for segments based on the validateapi for CI,Global and Advanced pages
    await filters.forEach((ele) => {
      if (ele.categoryId === null && ele.categoryName === "Segments") {
        ele.filterResponse.forEach((filterObj) => {
          let findSegment = validateSegmentsList?.filter(
            ({ segmentId }) => segmentId === filterObj?.filterId
          );
          if (findSegment?.length > 0) {
            filterObj.detachedFilters = findSegment[0]?.detachedFilters;
            filterObj.expiredFilters = findSegment[0]?.expiredFilters;
            filterObj.filterMarket = findSegment[0]?.segmentMarkets;
            filterObj.isExpired = findSegment[0]?.isFiltersExpired;
          }
        });
      }
    });
  }
  return filters;
};

export const formatViewsPayload = (payload, queryParamsList) => {
  let {
    selectedCampaign,
    selectedBrand,
    selectedDate,
    page,
    selectedTab,
    currentVariableMainFilter,
    currentVariableFilter,
    currentVariableGroup,
  } = queryParamsList;
  let {
    segments,
    brands,
    brand,
    chartType,
    screenType,
    group,
    metric,
    response,
    statTest,
    audienceType,
  } = getURLPathParams();
  let periodOptions = initData.getPeriodOptions();
  //get selected segments
  let segmentsList = queryParamsList?.segments?.reduce((acc, sr) => {
    acc.push(...sr.segments);
    return acc;
  }, []);
  segmentsList = segmentsList
    ?.filter(({ segmentId }) => segments?.some((id) => segmentId == id))
    ?.map(({ segmentId, order }) => ({ segmentId, position: order }));

  //get selected brands
  let urlBrands = brand ? [brand] : brands;

  //handling position null brands
  let maxBrandsPosition = Math.max(
    ...queryParamsList?.brands.map((obj) => obj["position"])
  );
  let nullCount = maxBrandsPosition;

  let primaryBrands = queryParamsList?.brands
    ?.filter((ele) => urlBrands?.some((id) => id == ele.id))
    ?.map(({ id, position }) => {
      if (position === null) position = ++nullCount;
      return { brandId: id, position };
    });
  primaryBrands = primaryBrands.sort((a, b) => {
    let indexA = urlBrands.findIndex((id) => id == a["brandId"]);
    let indexB = urlBrands.findIndex((id) => id == b["brandId"]);
    return indexA - indexB;
  });
  let secondaryBrands = [];
  if (page === "advanced") {
    if (selectedTab == 0 || selectedTab === 1) {
      secondaryBrands = primaryBrands.filter((obj, ind) => ind !== 0);
      primaryBrands = [primaryBrands[0]];
    } else if (selectedTab === 2) {
      secondaryBrands = [primaryBrands[1]];
      primaryBrands = [primaryBrands[0]];
    } else {
      primaryBrands = [primaryBrands[0]];
    }
  }

  //get selected markets
  let { marketsList } = getSelectedGlobalBrandMarketsIds(
    queryParamsList?.brands[selectedBrand[0]]
  );
  marketsList =
    page !== "global"
      ? [
          {
            marketId: selectedCampaign?.marketId,
            position: selectedCampaign?.position,
          },
        ]
      : marketsList;

  //get applied filters and segments
  let cfilters = JSON.parse(getSessionStorage("applyFilters"));
  let selectedMarketOrBrand =
    page !== "global" ? selectedCampaign : selectedBrand && selectedBrand[0];
  const formatFilterList = filtersTransformations.formatSelectedFilters(
    cfilters,
    selectedMarketOrBrand
  );
  let selectedFilters = filtersTransformations.applyMarketFilters(
    formatFilterList,
    queryParamsList?.segments,
    selectedCampaign
  );
  payload = {
    ...payload,
    details: {
      markets: marketsList,
      brands: {
        primary: primaryBrands,
        secondary: secondaryBrands,
      },
      segments:
        page === "segments" ? segmentsList : selectedFilters[0]?.segments,
      filters: selectedFilters[0]?.filters,
      screenType: {
        module: initData.getModuleType(page),
        screenType: screenType,
        chartType: chartType,
        group:
          screenType === "PROFILE"
            ? currentVariableMainFilter.toString()
            : group,
        metric:
          screenType === "PROFILE" ? currentVariableGroup.toString() : metric,
        response:
          screenType === "PROFILE"
            ? currentVariableFilter?.index.toString()
            : response,
        intervalType: selectedDate?.selectedInterval,
      },
      statTesting: statTest,
      audienceType: audienceType,
      datePeriod: {
        primaryStartDate: selectedDate.startdate,
        primaryEndDate: selectedDate.enddate,
        secondaryStartDate: selectedDate?.pstartdate,
        secondaryEndDate: selectedDate?.penddate,
        primaryperiodType:
          screenType === "PREPOST"
            ? periodOptions
                ?.find(({ id }) => id === selectedDate?.selectedPeriod)
                ?.name.replace(/ /g, "_")
                ?.toUpperCase()
            : payload?.isDynamicDateRange !== true
            ? periodOptions
                ?.find(({ id }) => id === selectedDate?.selectedPeriod)
                ?.name.replace(/ /g, "_")
                ?.toUpperCase()
            : "DYNAMIC_DATE_RANGE",
        secondaryPeriodType:
          payload?.isDynamicDateRange !== true
            ? periodOptions
                ?.find(({ id }) => id === selectedDate?.pselectedPeriod)
                ?.name.replace(/ /g, "_")
                ?.toUpperCase()
            : "DYNAMIC_DATE_RANGE",
      },
    },
  };
  return payload;
};

export const getRangeSliderDefaultValues = (
  page,
  selectedMarket,
  selectedGlobalBrand
) => {
  if (page === "global") {
    let globalBrands = safeParseJSONLocalStorage("globalBrands");
    let selectedURLMarkets = getGlobalMarketsBasedonBrand(
      globalBrands[selectedGlobalBrand]
    );
    if (selectedURLMarkets?.every((ele) => ele?.countryCode?.includes("HSS"))) {
      return [35, 120];
    } else if (
      selectedURLMarkets?.every((ele) =>
        ele?.countryCode?.includes("Spirit Drinkers")
      )
    ) {
      return [21, 120];
    } else {
      return [18, 120];
    }
  } else {
    if (selectedMarket?.countryCode === "HSS") {
      return [35, 120];
    } else if (selectedMarket?.countryCode === "Spirit Drinkers") {
      return [21, 120];
    } else {
      return [18, 120];
    }
  }
};

export const getAgeSegmentsNameBasedOnMarket = (selectedMarkets) => {
  if (
    selectedMarkets.every(
      (obj) => obj?.countryCode === "HSS" || obj?.code?.includes("HSS")
    )
  ) {
    return "Age 35+";
  } else if (
    selectedMarkets.every(
      (obj) =>
        obj?.countryCode === "Spirit Drinkers" || obj?.code?.includes("CTM-CA")
    )
  ) {
    return "Age 21+";
  } else {
    return "Age 18+";
  }
};

export const getGlobalMarketsBasedonBrand = (selectedGlobalBrand) => {
  let marketsList = selectedGlobalBrand?.marketGroups
    ?.reduce((acc, mg) => {
      mg.marketGroupsList.forEach((obj) => (obj.countryCode = mg.groupName));
      acc.push(...mg.marketGroupsList);
      return acc;
    }, [])
    .sort((a, b) => a.position - b.position);
  return marketsList;
};

export const getCountryCode = (marketName, countryCode) => {
  let marketCode = lookup.countries.filter(
    (obj) => obj?.country === marketName || obj?.internet === countryCode
  );

  if (countryCode === "HSS" || countryCode?.startsWith("HSS")) {
    marketCode = "us";
  } else if (
    countryCode === "Spirit Drinkers" ||
    countryCode?.startsWith("CTM")
  ) {
    marketCode = "spiritDrinkers";
  } else {
    marketCode = marketCode[0]?.internet?.toLowerCase();
  }
  return marketCode;
};

/* comapre previous start date is greater than Sept 21 2022 for advertising */
export const validateStatTestingAdvertisingStartDate = (startdate, enddate) => {
  let { statTest } = getURLPathParams("statTest");
  if (statTest === "YOY") {
    const startDate = DateTime.fromISO(startdate).minus({ day: 365 });
    return DateTime.fromISO("2022-09-21").diff(DateTime.fromISO(startDate)) > 0;
  } else if (statTest === "POP") {
    const startDate = DateTime.fromISO(startdate);
    const endDate = DateTime.fromISO(enddate);
    const daysDifference = endDate.diff(startDate, "day");
    let prevPeriodEnd = DateTime.fromISO(startdate).minus({ day: 1 });
    let prevPeriodStart = DateTime.fromISO(prevPeriodEnd)
      .minus(daysDifference, "day")
      .toFormat("yyyy-MM-dd");
    return (
      DateTime.fromISO("2022-09-21").diff(DateTime.fromISO(prevPeriodStart)) > 0
    );
  }
};
