import styled from "styled-components";
import { PRIMARY_BORDER_GRAY_COLOR } from "./utils/constant";

const StyledViewCard = styled.div`
  &.active{
    border: 1px solid #1f68dc;
  }
  width: 320px;
  border: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 3px -2px #0000000f;
  gap: 12px;
  background: white;
  cursor: grabbing;
  box-shadow: 0 0 10px #10182808, 0 0 10px #10182814;

  .header-card-icon{
    input[type=checkbox]:checked{       
      width: 17px !important;
      height: 17px !important;
      clip-path: none !important;
      visibility: visible;
      accent-color: #7556d9;
      border: 2px solid #7556d9;
      position: relative;
    }
    }
  }
  .MuiCheckbox-root {
    visibility: hidden;
    position: absolute;
  }


  &:hover {
    .MuiCheckbox-root {
      visibility: visible;
      position: static;
    }
    .screentype-logo {
      visibility: hidden;
      height: 0px;
      width: 0px;
    }
    input[type="checkbox"] {
      width: 15px !important;
      height: 15px !important;
      clip-path: none !important;
    }

    .header_card_icon {
      visibility: hidden;
    }
  }
  &:hover .view-card-drag-icon {
    opacity: 1;
  }
  .view-card-container {
    padding: 14px;
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    gap: 16px;
  }
  .brand-and-market-list {
    display: flex;
    gap: 24px;
    .logo{
      min-height: 30px;
      min-width: 30px;
      border: 3px solid white;
      border-radius: 50%;
      object-fit: cover;
      background-color: #292d33;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  * {
    font-family: "Inter", serif;
  }
`;

export default StyledViewCard;