import React from "react";
import { StyledViewsActions } from "./Styled.ViewsActions";
import ViewsDetailIcon from "../../../../assets/icons/icon_views_detail.svg";
import ViewsActionsIcon from "../../../../assets/icons/icon_views_dots.svg";
import ViewCloneIcon from "../../../../assets/icons/icon_views_clone.svg";
import ViewMakeDefaultIcon from "../../../../assets/icons/icon_views_make_default.svg";
import ViewExportIcon from "../../../../assets/icons/icon_views_download.svg";
import ViewDeleteIcon from "../../../../assets/icons/icon_views_trash.svg";
import PPTIcon from "../../../../assets/icons/ppt.svg";
import ExcelIcon from "../../../../assets/icons/xlsx.svg";
import EditIcon from "../../../../assets/icons/icon_report_edit.svg";
import ButtonWithDropdownOption from "../../../ButtonWithDropdownOption";
import useFileDownload from "../../../FileDownload/hooks/useFileDownloadHook";
import ApiData from "../../../../utils/Api";
import { generateWSPrivateChannelId } from "../../../../utils/userHelper";
import useViewsReportsExportsStatus from "../../../../hooks/useViewsReportsExportsStatus";

function ViewsActions({
  view,
  reportsIdsSelectedOnView,
  onClickDetailView,
  onClickDeleteView,
  onClickCloneView,
  onClickEditView,
  handleDefaultView,
  toggleExportModal,
  showToastHandler,
}) {
  const {
    isViewsExcelExportAvailable,
    isViewsPPTExportAvailable,
    isViewsExportAvailable,
  } = useViewsReportsExportsStatus();

  const { addNewFileToDownload } = useFileDownload();

  async function handleExport(isPPT = false) {
    const fileType = isPPT ? "pptx" : "xlsx";
    const fileName = `${view.viewName}.${fileType}`;
    const { id } = JSON.parse(localStorage.getItem("userInfo"));
    const connectionId = generateWSPrivateChannelId(id);
    try {
      let res = await ApiData.PostReportExport(
        {
          reportId: reportsIdsSelectedOnView,
          viewId: view.viewId,
          fileName,
          connectionId,
          userId: id,
        },
        fileType
      );
      showToastHandler({
        type: "success",
        message: "Download Initiated",
        description: "Preparing your view, please hold on.",
        fileName: fileName,
        fileType: fileType,
      });
      toggleExportModal(true);
      addNewFileToDownload({
        fileName,
        jobId: res,
        screenType: "views",
      });
    } catch (error) {
      showToastHandler({
        type: "error",
        message: "Download Failed",
        description: "The view could not be generated.",
        // fileName: fileName,
        fileType: fileType,
      });
    }
  }

  const handleViewClick = (e, view, closeDropdown, onClick) => {
    onClick(e, view);
    closeDropdown();
  };

  const handleDropdownButtonClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledViewsActions className="views-header-right-container">
      <img
        height={18}
        className="views-button"
        src={ViewsDetailIcon}
        onClick={(e) => onClickDetailView(e, view)}
        alt="views-details"
      />
      <ButtonWithDropdownOption
        closeOnContentClick={false}
        trigger={
          <img
            width={22}
            className="view-buttons"
            src={ViewsActionsIcon}
            alt="views-actions"
            onClick={handleDropdownButtonClick}
          />
        }
      >
        {(closeDropdown) => (
          <ul>
            <li
              key={`clone_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickEditView)
              }
            >
              <img height={18} src={EditIcon} alt="views-details" />
              <span>Edit View</span>
            </li>
            <li
              key={`clone_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickCloneView)
              }
            >
              <img height={18} src={ViewCloneIcon} alt="views-details" />
              <span>Clone View</span>
            </li>
            <li
              key={`make_default_view_${view.viewId}`}
              onClick={(e) => handleDefaultView(e, view)}
              condition={view?.isDefaultView}
            >
              <img height={18} src={ViewMakeDefaultIcon} alt="views-details" />
              <span>{!view?.isDefaultView && "Make"} Default View</span>
              <input type="checkbox" checked={view?.isDefaultView} />
            </li>
            {isViewsExportAvailable && (
              <ButtonWithDropdownOption
                trigger={
                  <li key={`export_view_${view.viewId}`}>
                    <img height={18} src={ViewExportIcon} alt="views-details" />
                    <span>Export View</span>
                  </li>
                }
                className="view-export-download-main-container"
                offset={[40, -40]}
                placement="right"
              >
                <ul>
                  {isViewsPPTExportAvailable && (
                    <li
                      id="ppt-button"
                      key={`ppt_view_${view.viewId}`}
                      onClick={handleExport.bind(this, true)}
                    >
                      <img height={18} src={PPTIcon} alt="views-details" />
                      <span>PPT</span>
                    </li>
                  )}
                  {isViewsExcelExportAvailable &&
                    view?.screenType !== "PERCEPTUAL" && (
                      <li
                        id="excel-button"
                        key={`Excel_view_${view.viewId}`}
                        onClick={handleExport.bind(this, false)}
                      >
                        <img height={18} src={ExcelIcon} alt="views-details" />
                        <span>Excel</span>
                      </li>
                    )}
                </ul>
              </ButtonWithDropdownOption>
            )}
            <li
              id="view-delete-action"
              key={`delete_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickDeleteView)
              }
            >
              <div className="view-delete-action-button">
                <img height={18} src={ViewDeleteIcon} alt="views-details" />
                <span>Delete View</span>
              </div>
            </li>
          </ul>
        )}
      </ButtonWithDropdownOption>
    </StyledViewsActions>
  );
}

export default ViewsActions;