import React, { Component } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FiltersList from "../widgets/Shared/FiltersList";
import BrandsMarketsDropDownSelect from "../widgets/Shared/BrandsMarketsDropDownSelect";
import DateSelecter from "../widgets/OptionFilters/DateSelecter";
import closeIcon from "../../assets/icons/icon_close.svg";
import filterIcon from "../../assets/icons/icon_filter.svg";
import viewsIcon from "../../assets/icons/icon_views.svg";
import failedDownloadIcon from "../../assets/icons/icon_close_red.svg";
import ExportButton from "../widgets/Shared/ExportButton/ExportButton";
import ExportDocumentsDropdown from "../widgets/ExportDocumentsDropdown";
import ApiData from "../../utils/Api";
import "./HorizontalBar.scss";
import "../widgets/OptionFilters/DateSelecter.scss";
import InitData from "../../utils/initData";
import filtersTransformations, {
  filterGroupTypes,
} from "../../utils/filterTransformations";
import SimpleSelect from "../widgets/Shared/SimpleDropDownSelect";
import { getLocalStorage } from "../../utils/storageHelpers";
import { safeParseJSONSessionStorage } from "../../utils/getBrowserStorageData";
import {
  getAgeSegmentsNameBasedOnMarket,
  getSelectedGlobalBrandMarketsIds,
  validateStatTestingAdvertisingStartDate,
} from "../../utils/common";
import { AblyContext } from "../../contexts/AblyContext";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import {
  parseMessage,
  subscribeToPrivateChannel,
} from "../../utils/socketManager";
import { ExportEventType } from "../../constants/enums/ExportEventType";
import { ExportJobStatus } from "../../constants/enums/ExportJobStatus";
import { generateWSPrivateChannelId } from "../../utils/userHelper";
import { Badge } from "@material-ui/core";
import ViewsActions from "../ViewsComponents/ViewsActionsContainer";
import CreateEditReport from "../ViewsComponents/CreateEditReport";
import { setViews } from "../../reducers/TwoByTwoChart/actionCreators";
import FileDownload from "../FileDownload";
import ComponentToUseHookForProccessingEvent from "../FileDownload/Component/ComponentToUseHookForProccessingEvent";
import Toaster from "../ViewsComponents/Toaster";

const displayDateFormat = "MMM D, YYYY";
const graphOptions = [
  "equity",
  "conversion",
  "emotional",
  "advertising",
  "trends",
  "profile",
];
const screenType = [
  "equity",
  "conversion",
  "emotional",
  "advertising",
  "trends",
  "profile",
];
const screenTypeForAdvanced = [
  "differentiation",
  "perceptual",
  "butterfly",
  "pre/post",
];
const trendStabs = [
  "equity",
  "conversion",
  "emotional",
  "advertising",
  "irreplaceability",
];
const advancedTabOptions = [
  "differential",
  "perceptual",
  "butterfly",
  "prepost",
];
const ALL = "all";
const pendo = window.pendo;

class HorizontalBar extends Component {
  static contextType = AblyContext;

  constructor(props) {
    super(props);
    this.state = {
      expandBrandsSelection: false,
      expandAdvancedBrandsSelection: false,
      expandMarketSelection: false,
      exportModalOpened: false,
      fileList: [],
      filterOpen: false,
      currentOption: 0,
      adTooltip: 0,
      isPrivateChannelSubscribed: false,
      showSaveViewsActionsModal: false,
      expandPeriodSelection: false,
      showViewNotification: false,
      isOpenReportModal: false,
      viewsList: [],
      toasterMessage: null,
      marketLastUpdated: false,
    };
    this.outsideClickListener = this.outsideClickListener.bind(this);
    this.openExportDropdown = this.openExportDropdown.bind(this);
    this.closeExportDropdown = this.closeExportDropdown.bind(this);
    this.onExportBtnClick = this.onExportBtnClick.bind(this);
    // this.onDownloadExportDocClick = this.onDownloadExportDocClick.bind(this);
    this.onDownloadExportDocClickV2 =
      this.onDownloadExportDocClickV2.bind(this);
    this.downloadDocumentCallback = this.downloadDocumentCallback.bind(this);
    this.initExportTemplate = this.initExportTemplate.bind(this);
    this.maxProgressLimit = 100;
    this.fileListMaxLength = 5;
    this.exportFileId = 0;
    this.canvasPlot = null;
    this.exportTemplate = {};
    this.loopCheckId = null;
    this.loopCheckTimeout = 6000;
    this.jobPreparingTimeKoef = 0.9;
    this.brandLabel = 0;
    this.tooltip = React.createRef(null);
    this.imageWidth = "0";
  }

  componentDidMount() {
    const { userId } = this.props;
    const ablyClient = this.context;
    const clientId = userId ?? null;
    if (ablyClient && clientId) {
      subscribeToPrivateChannel(
        ablyClient,
        clientId,
        ExportEventType.EXPORT_DELIVERY,
        this.processExportDeliveryEvent
      );
      subscribeToPrivateChannel(
        ablyClient,
        clientId,
        ExportEventType.EXPORT_FAILED,
        this.processExportFailedEvent
      );
      this.isPrivateChannelSubscribed = true;
      // TODO: Need to deconstruct this.
    }
    this.getViewsListData();
  }

  processExportFailedEvent = (payload) => {
    const event = parseMessage(payload.data);
    if (!event) {
      return;
    }

    const { jobId, error } = event;

    this.handleFailedExportJob(jobId, error);
  };

  processExportDeliveryEvent = (payload) => {
    const event = parseMessage(payload.data);
    if (!event) {
      return;
    }

    const { status, jobId, url } = event;

    switch (status) {
      case ExportJobStatus.SUCCESS:
        this.handleSuccessExportJob(jobId, url);
        break;
      case ExportJobStatus.FAILED:
        this.handleFailedExportJob(jobId);
        break;
      default:
        break;
    }
  };

  handleFailedExportJob = (jobId, error) => {
    const file = this.state.fileList?.find((file) => file.jobId === jobId);
    this.exportFileFinishedWithError(file.id, error);
    clearInterval(file?.timeoutId);
  };

  handleSuccessExportJob = (jobId, url) => {
    const file = this.state.fileList?.find((file) => file.jobId === jobId);
    this.changeExportFileProgress(file.id, 100, true, url);
    clearInterval(file?.timeoutId);
  };

  handleSocketUnsubscription = () => {}; // TODO: Need to figure this out.

  getGlobalBrandEngagement() {
    const { useExposed, selectedTab } = this.props;
    let optionModes = InitData.getModes();
    if (selectedTab === 1) {
      return optionModes[1].name.toUpperCase().replace(/ /g, "");
    }
    let audienceType = optionModes
      .filter((obj) => obj.id === useExposed)[0]
      ?.name?.toUpperCase()
      .replace(/ /g, "");
    return audienceType;
  }

  componentWillUnmount() {
    this.unmountClearInterval();
  }

  componentDidUpdate(prevProps) {
    let { page, selectedTab } = this.props;
    let brandLabel = this.props.brands.find(
      (obj, index) => index === Number(this.props.selectedBrand[0])
    )?.displayName;
    if (brandLabel == null) {
      brandLabel = this.props.brands.find(
        (obj, index) => index === Number(this.props.selectedBrand[0])
      )?.name;
    }
    this.brandLabel = brandLabel;
    if (page !== prevProps.page || selectedTab !== prevProps.selectedTab) {
      this.setState({
        expandBrandsSelection: false,
        expandMarketSelection: false,
        expandAdvancedBrandsSelection: false,
      });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (prevState.adTooltip !== this.tooltip?.current?.scrollWidth) {
        this.setState({
          adTooltip: this.tooltip?.current?.scrollWidth,
        });
      }
    }, 1000);
    return true;
  }

  onClickSelection = (e, key) => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  onHandleNotification = (value) => {
    this.setState({
      showViewNotification: value,
    });
  };

  openExportDropdown() {
    this.setState({ exportModalOpened: true });
  }

  closeExportDropdown() {
    this.setState({ exportModalOpened: false });
  }

  onExportBtnClick() {
    const { exportModalOpened } = this.state;
    if (exportModalOpened) this.closeExportDropdown();
    else this.openExportDropdown();
  }

  openSelect = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  createLimitedFilelist(currentFilelist, newFileObj) {
    if (
      currentFilelist.length >= this.fileListMaxLength &&
      currentFilelist.every((file) => !file.isLoaded)
    ) {
      return currentFilelist;
    } else if (currentFilelist.length >= this.fileListMaxLength) {
      return [...currentFilelist, newFileObj].slice(1);
    } else {
      return [...currentFilelist, newFileObj];
    }
  }

  createNewExportFile(fileId, fileName, downloadProgress = 0, fileType) {
    let timeOutId = setInterval(() => {
      let file = this.state.fileList.find((file) => file?.id === fileId);
      if (file) {
        if (file?.downloadProgress > 92) {
          clearInterval(file?.timeoutId);
        } else {
          this.changeExportFileProgress(
            fileId,
            file?.downloadProgress + 0.5,
            false
          );
        }
      }
    }, 100);
    const newFileObj = {
      id: fileId,
      name: fileName,
      isLoaded: false,
      downloadProgress,
      fileType: fileType,
      date: `${moment().format(displayDateFormat)}`,
      url: null,
      jobId: null,
      timeoutId: timeOutId,
      errorMessage: "",
    };

    this.setState((prevState) => ({
      fileList: this.createLimitedFilelist(prevState.fileList, newFileObj),
    }));
    setTimeout(() => {
      const currentFile = this.state.fileList.find(
        (file) => file.id === fileId
      );
      if (currentFile.downloadProgress) {
        this.exportFileFinishedWithError(fileId, "Request timed out");
      }
    }, 120000);
  }

  changeExportFileProgress(
    fileId,
    downloadProgress,
    isLoaded,
    url,
    jobId,
    callback
  ) {
    this.setState(
      (prevState) => ({
        fileList: prevState.fileList.map((file) =>
          file.id === fileId
            ? {
                ...file,
                downloadProgress,
                isLoaded,
                url: url ? url : file.url,
                jobId: jobId ? jobId : file.jobId,
              }
            : file
        ),
      }),
      callback ? callback() : () => {}
    );
  }
  getRoute() {
    const { location } = this.props;
    const route = location.pathname;
    const currentRoute = `/${route.split("/")[1]}`;
    return currentRoute;
  }

  exportFileFinishedWithError = (fileId, errorMessage = "") => {
    this.setState((prevState) => ({
      fileList: prevState.fileList.map((file) =>
        file.id === fileId
          ? {
              ...file,
              isLoaded: true,
              downloadProgress: this.maxProgressLimit,
              icon: failedDownloadIcon,
              errorMessage,
            }
          : file
      ),
    }));
  };

  unmountClearInterval = () => {
    clearInterval(this.loopCheckId);
    this.loopCheckId = null;
  };

  getDetailedPayload = (fileName) => {
    const {
      selectedBrand,
      selectedDate,
      brands,
      selectedCampaign,
      userId,
      hiddenListInBarChart,
      currentVariableGroup,
      selectedTab,
      lineOption,
      page,
      allFilters,
    } = this.props;
    const brand = brands[selectedBrand[0]];
    let segments = this.getSegments().filter(
      (segment) => !hiddenListInBarChart?.includes(segment.segmentId)
    );
    if (Number(currentVariableGroup === 1) && selectedTab === 5) {
      let age18SegmentIndex = segments?.filter(ele=> ele.segmentName === "Age 18+");
      segments = segments?.filter(
        (ele) => ele.isActive === true && ele.segmentName !== "Age 18+"
      );
      segments = [...age18SegmentIndex, ...segments];
    } else if(Number(currentVariableGroup !== 1) && selectedTab === 5){
      segments = segments?.filter(
        (ele) => ele.isActive === true && ele.segmentName !== "Age 18+"
      );
    }else{
      segments = segments?.filter(
        (ele) => ele.isActive === true 
      );
    }
    segments.filter((item) => {
      if (item.segmentName === "Age 18+")
        getAgeSegmentsNameBasedOnMarket([selectedCampaign]);
    });
    let startdate =
      (selectedTab === 3 ||
        (selectedTab === 5 && currentVariableGroup === 5) ||
        (page === "segments" && selectedTab === 4 && lineOption.stab === 3)) &&
      moment(selectedDate.startdate).isBefore("2022-09-21") &&
      selectedDate.selectedPeriod === 1
        ? "2022-09-21"
        : selectedDate.startdate;

    const body = {
      marketId: selectedCampaign.marketId,
      brandIds: [brand.id],
      fromDate: `${moment(startdate).format("YYYY-MM-DD")}`,
      toDate: `${moment(selectedDate.enddate).format("YYYY-MM-DD")}`,
      segments: filtersTransformations.parseSegmentsFilters(
        segments,
        allFilters
      ),
      audienceType:
        selectedTab === 1 ? "GENPOP" : this.getGlobalBrandEngagement(),
      module: page === "segments" ? "DETAILED" : page.toUpperCase(),
      screenType: screenType[selectedTab].toUpperCase(),
      connectionId: generateWSPrivateChannelId(userId),
      fileName: fileName,
      userId,
      statTesting: this.props.statTestingEnabled,
      useHSSLabels: selectedCampaign?.countryCode === "HSS" ? true : false,
    };
    return body;
  };

  getNamedInputs = () => {
    const { brands, interval, searchFilter } = this.props;
    return brands.map((item) => {
      return {
        name: item.name,
        brandId: item.id,
        intervalUnit: interval,
        filters: searchFilter,
      };
    });
  };

  getSegments = () => {
    const { segments } = this.props;
    let customSegmentList = segments?.reduce((acc, sr) => {
      acc.push(...sr.segments);
      return acc;
    }, []);
    return customSegmentList
      ?.filter(
        (item) =>
          item?.segmentMarkets?.includes(this.props.selectedCampaign.marketName)
      )
      .map((item) => {
        return {
          segmentName: item.segmentName,
          segmentId: item.segmentId,
          isActive: item.isActive,
          filters: [...item.filters],
        };
      });
  };
  setAge18Flag = () => {
    const { currentVariableGroup } = this.props;
    return currentVariableGroup === 1;
  };
  getAllScreenParamas = (lineOption, requestAry) => {
    const { interval } = this.props;
    let [payload, fileType, subRoute] = requestAry;
    payload.audienceType = this.getGlobalBrandEngagement();
    payload.intervalUnit = interval;
    this.setProfilePayload(payload);
    this.setTrendsPayload(lineOption, payload);
    const queryParams = { ...fileType };
    subRoute = ALL;
    return [payload, queryParams, subRoute];
  };

  setTrendsPayload = (trendsOptions, payload) => {
    let { stab, selectedOption, selectedScoreOption } = trendsOptions;
    let scoreDecompositionEnabled =
      selectedOption === "momentum" ||
      selectedOption === "quality" ||
      selectedOption === "recommend" ||
      selectedOption === "consideration";
    let subProperty = scoreDecompositionEnabled ? selectedScoreOption : "";

    payload.trend = {
      group: trendStabs[stab],
      metric: selectedOption,
      response: subProperty,
      intervalUnit: payload.intervalUnit,
    };
    return payload;
  };

  setProfilePayload = (payload) => {
    const {
      currentVariableMainFilter,
      currentVariableGroup,
      currentVariableFilter,
    } = this.props;

    payload.profile = {
      groupId: currentVariableMainFilter,
      metricId: currentVariableGroup,
      responseId: currentVariableFilter.index,
      isAge18: this.setAge18Flag(),
    };
    return payload;
  };

  getExportPayload = (payload, trendsOptions, optionId) => {
    const {
      selectedTab,
      interval,
      currentVariableMainFilter,
      currentVariableGroup,
      currentVariableFilter,
    } = this.props;
    let { stab, selectedOption, selectedScoreOption } = trendsOptions;
    let scoreDecompositionEnabled =
      selectedOption === "momentum" ||
      selectedOption === "quality" ||
      selectedOption === "recommend" ||
      selectedOption === "consideration";
    let subProperty = scoreDecompositionEnabled ? selectedScoreOption : "";
    if (optionId === 1) {
      if (selectedTab === 4) {
        payload.intervalUnit = interval;
        payload.trend = {
          group: trendStabs[stab],
          metric: selectedOption,
          response: subProperty,
          intervalUnit: interval,
        };
      } else if (selectedTab === 5) {
        payload.profile = {
          groupId: currentVariableMainFilter,
          metricId: currentVariableGroup,
          responseId: currentVariableFilter.index,
          isAge18: this.setAge18Flag(),
        };
      }
    } else {
      payload.screenType = "ALL";
      payload.intervalUnit = interval;
      payload.screens = {
        screensOrder: [
          {
            position: 1,
            screenType: "EQUITY",
          },
          {
            position: 2,
            screenType: "CONVERSION",
          },
          {
            position: 3,
            screenType: "EMOTIONAL",
          },
          {
            position: 4,
            screenType: "ADVERTISING",
          },
        ],
        trend: [
          {
            screenOrder: {
              position: 5,
              screenType: "TRENDS",
            },
            group: trendStabs[stab],
            metric: selectedOption,
            response: subProperty,
            intervalUnit: interval,
          },
        ],
        profile: [
          {
            screenOrder: {
              position: 6,
              screenType: "PROFILE",
            },
            groupId: currentVariableGroup,
            metricId: currentVariableFilter.index,
            responseId: currentVariableMainFilter,
            isAge18: this.setAge18Flag(),
          },
        ],
      };
    }
    return payload;
  };

  getDetailedExportMethod(fileType, optionId, fileName) {
    const { selectedTab, lineOption, interval } = this.props;
    const requestOpts = [
      this.getDetailedPayload(fileName),
      { fileType },
      graphOptions[selectedTab],
    ];

    if (fileType === "xlsx") {
      let payload = this.getExportPayload(
        this.getDetailedPayload(fileName),
        lineOption,
        optionId
      );
      return () => ApiData.getExports(payload, { fileType });
    } else {
      if (optionId === 2) {
        const allScreenReq = this.getAllScreenParamas(lineOption, requestOpts);
        return () => ApiData.getSegmentsExport(...allScreenReq);
      }

      switch (selectedTab) {
        case 0:
          return () => ApiData.getSegmentsExport(...requestOpts);
        case 1:
          return () => ApiData.getSegmentsExport(...requestOpts);
        case 2:
          return () => ApiData.getSegmentsExport(...requestOpts);
        case 3:
          return () => ApiData.getSegmentsExport(...requestOpts);
        case 4: {
          let [payload, fileType] = requestOpts;
          let { selectedOption, selectedScoreOption } = lineOption;
          payload.intervalUnit = interval;
          this.setTrendsPayload(lineOption, payload);
          const subtitle = `${graphOptions[selectedTab]}/${
            trendStabs[lineOption.stab]
          }`;
          let scoreDecompositionEnabled =
            selectedOption === "momentum" ||
            selectedOption === "quality" ||
            selectedOption === "recommend" ||
            selectedOption === "consideration";
          let subProperty = scoreDecompositionEnabled
            ? selectedScoreOption
            : "";
          return () =>
            ApiData.getSegmentsExport(
              payload,
              {
                ...fileType,
                property: selectedOption,
                subProperty: subProperty,
              },
              subtitle
            );
        }
        case 5: {
          let [payload, fileType, subtitle] = requestOpts;
          this.setProfilePayload(payload);
          const { intervalUnit, ...rest } = payload;

          return () =>
            ApiData.getSegmentsExport({ ...rest }, { ...fileType }, subtitle);
        }
        default:
          return () => Promise.reject("Detailed export error");
      }
    }
  }

  getGlobalPayload = (fileName) => {
    const {
      searchFilter,
      selectedTab,
      brands,
      selectedDate,
      selectedCampaign,
      userId,
      hiddenListInBarChart,
      segments,
      selectedBrand,
      lineOption,
      currentVariableGroup,
      page,
      interval,
    } = this.props;
    const brand = brands[selectedBrand[0]];
    let { geoIds, useHSSLabels } = getSelectedGlobalBrandMarketsIds(brand);
    geoIds = geoIds?.filter((id) => !hiddenListInBarChart.includes(id));
    let selectedFilters = filtersTransformations.applyMarketFilters(
      searchFilter,
      segments,
      selectedCampaign
    );
    let startdate =
      (selectedTab === 3 ||
        (selectedTab === 5 && currentVariableGroup === 5) ||
        (page === "global" && selectedTab === 4 && lineOption.stab === 3)) &&
      moment(selectedDate.startdate).isBefore("2022-09-21") &&
      selectedDate.selectedPeriod === 1
        ? "2022-09-21"
        : selectedDate.startdate;
    const payload = {
      marketId: selectedCampaign.marketId,
      brandIds: [brand.id],
      geoIds,
      fromDate: `${moment(startdate).format("YYYY-MM-DD")}`,
      toDate: `${moment(selectedDate.enddate).format("YYYY-MM-DD")}`,
      geoIds,
      intervalUnit: interval,
      filters: selectedFilters[0]?.filters,
      segments: selectedFilters[0]?.segments,
      audienceType:
        selectedTab === 1 ? "GENPOP" : this.getGlobalBrandEngagement(),
      module: page === "segments" ? "DETAILED" : page.toUpperCase(),
      screenType: screenType[selectedTab].toUpperCase(),
      isAge18: currentVariableGroup === 1,
      connectionId: generateWSPrivateChannelId(userId),
      fileName: fileName,
      userId,
      statTesting: this.props.statTestingEnabled,
      useHSSLabels: useHSSLabels,
    };

    return payload;
  };

  getPPTXExport(fileType, optionId, fileName, lineOption) {
    const { selectedTab, page } = this.props;
    let body = {};
    switch (page) {
      case "segments": {
        body = this.getDetailedPayload(fileName);
        break;
      }
      case "competitive": {
        body = this.getCompetitivePayload(fileName);
        break;
      }
      case "global": {
        body = this.getGlobalPayload(fileName);
        break;
      }
      case "advanced": {
        body = this.getAdvancedPayload(optionId, fileName);
      }
    }
    const updatedBody = this.getExportPayload(body, lineOption, optionId);
    const requestOptions = [
      updatedBody,
      { fileType },
      graphOptions[selectedTab],
    ];
    return () => ApiData.getNewPPTXExportURL(...requestOptions);
  }

  getGlobalExportMethod(fileType, optionId, fileName) {
    const { selectedTab, lineOption, selectedCampaign } = this.props;

    let body = this.getGlobalPayload(fileName);
    if (fileType === "xlsx") {
      let payload = this.getExportPayload(body, lineOption, optionId);
      return () => ApiData.getExports(payload, { fileType });
    } else {
      const requestOptions = [body, { fileType }, graphOptions[selectedTab]];

      if (optionId === 2) {
        const [payload, ...rest] = this.getAllScreenParamas(
          lineOption,
          requestOptions
        );
        payload.marketId = selectedCampaign.marketId;
        return () => ApiData.getGlobalExports(payload, ...rest);
      }

      switch (selectedTab) {
        case 0:
          return () => ApiData.getGlobalExports(...requestOptions);
        case 1:
          return () => ApiData.getGlobalExports(...requestOptions);
        case 2:
          return () => ApiData.getGlobalExports(...requestOptions);
        case 3:
          return () => ApiData.getGlobalExports(...requestOptions);
        case 4: {
          let { selectedOption, selectedScoreOption } = lineOption;
          let [payload, fileType] = requestOptions;
          payload.marketId = selectedCampaign.marketId;
          this.setTrendsPayload(lineOption, payload);
          const subtitle = `${graphOptions[selectedTab]}/${
            trendStabs[lineOption.stab]
          }`;
          let scoreDecompositionEnabled =
            selectedOption === "momentum" ||
            selectedOption === "quality" ||
            selectedOption === "recommend" ||
            selectedOption === "consideration";
          let subProperty = scoreDecompositionEnabled
            ? selectedScoreOption
            : "";
          let { audienceType } = body;
          return () =>
            ApiData.getGlobalExports(
              { ...payload, audienceType },
              {
                ...fileType,
                property: selectedOption,
                subProperty: subProperty,
              },
              subtitle
            );
        }
        case 5: {
          let [payload, fileType, subtitle] = requestOptions;
          payload.marketId = selectedCampaign.marketId;
          this.setProfilePayload(payload, requestOptions);
          const { intervalUnit, audienceType, ...rest } = payload;

          return () =>
            ApiData.getGlobalExports({ ...rest }, { ...fileType }, subtitle);
        }
        default:
          return () => Promise.reject("Global export error");
      }
    }
  }

  getCompetitivePayload = (fileName) => {
    const {
      searchFilter,
      selectedTab,
      brands,
      selectedDate,
      selectedCampaign,
      userId,
      hiddenListInBarChart,
      currentVariableGroup,
      segments,
      lineOption,
      page,
    } = this.props;
    let selectedURLCIBrands = safeParseJSONSessionStorage(
      "userSelectedBrands"
    )?.find(({ marketId }) => marketId == selectedCampaign?.marketId)?.brands;
    selectedURLCIBrands = brands?.filter((ele) =>
      selectedURLCIBrands?.some((id) => id == ele.id)
    );
    let brandIds = selectedURLCIBrands?.map((brandObj) => brandObj.id);
    brandIds = brandIds?.filter((id) => !hiddenListInBarChart?.includes(id));
    let selectedFilters = filtersTransformations.applyMarketFilters(
      searchFilter,
      segments,
      selectedCampaign
    );
    let startdate =
      (selectedTab === 3 ||
        (selectedTab === 5 && currentVariableGroup === 5) ||
        (page === "competitive" &&
          selectedTab === 4 &&
          lineOption.stab === 3)) &&
      moment(selectedDate.startdate).isBefore("2022-09-21") &&
      selectedDate.selectedPeriod === 1
        ? "2022-09-21"
        : selectedDate.startdate;

    const payload = {
      marketId: selectedCampaign.marketId,
      brandIds,
      fromDate: `${moment(startdate).format("YYYY-MM-DD")}`,
      toDate: `${moment(selectedDate.enddate).format("YYYY-MM-DD")}`,
      filters: selectedFilters[0]?.filters,
      segments: selectedFilters[0]?.segments,
      audienceType:
        selectedTab === 1 ? "GENPOP" : this?.getGlobalBrandEngagement(),
      module: page === "segments" ? "DETAILED" : page.toUpperCase(),
      screenType: screenType[selectedTab].toUpperCase(),
      isAge18: currentVariableGroup === 1,
      connectionId: generateWSPrivateChannelId(userId),
      fileName: fileName,
      userId,
      statTesting: this.props.statTestingEnabled,
      useHSSLabels: selectedCampaign?.countryCode === "HSS" ? true : false,
    };

    return payload;
  };

  getCompetitiveExportMethod(fileType, optionId, fileName) {
    const { selectedTab, lineOption, interval } = this.props;
    let body = this.getCompetitivePayload(fileName);
    if (fileType === "xlsx") {
      let payload = this.getExportPayload(body, lineOption, optionId);
      return () => ApiData.getExports(payload, { fileType });
    } else {
      const requestOpts = [body, { fileType }, graphOptions[selectedTab]];

      if (optionId === 2) {
        const allScreenReq = this.getAllScreenParamas(lineOption, requestOpts);
        return () => ApiData.getCompetitiveExports(...allScreenReq);
      }

      switch (selectedTab) {
        case 0:
          return () => ApiData.getCompetitiveExports(...requestOpts);
        case 1:
          return () => ApiData.getCompetitiveExports(...requestOpts);
        case 2:
          return () => ApiData.getCompetitiveExports(...requestOpts);
        case 3: {
          let [payload] = requestOpts;
          payload.intervalUnit = interval;
          return () => ApiData.getCompetitiveExports(...requestOpts);
        }
        case 4: {
          let { selectedOption, selectedScoreOption } = lineOption;
          let [payload, fileType] = requestOpts;
          payload.intervalUnit = interval;
          this.setTrendsPayload(lineOption, payload);
          const { geoIds, ...rest } = payload;
          const subtitle = `${graphOptions[selectedTab]}/${
            trendStabs[lineOption.stab]
          }`;
          let scoreDecompositionEnabled =
            selectedOption === "momentum" ||
            selectedOption === "quality" ||
            selectedOption === "recommend" ||
            selectedOption === "consideration";
          let subProperty = scoreDecompositionEnabled
            ? selectedScoreOption
            : "";
          return () =>
            ApiData.getCompetitiveExports(
              { ...rest },
              {
                ...fileType,
                property: selectedOption,
                subProperty: subProperty,
              },
              subtitle
            );
        }
        case 5: {
          let [payload] = requestOpts;
          this.setProfilePayload(payload);
          return () => ApiData.getCompetitiveExports(...requestOpts);
        }

        default:
          return () => Promise.reject("Competitive export error");
      }
    }
  }

  getAdvancedPayload = (optionId, fileName) => {
    const {
      searchFilter,
      selectedTab,
      selectedBrand,
      brands,
      selectedDate,
      useExposed,
      selectedCampaign,
      prePostLineOption,
      userId,
      segments,
      statTestingEnabled,
      page,
    } = this.props;
    let [_, ...competitors] = selectedBrand.map((brandId) => {
      const selectBrnd = brands[brandId];
      return selectBrnd.id;
    });
    let audienceType = this.getGlobalBrandEngagement();
    if (selectedTab === 3 && prePostLineOption?.stab === 1) {
      audienceType = "GENPOP";
    }
    if (
      Number(useExposed) === 2 &&
      selectedTab === 3 &&
      prePostLineOption?.stab !== 3
    ) {
      audienceType = "AdRecall";
    }
    let selectedFilters = filtersTransformations.applyMarketFilters(
      searchFilter,
      segments,
      selectedCampaign
    );
    let startdate =
      moment(selectedDate.startdate).isBefore("2022-09-21") &&
      selectedDate.selectedPeriod == 1 &&
      prePostLineOption.stab === 3
        ? "2022-09-21"
        : selectedDate.startdate;
    let pstartdate =
      moment(selectedDate.pstartdate).isBefore("2022-09-21") &&
      selectedDate.selectedPeriod == 1 &&
      prePostLineOption.stab === 3
        ? "2022-09-21"
        : selectedDate.pstartdate;

    const payload = {
      marketId: selectedCampaign.marketId,
      brandIds: [brands[selectedBrand[0]].id],
      competitors: competitors,
      fromDate: startdate,
      toDate: selectedDate.enddate,
      filters: selectedFilters[0]?.filters,
      segments: selectedFilters[0]?.segments,
      audienceType:
        Number(selectedTab) === 0 || Number(selectedTab) === 1
          ? "FAMILIAR"
          : audienceType,
      module: page === "segments" ? "DETAILED" : page.toUpperCase(),
      screenType:
        optionId === 2
          ? "ALL"
          : advancedTabOptions[selectedTab] === "differential"
          ? "DIFFERENTIATION"
          : advancedTabOptions[selectedTab].toUpperCase(),
      statTesting: statTestingEnabled,
      connectionId: generateWSPrivateChannelId(userId),
      fileName: fileName,
      useHSSLabels: selectedCampaign?.countryCode === "HSS" ? true : false,
      userId,
    };

    if (optionId === 1 && selectedTab === 3) {
      payload.prepost = {
        preFromDate: startdate,
        preToDate: selectedDate.enddate,
        postFromDate: pstartdate,
        postToDate: selectedDate.penddate,
        group: graphOptions[prePostLineOption.stab],
      };
      delete payload.competitors;
      delete payload.fromDate;
      delete payload.toDate;
    } else if (optionId === 2) {
      payload.prepost = {
        preFromDate: startdate,
        preToDate: selectedDate.enddate,
        postFromDate: pstartdate,
        postToDate: selectedDate.penddate,
        group: graphOptions[prePostLineOption.stab],
      };
    }
    return payload;
  };

  getAdvancedExportMethod = (fileType, optionId, fileName) => {
    const {
      selectedTab,
      selectedBrand,
      brands,
      selectedDate,
      selectedCampaign,
      statTestingEnabled,
      searchFilter,
      segments,
      prePostLineOption,
      userId,
    } = this.props;
    let payload = this.getAdvancedPayload(optionId, fileName);
    if (fileType === "xlsx") {
      return () => ApiData.getExports(payload, { fileType });
    } else {
      const requestOpts = [
        payload,
        { fileType, tabType: graphOptions[prePostLineOption.stab] },
        advancedTabOptions[selectedTab],
      ];
      switch (selectedTab) {
        case 0:
          return () => ApiData.getAdvancedExportJobId(...requestOpts);
        case 1:
          return () => ApiData.getAdvancedExportJobId(...requestOpts);
        case 2:
          return () => ApiData.getAdvancedExportJobId(...requestOpts);
        case 3:
          return () => ApiData.getAdvancedExportJobId(...requestOpts);
        default:
          return () => Promise.reject("Advanced export error");
      }
    }
  };

  downloadDocumentCallback(fileId) {
    const { fileList } = this.state;
    const targetFile = fileList.find((file) => file.id === fileId);

    return (total, loaded) => {
      const downloadProgress =
        targetFile.downloadProgress + (loaded * 100) / total / 10;
      this.changeExportFileProgress(
        fileId,
        downloadProgress,
        downloadProgress === this.maxProgressLimit
      );
    };
  }

  pendoTrackEvents = (fileType, optionId) => {
    const { page, selectedTab, lineOption, location } = this.props;
    const { selectedPeriod, startdate, enddate, selectedInterval } =
      this.props.selectedDate;
    let pendoCategory =
      (page === "competitive" || page === "segments") && selectedTab === 4;
    const trendsTabOptions = [
      { id: 0, name: "Equity" },
      { id: 1, name: "Conversion" },
      { id: 2, name: "Emotional" },
      { id: 3, name: "Advertising" },
    ];
    let pendoInterval;
    let pendoStab = lineOption;
    InitData.getIntervals(true).map((obj) =>
      obj.options.filter((obj1) => {
        if (obj1.id === selectedInterval) {
          pendoInterval = obj1;
        }
      })
    );
    if (pendo != undefined) {
      pendo.track("Screen Export", {
        location: location.pathname,
        exportPeriodType: InitData.getPeriodOptions().filter(
          (obj) => obj.id === selectedPeriod
        )[0].name,
        exportPeriodInterval: pendoCategory ? pendoInterval?.name : "Null",
        exportStartDate: startdate,
        exportEndDate: enddate,
        exportFileType: fileType,
        exportDownloadType: InitData.getExportOptions(
          location.pathname
        ).options.filter((obj) => obj.id === optionId)[0]?.label,
        "exportCategory ": pendoCategory
          ? trendsTabOptions.filter((obj) => obj.id === pendoStab.stab)[0]?.name
          : "Null",
        exportProperty: pendoCategory
          ? InitData.getTrendsOptionCategories()
              [pendoStab.stab].filter(
                (obj) => obj.id === pendoStab.selectedOption
              )[0]
              ?.name.toLowerCase()
          : "Null",
      });
    }
  };

  onDownloadExportDocClickV2(fileType, option) {
    this.pendoTrackEvents(fileType, option);

    this.exportFileId = ++this.exportFileId;

    switch (fileType) {
      case "xlsx":
      case "pptx":
        if (this.isPrivateChannelSubscribed) {
          this.exportXlsxFileNameCreation(fileType, option, this.exportFileId);
          this.downloadExportFile(fileType, option, this.exportFileId);
        } else {
          console.warn("[WS] No Channel Subscribed");
        }
        break;
      case "png":
      case "pdf":
        this.createExportFile(fileType, option, this.exportFileId);
        break;
      default:
        break;
    }
  }

  exportXlsxFileNameCreation(fileType, optionId, fileId) {
    const { selectedBrand, searchFilter, selectedTab, segments, brands, page } =
      this.props;
    const currentRoute = `/${page}`;

    this.createNewExportFile(
      fileId,
      `${InitData.getExportFilename(
        currentRoute,
        selectedTab,
        brands[selectedBrand[0]],
        optionId
      )}.${fileType}`,
      0,
      fileType
    );
  }

  downloadExportFile(fileType, optionId, fileId) {
    const { selectedBrand, selectedTab, brands, page, lineOption } = this.props;
    const currentRoute = `/${page}`;
    const routes = InitData.getAppRoutes();
    let apiMethod = null;
    const exportUsingNewPPTList =
      process.env.REACT_APP_ENTITY_USING_NEW_PPTX_EXPORT?.split(",") || [];
    let fileName = `${InitData.getExportFilename(
      currentRoute,
      selectedTab,
      brands[selectedBrand[0]],
      optionId
    )}.${fileType}`;
    if (
      fileType === "pptx" &&
      (exportUsingNewPPTList?.includes(`${page}${screenType[selectedTab]}`) ||
        (page === "advanced" &&
          exportUsingNewPPTList?.includes(
            `${page}${screenTypeForAdvanced[selectedTab]}`
          )))
    ) {
      apiMethod = this.getPPTXExport(fileType, optionId, fileName, lineOption);
    } else {
      switch (currentRoute) {
        case routes.segments: {
          apiMethod = this.getDetailedExportMethod(
            fileType,
            optionId,
            fileName
          );
          break;
        }
        case routes.competitive: {
          apiMethod = this.getCompetitiveExportMethod(
            fileType,
            optionId,
            fileName
          );
          break;
        }
        case routes.advanced: {
          apiMethod = this.getAdvancedExportMethod(
            fileType,
            optionId,
            fileName
          );
          break;
        }
        case routes.global: {
          apiMethod = this.getGlobalExportMethod(fileType, optionId, fileName);
          break;
        }
        default: {
          apiMethod = () => {
            return Promise.resolve();
          };
          break;
        }
      }
    }

    apiMethod().then((jobId) => {
      this.setState((prevState) => ({
        fileList: prevState.fileList.map((file) => {
          if (file.id === fileId) {
            return {
              ...file,
              jobId: jobId,
            };
          }
          return file;
        }),
      }));
    });
  }

  setExportUrl(url, fileId) {
    this.changeExportFileProgress(fileId, this.maxProgressLimit, true, url);
    this.canvasPlot = null;
  }

  correctImageDimensions() {
    const {
      match: { path },
      selectedTab,
    } = this.props;
    const routes = InitData.getAppRoutes();
    let width = 120;
    let height = 0;

    if (path === routes.advanced) {
      if (selectedTab === 0 || selectedTab === 1) height = 80;
      if (selectedTab === 2) height = 80;
    }

    if (document.querySelector(".mainContent.filterOpen")) width += 320;
    if (document.querySelector(".contentContainer.expand")) width += 120;
    return { width, height };
  }

  changeWrapperOverflow(isVisible) {
    const { page, horizontalMenu, wrapperRef } = this.props;
    if (page === "segments" || page === "competitive" || page === "global") {
      isVisible === true && horizontalMenu !== "secondary"
        ? (wrapperRef.current.style.overflow = "scroll")
        : (wrapperRef.current.style.overflow = "unset");
    }
  }

  convertDOMToCanvas(targetFormat, filename, callback, fileId) {
    let { page } = this.props;
    this.changeWrapperOverflow(false);
    try {
      this.initExportTemplate();
    } catch (error) {
      console.log("Export template init error", error);
    }
    const template = this.exportTemplate;

    const {
      img: {
        templateWidth,
        templateHeight,
        clearDOMCallback,
        ignoreElements,
        element: target,
        onCloneHandler,
      },
    } = template;
    const { width, height } = this.correctImageDimensions();
    const node = document.querySelectorAll(".barChartContent");
    let widthValue = 0;
    let windowWidthValue = 0;
    if (node.length === 0 && page !== "advanced") {
      widthValue = +220;
      windowWidthValue = +220;
    } else {
      widthValue = -width;
      windowWidthValue = 0;
    }
    node.forEach((box) => {
      this.imageWidth = box.style.width;
      box.style.setProperty("width", "", "important");
      box.style.setProperty("position", "relative", "important");
    });
    html2canvas(target.element, {
      allowTaint: true,
      imageTimeout: 0,
      // backgroundColor: 'transparent',
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      ignoreElements: (element) =>
        ignoreElements.some((ignElement) => {
          if (typeof ignElement === "string")
            return element.classList.contains(ignElement);
          else if (typeof ignElement === "function") {
            return ignElement(element);
          } else return false;
        }),
      canvas: this.canvasPlot,
      width: templateWidth + widthValue,
      height: templateHeight - height,
      windowWidth: templateWidth + windowWidthValue,
      windowHeight: templateHeight,
      onclone: (element) => onCloneHandler(element) || element,
      useCORS: true,
    })
      .then((canvas) => {
        node.forEach((box) => {
          box.style.setProperty("width", this.imageWidth, "important");
          page !== "advanced" &&
            box.style.setProperty("position", "absolute", "important");
        });
        this.changeWrapperOverflow(true);

        clearDOMCallback && clearDOMCallback();
        this.canvasPlot = canvas;

        if (targetFormat === "png") {
          const url = canvas.toDataURL("image/png");
          callback && callback(url);
        } else {
          const imageUrl = canvas.toDataURL("image/jpeg", 1);
          const {
            pdf: {
              orientation,
              unit,
              format,
              width,
              height,
              xOffset,
              yOffset,
              imageFormat,
            },
          } = template;
          const pdf = new jsPDF({ orientation, unit, format });
          const docwidth = pdf.internal.pageSize.getWidth();
          const docheight = pdf.internal.pageSize.getHeight();
          const maxRatio = Math.max(width / docwidth, height / docheight);
          pdf.addImage(
            imageUrl,
            imageFormat,
            xOffset,
            yOffset,
            width / maxRatio,
            height / maxRatio
          );
          const url = pdf.output("bloburl", { filename });
          callback && callback(url);
        }
        this.props.downloadPngPdfExports();
      })
      .catch((error) => {
        console.log("error with export files", error);
        this.exportFileFinishedWithError(fileId);
      });
  }

  async createExportFile(targetFormat = "png", option, fileId) {
    const { brands, selectedBrand, selectedTab, page } = this.props;
    await this.props.downloadPngPdfExports();
    const currentRoute = `/${page}`;
    const filename = InitData.getExportFilename(
      currentRoute,
      selectedTab,
      brands[selectedBrand[0]],
      option
    );
    const fakeTime = targetFormat === "png" ? 2000 : 4000;
    const progressCallback = (progress, isLoaded) => {
      this.changeExportFileProgress(fileId, progress, isLoaded);
    };

    this.createNewExportFile(fileId, `${filename}.${targetFormat}`);
    this.exportFileFakeProgress(fakeTime, progressCallback);

    try {
      this.convertDOMToCanvas(
        targetFormat,
        filename,
        (url) => this.setExportUrl(url, fileId),
        fileId
      );
    } catch (error) {
      this.exportFileFinishedWithError(fileId);
      console.log("Error with converting DOM to canvas", error);
    }
  }

  exportFileFakeProgress = (timeLimit, callback) => {
    const timeDelta = 100;
    let currentTimeProgress = 0;
    const exportProgressLoopId = setInterval(() => {
      const currentProgress =
        (this.maxProgressLimit * currentTimeProgress) / timeLimit;
      if (currentTimeProgress > timeLimit) {
        clearInterval(exportProgressLoopId);
        callback(currentProgress, true);
      } else {
        currentTimeProgress += timeDelta;
        callback(currentProgress, false);
      }
      currentTimeProgress += timeDelta;
    }, timeDelta);
  };

  initExportTemplate() {
    const { selectedTab, horizontalMenu, page } = this.props;
    const currentRoute = `/${page}`;
    this.exportTemplate = InitData.getExportTemplate(
      currentRoute,
      selectedTab,
      horizontalMenu
    );
  }

  handleViewAction = () => {
    let { showSaveViewsActionsModal } = this.state;
    this.setState({
      showSaveViewsActionsModal: !showSaveViewsActionsModal,
    });
  };

  onCloseModal = () => {
    this.setState({
      showSaveViewsActionsModal: false,
    });
  };

  toggleReportCreateModal = () => {
    const { isOpenReportModal } = this.state;
    this.setState({
      isOpenReportModal: !isOpenReportModal,
    });
  };

  getViewsListData = async () => {
    let { viewsList } = this.props;
    if (viewsList?.length === 0) {
      let data = await ApiData.getViewsList();
      await this.props.setViews(data);
      this.setState({ viewsList: data });
    }
  };

  handleToasterMessage = (messageObj) => {
    this.setState({
      toasterMessage: {
        type: messageObj.type,
        message: messageObj.message,
      },
    });
  };

  onHeaderClick = (e) => {
    const { sIndex } = this.props;
    if (!this.props.open) {
      document.addEventListener("click", this.outsideClickListener);
    } else {
      document.removeEventListener("click", this.outsideClickListener);
    }
  };
  outsideClickListener() {
    this.setState({ marketLastUpdated: false });
    if (this.props.open) {
      this.props.onOpenSelect();
    }
    document.removeEventListener("click", this.outsideClickListener);
  }

  marketUpdated = () => {
    this.setState({ marketLastUpdated: true });
  };

  renderMarketPopOver = () => {
    const { data, openTop } = this.props;
    const { marketLastUpdated } = this.state;
    return (
      marketLastUpdated && (
        <div
          id="lastupdate"
          style={{
            position: "absolute",
            display: "block",
            top: "42px",
            right: "0px",
            zIndex: 999,
          }}
          className={cx("selectListContainer", { openTop: openTop })}
        >
          <label className="warninglabel">
            Data has been imported into the portal for the market and is current
            through the date shown.
          </label>
          {data.map((campaign, index) => {
            return (
              <div key={index} className={"selectCampaignContainer"}>
                <div className={"campaignNameBox"}>
                  <span>{campaign[0]}</span>
                </div>
                <div className={"campaignRegionBox"}>
                  <span className={cx("campaignRegion")}>
                    {moment(campaign[1].split("T")[0]).format("MM/DD/YYYY")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  rendersCampaignsDropDown() {
    const {
      campaigns,
      selectedCampaign,
      page,
      handleSelectBrandsMarkets,
      selectedGlobalMarkets,
      getSelectedGlobalBrandMarkets,
    } = this.props;
    const { expandMarketSelection } = this.state;
    const markets = JSON.parse(getLocalStorage("markets"));
    markets.forEach((obj) => {
      if (
        obj.marketGroupsList.some(
          (val) => val.marketId === selectedCampaign.marketId
        )
      ) {
        selectedCampaign.countryCode = obj.groupName;
      }
    });
    let { sortedMarketAry } = getSelectedGlobalBrandMarkets();
    return (
      <>
        {page === "global" && sortedMarketAry?.length > 1 && (
          <BrandsMarketsDropDownSelect
            data={sortedMarketAry}
            multiSelect={true}
            selected={selectedGlobalMarkets}
            sIndex="0"
            skey="tp_"
            open={expandMarketSelection}
            onOpenSelect={(e) =>
              this.onClickSelection(e, "expandMarketSelection")
            }
            onSelectCampaign={this.props.onSelectCampaign}
            onSelectFunction={this.props.onSelectMarketFunction}
            handleSelectBrandsMarkets={handleSelectBrandsMarkets}
          />
        )}
        {page !== "global" && (
          <BrandsMarketsDropDownSelect
            data={campaigns}
            selected={selectedCampaign}
            sIndex="0"
            skey="tp_"
            open={expandMarketSelection}
            // summary={page === "summary"}
            onOpenSelect={(e) =>
              this.onClickSelection(e, "expandMarketSelection")
            }
            onSelectCampaign={this.props.onSelectCampaign}
          />
        )}
      </>
    );
  }

  renderBrandsDropDown() {
    const {
      brands,
      brandSsize,
      page,
      primaryBrandHidden,
      selectedBrand,
      selectedTab,
      selectedPctBrand,
      selectedCompetitiveBrands,
      filterOn,
      onSelectFunction,
      handleSelectBrandsMarkets
    } = this.props;
    const { expandBrandsSelection, expandAdvancedBrandsSelection } = this.state;
    return (
      <>
        {this.props.onSelectFunction &&
          Array.isArray(selectedBrand) &&
          !primaryBrandHidden &&
          page !== "competitive" && (
            <BrandsMarketsDropDownSelect
              data={brands}
              open={expandBrandsSelection}
              brandSsize={page === "advanced" ? brandSsize : []}
              selected={selectedBrand[0]}
              sIndex="0"
              onOpenSelect={(e) =>
                this.onClickSelection(e, "expandBrandsSelection")
              }
              onSelectFunction={onSelectFunction}
              page={page}
              selectedTab={selectedTab}
              filterOn={filterOn}
            />
          )}
        {page === "competitive" && brands.length > 1 && (
          <BrandsMarketsDropDownSelect
            data={brands}
            multiSelect={true}
            open={expandBrandsSelection}
            selected={selectedCompetitiveBrands}
            sIndex={2}
            onOpenSelect={(e) =>
              this.onClickSelection(e, "expandBrandsSelection")
            }
            page={page}
            onSelectFunction={onSelectFunction}
            handleSelectBrandsMarkets={handleSelectBrandsMarkets}
          />
        )}
        {page === "advanced" &&
          selectedTab >= 0 &&
          selectedTab < 3 &&
          brands.length > 2 && (
            <BrandsMarketsDropDownSelect
              data={brands}
              multiSelect={selectedTab === 2 ? false : 3}
              open={expandAdvancedBrandsSelection}
              selected={
                selectedTab === 1
                  ? selectedPctBrand
                  : selectedTab === 2
                  ? selectedBrand[1]
                  : selectedBrand
              }
              hideList={selectedTab === 1 ? [] : [selectedBrand[0]]}
              brandSsize={brandSsize}
              sIndex={1}
              onOpenSelect={(e) =>
                this.onClickSelection(e, "expandAdvancedBrandsSelection")
              }
              page={page}
              onSelectFunction={onSelectFunction}
              handleSelectBrandsMarkets={handleSelectBrandsMarkets}
            />
          )}
      </>
    );
  }

  renderAdvertisingWarningText() {
    let { selectedTab, selectedDate:{startdate,enddate}, loading, page } = this.props;
    let advertisingDateValidation = validateStatTestingAdvertisingStartDate(startdate, enddate)

    return (
      <div className={this.brandLabel?.length > 50 ? "sample" : "warningText"}>
        {selectedTab === 3 &&
          advertisingDateValidation &&
          !loading &&
          page !== "advanced" && (
            <span
              className="adtext"
              ref={this.tooltip}
              style={
                this.state.adTooltip < 250
                  ? { cursor: "pointer" }
                  : { cursor: "auto" }
              }
            >
              Data for the previous time period comparison is only available
              from September 21, 2022.
              {this.state.adTooltip < 250 && (
                <span className={"tooltiptext"}>
                  Data for the previous time period comparison is only available
                  from September 21, 2022.
                </span>
              )}
            </span>
          )}
      </div>
    );
  }

  renderExportBtn() {
    const { isDataAvailable, selectedTab } = this.props;
    const { exportModalOpened, fileList } = this.state;
    const routeTab = this.getRoute();
    const exportButtonInProgress = fileList.every((file) => file.isLoaded);
    const exportButtonProgress =
      fileList.length && !exportButtonInProgress
        ? Math.max(
            ...fileList
              .filter((file) => file.downloadProgress < this.maxProgressLimit)
              .map((file) => file.downloadProgress)
          )
        : 0;
    return (
      <div className={"exportContainer"}>
        <ExportButton
          onClick={this.onExportBtnClick}
          progress={exportButtonProgress}
          downloadInProgress={!exportButtonInProgress}
          exportModalOpened={exportModalOpened}
        />
        {exportModalOpened && (
          <ExportDocumentsDropdown
            closeDropdown={this.closeExportDropdown}
            getExportFile={this.onDownloadExportDocClickV2}
            fileList={fileList}
            initExportTemplate={this.initExportTemplate}
            currentRoute={routeTab}
            isDataAvailable={isDataAvailable}
            selectedTab={selectedTab}
          />
        )}
      </div>
    );
  }

  renderFilterIcon() {
    const { filterOn, onClickFilterFunction } = this.props;

    return (
      onClickFilterFunction && (
        <div
          className={cx(
            "filterIconContainer",
            "floatRight",
            "viewIconContainer",
            {
              filterOpen: filterOn,
            }
          )}
          onClick={onClickFilterFunction}
        >
          <img
            src={filterOn ? closeIcon : filterIcon}
            className="viewIcon"
            alt=""
            height={20}
          />
        </div>
      )
    );
  }

  renderDataLastUpdated() {
    return (
      <div
        onClick={(e) => this.onHeaderClick(e)}
        className="flex-container filterIconContainer"
      >
        <HiOutlineInformationCircle
          style={{ color: "#71767D" }}
          className="iconInformation"
          onClick={this.marketUpdated}
        />
        {this.renderMarketPopOver()}
      </div>
    );
  }

  renderSaveViewsIcon() {
    let { isViewChanged } = this.props;
    let { showSaveViewsActionsModal, showViewNotification } = this.state;
    return (
      <div>
        <div className={"filterIconContainer floatRight viewIconContainer"}>
          {showViewNotification ? (
            <Badge
              color="error"
              overlap="circle"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              onClick={this.handleViewAction}
            >
              <img src={viewsIcon} alt="" className="viewIcon" />
            </Badge>
          ) : (
            <img
              src={viewsIcon}
              alt=""
              onClick={this.handleViewAction}
              className="viewIcon"
              height={22}
            />
          )}
        </div>
        <ViewsActions
          showSaveViewsActionsModal={showSaveViewsActionsModal}
          closeModal={this.onCloseModal}
          isViewChanged={isViewChanged}
          onChangeHandle={this.onHandleNotification}
          showViewNotification={showViewNotification}
          queryParamslist={this.props}
        />
      </div>
    );
  }

  renderFilterList() {
    const {
      filterOn,
      currentFilters,
      onClickFilterFunction,
      clearFiterFunction,
      page,
      selectedCampaign,
      selectedBrand,
    } = this.props;
    let selectedMarketOrBrand =
      page !== "global" ? selectedCampaign : selectedBrand[0];
    const formatFilterList = filtersTransformations.formatSelectedFilters(
      currentFilters,
      selectedMarketOrBrand
    );
    return (
        <div
          className={cx("filterListContainer", {
            listWithData: currentFilters.length,
          })}
        >
          <FiltersList
            page={page}
            currentSelectedFilters={formatFilterList}
            showLimit={2}
            mbClickFunction={onClickFilterFunction}
            clearFunction={!filterOn ? clearFiterFunction : null}
            selectedCampaign={selectedCampaign}
          />
        </div>
    );
  }

  renderDateSelector() {
    const {
      selectedDate,
      selectedTab,
      page,
      brandSsize,
      dateFilterId,
      lineOption,
      prePostLineOption,
      currentVariableGroup,
      onPeriodChange,
      onIntervalChange,
      data,
      onPostPeriodDate,
    } = this.props;
    return (
      <div
        className={cx("periodContainer", {
          prePost: page === "advanced" && selectedTab === 3,
          customDates:
            page === "advanced" &&
            selectedTab === 3 &&
            (selectedDate.selectedPeriod === 1 ||
              selectedDate.pselectedPeriod === 1),
          notCustomDates: page === "advanced" && selectedTab !== 3,
        })}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DateSelecter
          selectedPeriod={selectedDate.selectedPeriod}
          selectedInterval={selectedDate.selectedInterval}
          sSize={
            page === "advanced" &&
            selectedTab === 3 &&
            Array.isArray(brandSsize) &&
            brandSsize[0]
              ? brandSsize[0]
              : null
          }
          ppLabel={page === "advanced" && selectedTab === 3 ? "pre" : null}
          startdate={selectedDate.startdate}
          enddate={selectedDate.enddate}
          editInterval={
            page === "summary" ||
            ((page === "competitive" ||
              page === "segments" ||
              page === "global") &&
              selectedTab === 4)
          }
          onChangeFunction={this.props.onChangePeriodFunction}
          dateFilterId={dateFilterId}
          showMovingAverage={
            ((page === "competitive" ||
              page === "segments" ||
              page === "global") &&
              selectedTab === 4) ||
            page === "summary"
          }
          page={page}
          selectedTab={selectedTab}
          lineOption={lineOption}
          prePostLineOption={prePostLineOption}
          currentVariableGroup={currentVariableGroup}
          onPeriodChange={onPeriodChange}
          onIntervalChange={onIntervalChange}
          data={data}
          onPostPeriodDate={onPostPeriodDate}
        />
        {page === "advanced" && selectedTab === 3 && (
          <DateSelecter
            selectedPeriod={selectedDate.pselectedPeriod}
            sSize={
              page === "advanced" &&
              selectedTab === 3 &&
              Array.isArray(brandSsize) &&
              brandSsize[1]
                ? brandSsize[1]
                : 0
            }
            ppLabel={page === "advanced" && selectedTab === 3 ? "post" : null}
            startdate={selectedDate.pstartdate}
            enddate={selectedDate.penddate}
            onChangeFunction={this.props.onChangePostPeriodFunction}
            dateFilterId={dateFilterId}
            page={page}
            selectedTab={selectedTab}
            lineOption={lineOption}
            prePostLineOption={prePostLineOption}
            onPeriodChange={onPeriodChange}
            onIntervalChange={onIntervalChange}
            data={data}
            onPostPeriodDate={onPostPeriodDate}
          />
        )}
      </div>
    );
  }

  renderFilter() {
    const {
      page,
      newCheck,
      options,
      selectedTab,
      tabs,
      currentOption,
      selectFilter,
      disabledOption,
    } = this.props;
    const checking =
      tabs.length > 0 &&
      selectedTab > -1 &&
      newCheck &&
      page !== "users" &&
      page !== "brands";
    return (
      ((page === "advanced" && this.props.prePostLineOption?.stab === 1) ||
        checking) && (
        <div className="filterBox">
          <SimpleSelect
            customClass="filterTab"
            data={options}
            open={this.state.filterOpen}
            selected={currentOption}
            placeholder="default"
            skey="ur_"
            onOpenSelect={this.openSelect}
            onSelectFunction={selectFilter}
            disabledOption={disabledOption}
            onSelectModeChange={this.props.onSelectModeChange}
          />
        </div>
      )
    );
  }

  renderHorizontalBar() {
    let { page } = this.props;
    let { isOpenReportModal, viewsList } = this.state;
    let menuItem = InitData.getMenu()[page];
    viewsList = viewsList?.length > 0 ? viewsList : this.props.viewsList;
    if (menuItem?.primaryTitle) {
      return (
        <div className={"menuBarContainerWrapper"}>
          <div className="menuBarContainer horizontalBarItemsContainer">
            <div className={"pageTitleContainer"}>
              <div className={"primaryTiltle"}>
                {menuItem?.primaryTitle}{" "}
                {menuItem?.primaryTitle === "Report Builder" ? (
                  <span className="report-builder-beta-tag">Beta</span>
                ) : (
                  ""
                )}
              </div>
              <div className={"secondaryTitle"}>{menuItem?.subTitle}</div>
            </div>
            <div
              className="viewsHorizontalBar"
              style={{ height: "40px", gap: "20px", display: "flex" }}
            >
              <FileDownload
                toggleExportModal={this.props.toggleExportModal}
                open={this.props.isExportModalOpen}
              />
              <div
                className="form-button-actions"
                onClick={
                  viewsList?.length !== 0 && this.toggleReportCreateModal
                }
              >
                <div
                  className={cx("saveButton", {
                    disabled: viewsList?.length === 0,
                  })}
                >
                  Build Report
                </div>
              </div>
            </div>
          </div>
          {isOpenReportModal && (
            <CreateEditReport
              isOpen={isOpenReportModal}
              closeModal={this.toggleReportCreateModal}
              selectedAction={"create"}
              handleToasterMessage={this.handleToasterMessage}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className={"menuBarContainerWrapper"}>
        <div className="menuBarContainer horizontalBarItemsContainer">
            {/* <div className="horizontalBarActions" style={{justifyContent:'flex-start',width:'30%'}}> */}
            {page === "global"
              ? this.renderBrandsDropDown()
              : this.rendersCampaignsDropDown()}
            {page === "global"
              ? this.rendersCampaignsDropDown()
              : this.renderBrandsDropDown()}
            {page !== "summary" && this.renderFilterList()}
            {this.renderAdvertisingWarningText()}
            <div className="horizontalBarActions">
              {this.renderFilter()}
              {this.renderDateSelector()}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                {this.renderDataLastUpdated()}
                {page !== "summary" && this.renderSaveViewsIcon()}
                {page !== "summary" && this.renderExportBtn()}
                {page !== "summary" && this.renderFilterIcon()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { userId } = this.props;
    const { toasterMessage } = this.state;
    const ablyClient = this.context;
    const clientId = userId ?? null;
    return (
      <>
        <div className={"horizontalBarContainer"}>
          {this.renderHorizontalBar()}
          <ComponentToUseHookForProccessingEvent
            ablyClient={ablyClient}
            clientId={clientId}
          />
        </div>
        {toasterMessage && (
          <Toaster
            isOpen={toasterMessage}
            variant={toasterMessage?.type}
            onClose={() => {
              this.setState({ toasterMessage: null });
            }}
          >
            {toasterMessage?.message}
          </Toaster>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ twoByTwoChart }) => ({
  customSegmentList: twoByTwoChart.customSegmentList,
  viewsList: twoByTwoChart?.viewsList,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setViews }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withRouter(HorizontalBar));
