import React, { Component } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import LoadingLayer from "../widgets/Shared/LoadingLayer";
import BarChart from "../widgets/ChartComponents/BarChart";
import EquityCard from "../widgets/ChartComponents/EquityCard";
import EmotionalCard from "../widgets/ChartComponents/EmotionalCard";
import FunnelChartCard from "../widgets/ChartComponents/FunnelChartCard";
import LineChart from "../widgets/ChartComponents/LineChart";
import initData from "../../utils/initData";
import ApiData from "../../utils/Api";
import StackedBarChart from "../widgets/ChartComponents/StackedBarChart";
import transformHigherLowerThan from "../../utils/transformHigherLowerThan";
import AdvertisingCard from "../widgets/ChartComponents/AdvertisingCard";
import {
  setBrands as setTwoByTwoBrands,
  setChartData as setTwoByTwoChartData,
  clearAllData as clearAllTwoByTwoData,
} from "../../reducers/TwoByTwoChart/actionCreators";
import {
  setChartData as setTrendsData,
  clearAllData as clearAllTrendsData,
} from "../../reducers/Trends/actionCreators";
import EquityScrollWrapperHeightService, {
  selectors,
} from "../../utils/equityScrollWrapperHeightService";
import TwoByTwoChartAxisPatchService from "../../utils/2x2ChartAxisPatchService";
import "./GlobalDashboardHomeComponent.scss";
import { getLocalStorage, setLocalStorage } from "../../utils/storageHelpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import filtersTransformations from "../../utils/filterTransformations";
import moment from "moment";
import dateTransform from "../../utils/dateTransform";
import { safeParseJSONSessionStorage } from "../../utils/getBrowserStorageData";
import { getGlobalSelectedBrandAndMarkets } from "../../utils/URLParamsValidations";
import {
  getAgeSegmentsNameBasedOnMarket,
  getGlobalMarketsBasedonBrand,
  getSelectedGlobalBrandMarketsIds,
  handleLabelsBasedonMarket,
} from "../../utils/common";

class GlobalDashboardHomeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandData: [],
      geoList: [],
      loading: true,
      readyShow: true,
      variableData: [],
      scrollWrapperService: null,
      scrollWrapperHeight: null,
      selectedBrand: this.props.selectedBrand,
    };
    this.twoByTwoChartAxisPatchService = new TwoByTwoChartAxisPatchService();
    this.wrapperRef = React.createRef();
    this.props.setRef(this.wrapperRef);
  }

  updateListData = (listData) => {
    const { setTrendsData, selectedTab, interval } = this.props;
    // listData items structure for Trends is slightly different
    listData.sort((a, b) => a.campaign?.position - b.campaign?.position);
    const parsedListData = listData.map((listDataItem) => {
      return {
        ...listDataItem,
      };
    });

    const notTrendsTab = selectedTab !== 4;
    this.props.setDataAvailability(
      listData.every((item) =>
        notTrendsTab ? !isEmpty(item.data.data) : !isEmpty(item.data)
      )
    );
    this.setState({
      loading: false,
      readyShow: true,
      brandData: parsedListData,
    });
    if (!notTrendsTab) {
      setTrendsData(dateTransform(listData, interval));
    }
  };

  getTendsOptionsData = (trendsData) => {
    this.setState({ trendsDataName: trendsData });
  };

  groupBy = (arr, property) => {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  getDefaultListData = (tab, defaultData) => {
    const bcolors = initData.getColors().map((color) => color[0]);
    let { selectedBrand } = this.props;
    let brands = JSON.parse(getLocalStorage("globalBrands"));
    const branddata = [];
    brands[selectedBrand].marketGroups.forEach((obj) => {
      obj.marketGroupsList.forEach((campaign) => {
        return branddata.push(campaign);
      });
    });
    return branddata.map((item, index) => {
      const brandColor = bcolors[index % 10];
      return {
        tab: tab,
        brand: item,
        color: brandColor,
        data: defaultData,
      };
    });
  };

  getDefaultTrendsData = (stab, campaign) => {
    const bcolors = initData.getColors().map((color) => color[0]);
    let finalData = [];
    campaign.forEach((item, ind) => {
      item.marketGroupsList.filter((geo) => {
        let color = geo?.color;
        if (!color) {
          color = bcolors[ind % 10];
        }
        return finalData.push({
          tab: 4,
          stab: stab,
          campaign: geo,
          color: color,
          brandShow: false,
          data: [],
          regionState: item.groupName,
          countryCode: item.groupName,
        });
      });
    });
    return finalData;
  };

  onSelectedMarket = (brands, selectedBrand, inputId, groupName) => {
    let selectedMarket;
    brands[selectedBrand].marketGroups.forEach((obj) =>
      obj.marketGroupsList.filter((geo) => {
        if (geo.marketId === inputId) {
          selectedMarket = geo;
          if (groupName) {
            selectedMarket = obj.groupName;
          }
        }
      })
    );
    return selectedMarket;
  };

  getListData = async () => {
    const {
      currentVariableGroup,
      currentVariableMainFilter,
      interval,
      selectedBrand,
      selectedTab,
      useExposed,
      selectedDate,
      lineOption,
      selectedPeriod,
      currentVariableFilter,
      brands,
      segments,
      page,
      getAppliedFilters,
      labelsList,
    } = this.props;
    this.setState({ loading: true });
    const bcolors = initData.getColors().map((c) => {
      return c[0];
    });
    const brand = brands[selectedBrand];
    let startDate = selectedDate.startdate;
    if (
      (selectedTab === 3 ||
        (selectedTab === 4 && lineOption.stab === 3) ||
        (selectedTab === 5 && currentVariableGroup === 5)) &&
      selectedPeriod === 1 &&
      moment(startDate).isBefore("2022-09-21")
    ) {
      startDate = "2022-09-21";
    }
    let { geoIds } = getSelectedGlobalBrandMarketsIds(brand);
    let optionModes = initData.getModes();
    let selectedMode = optionModes
      .filter((obj) => obj.id === useExposed)[0]
      ?.name?.toUpperCase()
      .replace(/ /g, "");
    let searchFilter = getAppliedFilters();
    let filters = JSON.parse(getLocalStorage("filters"));
    let updateGlobalSegments = segments?.map((ele) => {
      let segmentsIndex = filters?.findIndex(
        ({ categoryType, categoryId }) =>
          categoryType === "USER" && categoryId === null
      );
      segmentsIndex >= 0 &&
        filters[segmentsIndex].filterResponse.map((obj) => {
          ele.segments = ele?.segments?.map((val) => {
            if (val?.segmentId == obj?.filterId && val?.module === "GLOBAL") {
              val.segmentMarkets = obj.filterMarket;
            }
            return val;
          });
        });
      return ele;
    });

    let selectedFilters = filtersTransformations.applyMarketFilters(
      searchFilter,
      updateGlobalSegments,
      selectedBrand
    );
    let body = {
      geoIds,
      brandIds: [brand.id],
      fromDate: startDate,
      toDate: selectedDate.enddate,
      filters: selectedFilters[0]?.filters,
      segments: selectedFilters[0]?.segments,
      audienceType: selectedMode,
      module: initData.getModuleType(page),
      statTesting: this.props?.statTestingEnabled,
    };

    if (selectedTab === 0) {
      ApiData.getGeoEquityData(body)
        .then((result) => {
          this.updateListData(
            result.map((item, index) => {
              const brandColor = bcolors[index % 10];
              const data = {
                value: Number(item.equity ? item.equity?.toFixed(1) : 0),
                upDown: item.equityDiff?  Number(item.equityDiff?.toFixed(1)): null,
                pool: Number(item.pool?.toFixed(2)),
                data: handleLabelsBasedonMarket(
                  labelsList,
                  item,
                  'global'
                ),
              };
              return {
                tab: 0,
                brand: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                campaign: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                color: brandColor,
                data: data,
                lowerThan: transformHigherLowerThan(item.lowerThan),
                higherThan: transformHigherLowerThan(item.higherThan),
                name: this.onSelectedMarket(brands, selectedBrand, item.inputId)
                  .marketName,
                regionState: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ).region,
                groupName: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId,
                  "groupName"
                ),
              };
            })
          );
        })
        .catch((err) => {
          console.log("error=" + err);
          this.updateListData(
            this.getDefaultListData(0, {
              sample: 0,
              value: 0,
              upDown: null,
              data: [],
            })
          );
        });
      return null;
    }

    if (selectedTab === 1) {
      body.audienceType = "GENPOP";
      ApiData.getGeoConversionData(body)
        .then((result) => {
          this.updateListData(
            result.map((item, index) => {
              const brandColor = bcolors[index % 10];
              const data = handleLabelsBasedonMarket(
                labelsList,
                item,
                'global'
              );
              data.sort((a, b) => {
                return b.value - a.value;
              });
              return {
                tab: 1,
                brand: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                campaign: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                color: brandColor,
                data: { pool: Number(item.pool.toFixed(2)), data: data },
                lowerThan: item.lowerThan,
                higherThan: item.higherThan,
                name: this.onSelectedMarket(brands, selectedBrand, item.inputId)
                  .marketName,
                regionState: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ).region,
                groupName: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId,
                  "groupName"
                ),
              };
            })
          );
        })
        .catch((err) => {
          console.log("error=" + err);
          this.updateListData(
            this.getDefaultListData(1, { pool: 0, data: [] })
          );
        });
    }

    if (selectedTab === 2) {
      ApiData.getGeoImageryData(body)
        .then((result) => {
          this.updateListData(
            result.map((item, index) => {
              const brandColor = bcolors[index % 10];
              const data = handleLabelsBasedonMarket(
                labelsList,
                item,
                'global'
              );

              return {
                tab: 2,
                brand: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                campaign: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ),
                color: brandColor,
                data: { pool: Number(item["pool"]?.toFixed(2)), data: data },
                lowerThan: item.lowerThan,
                higherThan: item.higherThan,
                name: this.onSelectedMarket(brands, selectedBrand, item.inputId)
                  .marketName,
                regionState: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId
                ).region,
                groupName: this.onSelectedMarket(
                  brands,
                  selectedBrand,
                  item.inputId,
                  "groupName"
                ),
              };
            }),
            () =>
              this.updateListData(
                this.getDefaultListData(2, { pool: 0, data: [] })
              )
          );
        })
        .catch((err) => {
          console.log("error=" + err);
          this.updateListData(
            this.getDefaultListData(2, { pool: 0, data: [] })
          );
        });
    }

    if (selectedTab === 3) {
      try {
        let result = await ApiData.getGeoAdvertisingData(body);
        this.updateListData(
          result.map((item, index) => {
            const brandColor = bcolors[index % 10];
            const data = handleLabelsBasedonMarket(
              labelsList,
              item,
              'global'
            );

            return {
              tab: 3,
              brand: this.onSelectedMarket(brands, selectedBrand, item.inputId),
              campaign: this.onSelectedMarket(
                brands,
                selectedBrand,
                item.inputId
              ),
              color: brandColor,
              data: {
                pool: Number(item["pool"]?.toFixed(2)),
                upDown: item.advertisingRecallDiff ? Number(item.advertisingRecallDiff?.toFixed(1)): null,
                upDownNA: item.advertisingRecallDiff === null,
                data: data,
              },
              lowerThan: item?.lowerThan,
              higherThan: item?.higherThan,
              name: this.onSelectedMarket(brands, selectedBrand, item.inputId)
                .marketName,
              regionState: this.onSelectedMarket(
                brands,
                selectedBrand,
                item.inputId
              ).region,
              groupName: this.onSelectedMarket(
                brands,
                selectedBrand,
                item.inputId,
                "groupName"
              ),
            };
          })
        );
      } catch (err) {
        console.log("error=" + err);
        this.updateListData(
          this.getDefaultListData(3, {
            pool: 0,
            upDown: null,
            upDownNA: true,
            data: [],
          })
        );
      }
    }

    if (selectedTab === 4) {
      const labelsText = initData.getLabelsText();
      let body = {
        geoIds,
        brandIds: [brand.id],
        fromDate: startDate,
        toDate: selectedDate.enddate,
        filters: selectedFilters[0]?.filters,
        segments: selectedFilters[0]?.segments,
        audienceType: selectedMode,
        trend: { intervalUnit: interval },
        module: initData.getModuleType(page),
        statTesting: this.props?.statTestingEnabled,
      };
      if (lineOption.stab === 0) {
        ApiData.getGeoEquityData(body, true)
          .then((result) => {
            if (result?.length > 0) {
              result = this.groupBy(result, "inputId");
              let finalData = [],
                selectedCampain,
                region,
                countryCode;
              Object.keys(result).forEach((obj, ind) => {
                let data = [];
                result[obj].forEach((r) => {
                  data.push({
                    date: r.date,
                    pool: Number(r.pool ? r.pool.toFixed(2) : 0),
                    total: Number(r.equity ? r.equity.toFixed(1) : 0),
                    poolTotal: Number(r.poolTotal?.toFixed(2)),
                    dailyAverageSampleSize: Number(
                      r.dailyAverageSampleSize?.toFixed(2)
                    ),
                    momentum: Number(
                      r[lineOption?.selectedScoreOption + "NetMomentum"]
                        ? r[
                            lineOption.selectedScoreOption + "NetMomentum"
                          ].toFixed(1)
                        : 0
                    ),
                    consideration: Number(
                      r[lineOption?.selectedScoreOption + "Consideration"]
                        ? r[
                            lineOption.selectedScoreOption + "Consideration"
                          ].toFixed(1)
                        : 0
                    ),
                    quality: Number(
                      r[lineOption?.selectedScoreOption + "HighQuality"]
                        ? r[
                            lineOption.selectedScoreOption + "HighQuality"
                          ].toFixed(1)
                        : 0
                    ),
                    familiarity: Number(
                      r.familiarity ? r.familiarity.toFixed(1) : 0
                    ),
                    lowerThan: r?.lowerThan,
                    higherThan: r?.higherThan,
                  });
                });
                brands[selectedBrand].marketGroups.forEach((item) =>
                  item.marketGroupsList.filter((geo) => {
                    if (geo.marketId == obj) {
                      selectedCampain = geo;
                      region = geo.region;
                      countryCode = item.groupName;
                    }
                  })
                );
                let color = selectedCampain.color;
                if (!color) {
                  color = bcolors[ind % 10];
                }
                finalData.push({
                  tab: 4,
                  stab: 0,
                  campaign: selectedCampain,
                  color: color,
                  brandShow: true,
                  data: data,
                  regionState: region,
                  countryCode: countryCode,
                });
              });
              this.updateListData(finalData);
            } else {
              let stab = 0;
              this.updateListData(
                this.getDefaultTrendsData(
                  stab,
                  brands[selectedBrand].marketGroups
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=" + err);
            let stab = 0;
            this.updateListData(
              this.getDefaultTrendsData(
                stab,
                brands[selectedBrand].marketGroups
              )
            );
          });
      }
      if (lineOption.stab === 1) {
        ApiData.getGeoConversionData(body, true)
          .then((result) => {
            if (result?.length > 0) {
              result = this.groupBy(result, "inputId");
              let finalData = [],
                selectedCampain,
                region,
                countryCode;
              Object.keys(result).map((obj, ind) => {
                let data = [];
                result[obj].forEach((r) => {
                  data.push({
                    date: r.date,
                    pool: Number(r.pool ? r.pool.toFixed(2) : 0),
                    awareness: Number(r.awareness ? r.awareness.toFixed(1) : 0),
                    poolTotal: Number(r.poolTotal?.toFixed(2)),
                    dailyAverageSampleSize: Number(
                      r.dailyAverageSampleSize?.toFixed(2)
                    ),
                    familiarity: Number(
                      r.familiarity ? r.familiarity.toFixed(1) : 0
                    ),
                    trial: Number(r.trial ? r.trial.toFixed(1) : 0),
                    usage: Number(r.usage ? r.usage.toFixed(1) : 0),
                    recommend: Number(
                      r[lineOption?.selectedScoreOption + "Recommend"]
                        ? r[
                            lineOption.selectedScoreOption + "Recommend"
                          ].toFixed(1)
                        : 0
                    ),
                    lowerThan: r?.lowerThan,
                    higherThan: r?.higherThan,
                  });
                });
                brands[selectedBrand].marketGroups.map((item) =>
                  item.marketGroupsList.filter((geo) => {
                    if (geo.marketId == obj) {
                      selectedCampain = geo;
                      region = geo.region;
                      countryCode = item.groupName;
                    }
                  })
                );
                let color = selectedCampain.color;
                if (!color) {
                  color = bcolors[ind % 10];
                }
                finalData.push({
                  tab: 4,
                  stab: 1,
                  campaign: selectedCampain,
                  color: color,
                  brandShow: true,
                  data: data,
                  regionState: region,
                  countryCode: countryCode,
                });
              });
              this.updateListData(finalData);
            } else {
              let stab = 1;
              this.updateListData(
                this.getDefaultTrendsData(
                  stab,
                  brands[selectedBrand].marketGroups
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=" + err);
            let stab = 1;
            this.updateListData(
              this.getDefaultTrendsData(
                stab,
                brands[selectedBrand].marketGroups
              )
            );
          });
      }
      if (lineOption.stab === 2) {
        ApiData.getGeoImageryData(body, true)
          .then((result) => {
            if (result?.length > 0) {
              result = this.groupBy(result, "inputId");
              let finalData = [],
                selectedCampain,
                region,
                countryCode;
              Object.keys(result).map((obj, ind) => {
                let data = [];
                result[obj].forEach((r) => {
                  const rdata = {
                    date: r.date,
                    pool: Number(r.pool ? r.pool.toFixed(2) : 0),
                    poolTotal: Number(r.poolTotal?.toFixed(2)),
                    dailyAverageSampleSize: Number(
                      r.dailyAverageSampleSize?.toFixed(2)
                    ),
                    lowerThan: r.lowerThan,
                    higherThan: r.higherThan,
                  };
                  for (const key in r) {
                    if (labelsText[key] !== undefined) {
                      rdata[key] = Number(r[key] ? r[key].toFixed(1) : 0);
                    }
                  }
                  data.push(rdata);
                });
                brands[selectedBrand].marketGroups.map((item) =>
                  item.marketGroupsList.filter((geo) => {
                    if (geo.marketId == obj) {
                      selectedCampain = geo;
                      region = geo.region;
                      countryCode = item.groupName;
                    }
                  })
                );
                let color = selectedCampain.color;
                if (!color) {
                  color = bcolors[ind % 10];
                }
                finalData.push({
                  tab: 4,
                  stab: 2,
                  campaign: selectedCampain,
                  color: color,
                  brandShow: true,
                  data: data,
                  regionState: region,
                  countryCode: countryCode,
                });
              });
              this.updateListData(finalData);
            } else {
              let stab = 2;
              this.updateListData(
                this.getDefaultTrendsData(
                  stab,
                  brands[selectedBrand].marketGroups
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=" + err);
            let stab = 2;
            this.updateListData(
              this.getDefaultTrendsData(
                stab,
                brands[selectedBrand].marketGroups
              )
            );
          });
      }
      if (lineOption.stab === 3) {
        ApiData.getGeoAdvertisingData(body, true)
          .then((result) => {
            if (result?.length > 0) {
              result = this.groupBy(result, "inputId");
              let finalData = [],
                selectedCampain,
                region,
                countryCode;
              Object.keys(result).map((obj, ind) => {
                let data = [];
                result[obj].forEach((r) => {
                  const rdata = {
                    date: r.date,
                    pool: Number(r.pool ? r.pool.toFixed(2) : 0),
                    poolTotal: Number(r.poolTotal?.toFixed(2)),
                    dailyAverageSampleSize: Number(
                      r.dailyAverageSampleSize?.toFixed(2)
                    ),
                    lowerThan: r.lowerThan,
                    higherThan: r.higherThan,
                  };
                  for (const key in r) {
                    if (labelsText[key] !== undefined) {
                      rdata[key] = Number(r[key] ? r[key].toFixed(1) : 0);
                    }
                  }
                  data.push(rdata);
                });
                brands[selectedBrand].marketGroups.map((item) =>
                  item.marketGroupsList.filter((geo) => {
                    if (geo.marketId == obj) {
                      selectedCampain = geo;
                      region = geo.region;
                      countryCode = item.groupName;
                    }
                  })
                );
                let color = selectedCampain.color;
                if (!color) {
                  color = bcolors[ind % 10];
                }
                finalData.push({
                  tab: 4,
                  stab: 3,
                  campaign: selectedCampain,
                  color: color,
                  brandShow: true,
                  data: data,
                  regionState: region,
                  countryCode: countryCode,
                });
              });
              this.updateListData(finalData);
            } else {
              let stab = 3;
              this.updateListData(
                this.getDefaultTrendsData(
                  stab,
                  brands[selectedBrand].marketGroups
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=" + err);
            let stab = 3;
            this.updateListData(
              this.getDefaultTrendsData(
                stab,
                brands[selectedBrand].marketGroups
              )
            );
          });
      }
      if (lineOption.stab === 4) {
        ApiData.getGeoIrreplaceabilityData(body, true)
          .then((result) => {
            if (result?.length > 0) {
              result = this.groupBy(result, "inputId");
              let finalData = [],
                selectedCampain,
                region,
                countryCode;
              Object.keys(result).forEach((obj, ind) => {
                let data = [];
                result[obj].forEach((r) => {
                  data.push({
                    date: r?.date,
                    pool: Number(r.pool ? r.pool.toFixed(2) : 0),
                    // total:Number(r.equity?r.equity.toFixed(1):0),
                    poolTotal: Number(r.poolTotal?.toFixed(2)),
                    dailyAverageSampleSize: Number(
                      r.dailyAverageSampleSize?.toFixed(2)
                    ),
                    positive: Number(r.positive ? r.positive.toFixed(1) : 0),
                    neutral: Number(r.neutral ? r.neutral.toFixed(1) : 0),
                    negative: Number(r.negative ? r.negative.toFixed(1) : 0),
                    net: Number(r.net ? r.net.toFixed(1) : 0),
                    lowerThan: r?.lowerThan,
                    higherThan: r?.higherThan,
                  });
                });
                brands[selectedBrand].marketGroups.forEach((item) =>
                  item.marketGroupsList.filter((geo) => {
                    if (geo.marketId == obj) {
                      selectedCampain = geo;
                      region = geo.region;
                      countryCode = item.groupName;
                    }
                  })
                );
                let color = selectedCampain.color;
                if (!color) {
                  color = bcolors[ind % 10];
                }
                finalData.push({
                  tab: 4,
                  stab: 4,
                  campaign: selectedCampain,
                  color: color,
                  brandShow: true,
                  data: data,
                  regionState: region,
                  countryCode: countryCode,
                });
              });
              this.updateListData(finalData);
            } else {
              let stab = 4;
              this.updateListData(
                this.getDefaultTrendsData(
                  stab,
                  brands[selectedBrand].marketGroups
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=" + err);
            let stab = 4;
            this.updateListData(
              this.getDefaultTrendsData(
                stab,
                brands[selectedBrand].marketGroups
              )
            );
          });
      }
    }

    if (selectedTab === 5) {
      // this.setState({ variableData: [] });
      let payload = {
        geoIds,
        brandIds: [brand.id],
        fromDate: startDate,
        toDate: selectedDate.enddate,
        filters: selectedFilters[0]?.filters,
        segments: selectedFilters[0]?.segments,
        audienceType: selectedMode,
        profile: {
          groupId: currentVariableMainFilter,
          metricId: currentVariableGroup,
          responseId: currentVariableFilter.index,
          isAge18: currentVariableGroup === 1,
        },
        isAge18: true,
        module: initData.getModuleType(page),
        statTesting: this.props?.statTestingEnabled,
      };
      ApiData.getGeoVariableData(payload)
        .then((result) => {
          if (currentVariableGroup !== 1) {
            result = result.filter((val) => Number(val.inputId) !== -1);
          }
          result.map((obj) => {
            obj.countryCode = this.onSelectedMarket(
              brands,
              selectedBrand,
              obj.inputId,
              "groupName"
            );
          });
          this.props.setDataAvailability(!isEmpty(result));
          this.setState({
            loading: false,
            readyShow: true,
            variableData: result,
          });
        })
        .catch((err) => {
          console.log("error=" + err);
          this.props.setDataAvailability(false);
          this.setState({ loading: false });
        });
      return;
    }
  };

  componentDidMount() {
    const { brands, setTwoByTwoBrands } = this.props;
    setTwoByTwoBrands(brands);
    this.initializeAndUseScrollWrapperService();
    window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps) {
    const serviceInitialized = Boolean(this.state.scrollWrapperService);
    const menuStateDifferent =
      prevProps.menuExpand !== this.props.menuExpand ||
      prevProps.filtersOn !== this.props.filtersOn;

    if (serviceInitialized && menuStateDifferent) {
      setTimeout(() => {
        const scrollWrapperHeight =
          this.state.scrollWrapperService.calculateScrollWrapperHeight();

        this.setState({ scrollWrapperHeight });
      }, 500);
    }
    // if (this.props?.statTestingEnabled !== prevProps?.statTestingEnabled) {
    //   this.getListData();
    // }
    // if (!isEqual(this.props.brands, prevProps.brands)) {
    //     this.setState({readyShow: false})
    //     this.getListData()
    // }
  }

  componentWillUnmount() {
    const { clearAllTwoByTwoData, clearAllTrendsData } = this.props;

    clearAllTwoByTwoData();
    clearAllTrendsData();
    window.removeEventListener("resize", this.onResize);
  }

  onChangeTwoByTwoOption = (data) => {
    this.getListData(data);
  };

  initializeAndUseScrollWrapperService = () => {
    const scrollWrapperService = new EquityScrollWrapperHeightService(
      selectors
    );
    const scrollWrapperHeight =
      scrollWrapperService.calculateScrollWrapperHeight();

    this.setState({
      scrollWrapperService,
      scrollWrapperHeight,
    });
  };

  onResize = () => {
    setTimeout(() => {
      const scrollWrapperHeight =
        this.state.scrollWrapperService.calculateScrollWrapperHeight();

      this.setState({ scrollWrapperHeight });
    }, 500);
  };

  emulateLoading = (loading, readyShow) =>
    this.setState({
      loading,
      readyShow,
    });

  // Implementation of Draggable and Reordering of the Brands with React-beautiful-dnd
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  changeGeosOrder = async (userId, checkData, selectedMarketIndexes) => {
    await ApiData.updateGeoOrderOrColour(userId, checkData);
    await this.props.onGeosChange(selectedMarketIndexes);
  };

  onDragEnd = async (result) => {
    let { brands, selectedBrand } = this.props;
    try {
      if (!result.destination) {
        return;
      }
      clearTimeout(this.debounceTimer);
      const items = this.reorder(
        this.state.brandData,
        result.source.index,
        result.destination.index
      );
      const data = await items.map((b, i) => {
        b.brand.position = i + 1;
        return b;
      });

      let allMarkets = brands[this.props.selectedBrand].marketGroups.reduce(
        (acc, obj) => {
          acc.push(...obj.marketGroupsList);
          obj?.marketGroupsList?.length > 0 &&
            obj.marketGroupsList.map((ele) => (ele.groupName = obj.groupName));
          return acc;
        },
        []
      );

      const combinedArray = items.map((ele) => ele.campaign).concat(allMarkets);
      let otherMarkets = combinedArray.filter(
        (obj) =>
          !items.some((ele) => obj.marketId === ele.campaign.marketId) ||
          !allMarkets.some((ele) => obj.marketId === ele.marketId)
      );

      let updatedMarketOrderList = [
        ...items?.map((b) => {
          b.campaign.groupName = b.groupName;
          return b.campaign;
        }),
        ...otherMarkets,
      ];
      updatedMarketOrderList = updatedMarketOrderList?.map((b, i) => {
        b.position = i + 1;
        return b;
      });
      let finalResult = [];
      brands[this.props.selectedBrand].marketGroups.map((ele) =>
        ele.marketGroupsList.map((it) => {
          {
            updatedMarketOrderList.map((obj) => {
              let result = finalResult?.filter(
                (a) => a.groupName === obj.groupName
              );
              if (obj.marketId === it.marketId) {
                it.position = obj.position;
                if (result?.length == 0) {
                  finalResult.push(ele);
                }
              }
            });
          }
        })
      );

      this.setState({ brandData: data });
      brands[this.props.selectedBrand].marketGroups = finalResult;
      setLocalStorage("globalBrands", JSON.stringify(brands));
      const myUserInfo = getLocalStorage("userInfo");
      const userId = myUserInfo ? JSON.parse(myUserInfo).id : "USER";
      let checkData = [];
      await data.forEach((item) => {
        checkData.push({
          entityId: item.brand.marketId,
          position: item.brand.position,
        });
      });
      let selectedURLMarkets = safeParseJSONSessionStorage(
        "userSelectedGlobalMarkets"
      )?.find(({ brandId }) => brandId == brands[selectedBrand]?.id)?.markets;
      let { selectedMarketIndexes } = getGlobalSelectedBrandAndMarkets(
        brands[selectedBrand]?.id,
        brands[0]?.id,
        selectedURLMarkets
      );
      this.debounceTimer = setTimeout(
        () => this.changeGeosOrder(userId, checkData, selectedMarketIndexes),
        1500
      );
    } catch (error) {
      if (error.message == "Network Error")
        this.setState({ sessionTimeoutErr: true });
      console.log("Updating  error", error);
    }
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: this.state.grid * 2,
    margin: `0 ${this.state.grid}px 0 0`,
    background: isDragging ? "lightgreen" : "grey",

    ...draggableStyle,
  });

  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col) {
      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  getColors = (color) => {
    if (color) {
      const newColors = color.reduce((acc, next) => {
        const colorCode = this.LightenDarkenColor(next, 80);
        let newAcc = [...acc];
        newAcc.push(next, colorCode);
        return newAcc;
      }, []);
      const colors = newColors;
      return colors;
    }
  };
  getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver && this.props.selectedTab != 5 ? "white" : "",
    flexGrow: 1,
    marginRight: isDraggingOver ? "200px" : "0px",
  });

  //End of the Draggable Implementation

  render() {
    const { brandData, loading, readyShow, variableData } = this.state;
    const {
      selectedTab,
      horizontalMenu,
      selectedPeriod,
      enddate,
      startdate,
      lineOption,
      onChangeLineOption,
      brands,
      onSelectVariableMainFilter,
      onSelectVariableFilter,
      onSelectVariableGroup,
      currentVariableFilter,
      currentVariableGroup,
      currentVariableMainFilter,
      variableGroupOptions,
      mainVariableFilterOptions,
      variableFilterOptions,
      variableOptionOpen,
      setVariableOptionOpen,
      selectedBrand,
      axisSelectOptionChange,
      yAxisSelect,
      statTestingEnabled,
      page,
      useExposed,
      downloadexport,
      selectedDate,
    } = this.props;
    const profileTabIndex = 5;
    let chartContent = [];
    let selectedURLMarkets = safeParseJSONSessionStorage(
      "userSelectedGlobalMarkets"
    )?.find(({ brandId }) => brandId == brands[selectedBrand]?.id)?.markets;
    let isAllHSSMarkets = selectedURLMarkets.every((ele) => ele.includes("HSS"))
      ? "HSS"
      : "";

    if (
      horizontalMenu === "secondary" &&
      selectedTab !== 4 &&
      selectedTab !== 5
    ) {
      chartContent.push(
        <BarChart
          key="barChart"
          selectedTab={selectedTab}
          selectedBrand={selectedBrand}
          data={brandData.filter((b) => {
            return b.tab === selectedTab;
          })}
          isDetailed={false}
          statTestingEnabled={
            statTestingEnabled === "POP" || statTestingEnabled === "YOY"
          }
          page={page}
          cperiod={
            selectedPeriod > 1
              ? initData
                  .getPeriodOptions()
                  ?.find(({ id }) => id === selectedPeriod)?.name
              : startdate + " to " + enddate
          }
          hiddenList={this.props.hiddenList}
          getList={this.props.onGeosChange}
          emulateLoading={this.emulateLoading}
          selectedDate={selectedDate}
        />
      );
    } else {
      switch (selectedTab) {
        default:
        case 0:
          let equityData = brandData
            .filter((b) => {
              return b.tab === 0;
            })
            .sort((x, y) => {
              return y.data.number - x.data.number;
            });
          equityData.forEach((bData, index) => {
            chartContent.push(
              <Draggable
                key={bData.brand.marketId}
                draggableId={`${bData.brand.marketId}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    className="drag"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <EquityCard
                      key={"eq" + index}
                      tag={"eq" + index}
                      id={index}
                      brand={
                        bData.brand.marketName
                          ? bData.brand.marketName
                          : bData.brand.groupName
                      }
                      logo={bData.brand.logo}
                      data={bData.data.data}
                      colors={initData.getDonutColors()}
                      chartTitle={"EQUITY"}
                      pool={bData.data.pool}
                      number={bData.data.value}
                      upDown={bData.data.upDown}
                      upDownNA={bData.data.upDownNA}
                      cperiod={
                        selectedPeriod > 1
                          ? initData
                              .getPeriodOptions()
                              ?.find(({ id }) => id === selectedPeriod)?.name
                          : startdate + " to " + enddate
                      }
                      statTestingEnabled={
                        statTestingEnabled === "POP" ||
                        statTestingEnabled === "YOY"
                      }
                      higherThan={bData.higherThan}
                      lowerThan={bData.lowerThan}
                      page={page}
                      selectedTab={selectedTab}
                      countryCode={bData.groupName}
                      selectedDate={selectedDate}
                    />
                  </div>
                )}
              </Draggable>
            );
          });
          break;
        case 1:
          brandData
            .filter((b) => {
              return b.tab === 1;
            })
            .forEach((bData, index) => {
              let data = [];
              data.push(bData.brand.color);
              chartContent.push(
                <Draggable
                  key={bData.brand.marketId}
                  draggableId={`${bData.brand.marketId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FunnelChartCard
                        key={"cv" + index}
                        tag={"cv" + index}
                        id={index}
                        data={bData.data.data}
                        colors={this.getColors(data)}
                        brand={
                          bData.brand.marketName
                            ? bData.brand.marketName
                            : bData.brand.groupName
                        }
                        logo={bData.brand.logo}
                        brandValue={bData.data.pool}
                        hightRatio={0.8}
                        lowerThan={bData.lowerThan}
                        higherThan={bData.higherThan}
                        statTestingEnabled={
                          statTestingEnabled === "POP" ||
                          statTestingEnabled === "YOY"
                        }
                        countryCode={bData.groupName}
                        cperiod={
                          selectedPeriod > 1
                            ? initData
                                .getPeriodOptions()
                                ?.find(({ id }) => id === selectedPeriod)?.name
                            : startdate + " to " + enddate
                        }
                        selectedDate={selectedDate}
                      />
                    </div>
                  )}
                </Draggable>
              );
            });
          break;
        case 2:
          brandData
            .filter((b) => {
              return b.tab === 2;
            })
            .forEach((bData, index) => {
              let data = [];
              data.push(bData.brand.color);
              chartContent.push(
                <Draggable
                  key={bData.brand.marketId}
                  draggableId={`${bData.brand.marketId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      style={{ transition: "transform 0.2s;" }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <EmotionalCard
                        key={"em" + index}
                        label="emotional"
                        brand={
                          bData.brand.marketName
                            ? bData.brand.marketName
                            : bData.brand.groupName
                        }
                        logo={bData.brand.logo}
                        brandValue={bData.data.pool}
                        data={bData.data.data}
                        colors={this.getColors(data)}
                        lowerThan={bData.lowerThan}
                        higherThan={bData.higherThan}
                        statTestingEnabled={
                          statTestingEnabled === "POP" ||
                          statTestingEnabled === "YOY"
                        }
                        countryCode={bData.groupName}
                        cperiod={
                          selectedPeriod > 1
                            ? initData
                                .getPeriodOptions()
                                ?.find(({ id }) => id === selectedPeriod)?.name
                            : startdate + " to " + enddate
                        }
                        selectedDate={selectedDate}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              );
            });
          break;
        case 3:
          brandData
            .filter((b) => {
              return b.tab === 3;
            })
            .forEach((bData, index) => {
              let data = [];
              data.push(bData.brand.color);
              chartContent.push(
                <Draggable
                  key={bData.brand.marketId}
                  draggableId={`${bData.brand.marketId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <AdvertisingCard
                        key={"eq" + index}
                        tag={"eq" + index}
                        id={index}
                        label="advertising"
                        brand={
                          bData.brand.marketName
                            ? bData.brand.marketName
                            : bData.brand.groupName
                        }
                        logo={bData.brand.logo}
                        colors={this.getColors(data)}
                        data={bData.data.data}
                        chartTitle={"AD RECALL"}
                        pool={bData.data.pool}
                        upDown={bData.data.upDown}
                        upDownNA={bData.data.upDownNA}
                        number={
                          bData?.data?.data?.filter(
                            (item) => item.label === "Advertising Recall"
                          )[0]?.value
                        }
                        cperiod={
                          selectedPeriod > 1
                            ? initData
                                .getPeriodOptions()
                                ?.find(({ id }) => id === selectedPeriod)?.name
                            : startdate + " to " + enddate
                        }
                        selectedDate={selectedDate}
                        statTestingEnabled={
                          statTestingEnabled === "POP" ||
                          statTestingEnabled === "YOY"
                        }
                        higherThan={bData.higherThan}
                        lowerThan={bData.lowerThan}
                        page={page}
                        selectedTab={selectedTab}
                        useExposed={useExposed}
                        countryCode={bData.groupName}
                      />
                    </div>
                  )}
                </Draggable>
              );
            });
          break;
        // case 3:
        //     let touchpointsData = brandData.filter(b=>{return b.tab===3})
        //     touchpointsData.forEach( (bData, index)=> {
        //         chartContent.push(<EmotionalCard
        //             key={'tp'+index}
        //             label="touchpoints"
        //             brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
        //             name={bData.name}
        //             regionState={bData.regionState}
        //             logo={bData.brand.logo}
        //             brandValue={bData.data.pool}
        //             data={bData.data.data}
        //             colors = {initData.getColors(index%10+1)}
        //             lowerThan={bData.lowerThan}
        //             higherThan={bData.higherThan}
        //             statTestingEnabled={statTestingEnabled}
        //         />);
        //     })
        //     break;
        case 4:
          brandData?.length> 0 && chartContent.push(
            <LineChart
              getTendsOptionsData={this.getTendsOptionsData}
              interval={this.props.interval}
              key="lineChart"
              stab={lineOption.stab}
              selectedOption={lineOption.selectedOption}
              selectedScoreOption={lineOption.selectedScoreOption}
              onChangeLineOption={
                typeof onChangeLineOption === "function"
                  ? onChangeLineOption
                  : null
              }
              statTestingEnabled={
                statTestingEnabled === "POP" || statTestingEnabled === "YOY"
              }
              page={page}
              emulateLoading={this.emulateLoading}
              axisSelectOptionChange={axisSelectOptionChange}
              onChangeFunction={this.props.onChangePeriodFunction}
              startdate={this.props.selectedDate.startdate}
              enddate={this.props.selectedDate.enddate}
              selectedPeriod={this.props.selectedDate.selectedPeriod}
              dateFilterId={this.props.dateFilterId}
              yAxisSelect={yAxisSelect}
              selectedBrand={selectedBrand}
              getList={this.props.onGeosChange}
              downloadexport={downloadexport}
              selectedMarket={{ countryCode: isAllHSSMarkets }}
              selectedTab={selectedTab}
            />
          );
          break;

        case 5:
          let brands = JSON.parse(getLocalStorage("globalBrands"));
          const actualCampaigns = [];
          const actualCampaingSet = new Set();
          brands[selectedBrand].marketGroups.forEach((mg) => {
            mg.marketGroupsList.map((mgl) => {
              if (!actualCampaingSet.has(mgl.marketId)) {
                actualCampaingSet.add(mgl.marketId);
                return actualCampaigns.push({
                  inputName: mgl.marketName,
                  inputId: mgl.marketId,
                  position: mgl.position,
                  identity: mgl.identity,
                });
              }
            });
          });
          actualCampaigns.sort((a, b) => a.position - b.position);
          selectedURLMarkets = actualCampaigns?.filter((ele) =>
            selectedURLMarkets?.some((id) => id == ele?.identity)
          );
          selectedURLMarkets =
            selectedURLMarkets?.length > 0
              ? selectedURLMarkets
              : actualCampaigns;
          const rd = [
            { inputId: -1, inputName: "Age 18+" },
            ...selectedURLMarkets,
          ];
          let markets = getGlobalMarketsBasedonBrand(brands[selectedBrand]);
          let ageSegmentName = getAgeSegmentsNameBasedOnMarket(markets);
          variableData.forEach((item) => {
            if (item.inputName === "Age 18+") item.inputName = ageSegmentName;
          });
          rd.forEach((item) => {
            if (item.inputName === "Age 18+") item.inputName = ageSegmentName;
          });
          chartContent.push(
            <StackedBarChart
              key={"StackedBarChart"}
              chartType={"geo"}
              data={variableData}
              rowsData={rd}
              onSelectVariableMainFilter={onSelectVariableMainFilter}
              onSelectVariableFilter={onSelectVariableFilter}
              onSelectVariableGroup={onSelectVariableGroup}
              currentVariableFilter={currentVariableFilter}
              currentVariableGroup={currentVariableGroup}
              currentVariableMainFilter={currentVariableMainFilter}
              // variableSubfilterOptions={variableSubfilterOptions}
              mainVariableFilterOptions={mainVariableFilterOptions}
              variableGroupOptions={variableGroupOptions}
              variableFilterOptions={variableFilterOptions}
              variableOptionOpen={variableOptionOpen}
              setVariableOptionOpen={setVariableOptionOpen}
              statTestingEnabled={
                statTestingEnabled === "POP" || statTestingEnabled === "YOY"
              }
            />
            // : <NoGeografies hasAnyGeografies/> :
            // <NoGeografies/>
          );
          break;
      }
    }

    return (
      <div
        ref={this.wrapperRef}
        style={{
          overflow: this.props.selectedTab == 5 ? "inherit" : "auto",
          display: "flex",
          backgroundColor: "#F5F7FA",
        }}
      >
        {/* {sessionTimeoutErr && <SessionTimeoutModal />} */}
        <DragDropContext
          onDragEnd={this.onDragEnd}
          style={{ transition: "transform 0.2s;" }}
        >
          <Droppable
            droppableId="droppable"
            direction="horizontal"
            style={{ transition: "transform 0.2s;" }}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={this.getListStyle(
                  snapshot.isDraggingOver,
                  this.state.brandData.length
                )}
                {...provided.droppableProps}
              >
                <div
                  className={cx({
                    scrollWrapper: selectedTab !== profileTabIndex,
                  })}
                  style={
                    selectedTab !== profileTabIndex
                      ? { height: this.state.scrollWrapperHeight }
                      : {}
                  }
                >
                  <div
                    className={cx("dataItemsContainer", {
                      loading: loading,
                      scrollBarHidden:
                        selectedTab === 4 || horizontalMenu === "secondary",
                    })}
                  >
                    {loading && <LoadingLayer />}
                    {readyShow && chartContent}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

const mapStateToProps = ({ twoByTwoChart }) => ({
  labelsList: twoByTwoChart.labelsList,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setTwoByTwoBrands,
      setTwoByTwoChartData,
      setTrendsData,
      clearAllTwoByTwoData,
      clearAllTrendsData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(GlobalDashboardHomeComponent);
