import React, { Component } from "react";
import cx from "classnames";
import { get } from "lodash";
import initData from "../../../utils/initData";
import BrandTitle from "./BrandTitle";
import hideIcon from "../../../assets/icons/icon_hide.svg";
import "./ItemList.scss";
import ApiData from "../../../utils/Api";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../utils/storageHelpers";
import { ChromePicker } from "react-color";

export default class itemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showColor: false,
      id: "",
      colorChange: false,
      index: "",
      showTooltipId: false,
    };
    this.tooltipTimeout = null;
  }
  showColorPicker = (item, listItem) => {
    this.setState({
      showPicker: true,
      id: item.id,
      color:
        item.brandUpdation === "yes" || this.props.page === "global"
          ? item.color
          : listItem.color,
    });
  };

  handleMouseLeave = (index) => {
    this.setState({ showPicker: false, showColor: false, index: "" });
  };

  onClose = async (listKey, listitem, item, index) => {
    let { selectedMarket } = this.props;
    const myUserInfo = getLocalStorage("userInfo");
    const userId = myUserInfo ? JSON.parse(myUserInfo).id : "USER";
    if (listKey === "brand" && this.props.page === "competitive") {
      let colorObject = {
        entityId: item.brand.id,
        position: null,
        color: this.state.color,
      };
      let result = [];
      result.push(colorObject);
      ApiData.updateBrandsOrderOrColour(userId, selectedMarket, result)
        .then(async () => {
          // brandInfo was updated, but the data in localStorage was not. Let's request the updated one
          await this.props.getList();
          return await ApiData.getUserBrandsByCampaign(userId, selectedMarket);
        })
        .then((brands) => {
          if (brands.length) {
            setLocalStorage(
              "myBrands",
              JSON.stringify(
                brands.map((v) => (v.id === listitem.id ? listitem : v))
              )
            );
          }
        })
        .catch((error) => {});
    }
    if (listKey === "segment") {
      let payload = {
        color: this.state.color,
      };
      ApiData.updateSegmentColor({ segmentId: item.segment.segmentId, payload })
        .then(async () => {
          // segmentsInfo was updated, but the data in localStorage was not. Let's request the updated one
          await this.props.getList(selectedMarket);
          await this.props.getSegments(selectedMarket);
          // return ApiData.getUserSegments(userId);
        })
        .catch((error) => {});
    }
    if (this.props.page === "global") {
      listitem.color = this.state.color;
      item.color = this.state.color;
      let colorObject = {
        entityId: item.campaign.marketId,
        position: null,
        color: this.state.color,
      };
      let result = [];
      result.push(colorObject);

      let brands = JSON.parse(getLocalStorage("globalBrands"));
      brands.map((brand) => {
        brand.marketGroups.filter((obj) =>
          obj.marketGroupsList
            .filter((campaign) => campaign?.marketId === item.campaign.marketId)
            .map((camp) => (camp.color = this.state.color))
        );
      });
      await setLocalStorage("globalBrands", JSON.stringify(brands));
      ApiData.updateGeoOrderOrColour(userId, result);
      await this.props.getList();
    }

    this.setState(
      {
        showColor: true,
        showPicker: false,
      },
      () => {
        this.props.onClickShowOrHide("", true);
      }
    );
  };

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex, colorChange: true });
  };

  getColor = (listColor, listitem) => {
    let { id, showColor, color } = this.state;
    if (listitem.id === id && showColor) {
      return (listColor = color);
    }
    return listitem.brandUpdation === "yes" || this.props.page === "global"
      ? listitem.color
      : listColor;
  };

  handleMouseOver = (id) => {
    // Set a timeout to show the tooltip after 2 seconds
    this.tooltipTimeout = setTimeout(() => {
      this.setState({ showTooltipId: id });
    }, 1000);
  };

  handleMouseOut = () => {
    // Clear the timeout if mouse leaves before the 2 seconds
    clearTimeout(this.tooltipTimeout);
    this.setState({ showTooltipId: -1 });
  };

  render() {
    const {
      listData,
      hiddenList,
      listClass,
      onClickShowOrHide,
      listKey,
      defaultList,
      showSsize,
      statTestingEnabled,
      charKeyMap,
      page,
      movingAverage,
      customSegment,
      customSegmentpage,
      selectedTab,
    } = this.props;
    let { showPicker, showColor, id, color, showTooltipId } = this.state;
    const bcolors = initData.getColors().map((color) => color[0]);
    let regionState;
    return (
      <div className={listClass} id="clickbox">
        {listData.map((item, index) => {
          const listitem = listKey === "self" ? item : item[listKey];
          const listColor = item.color;
          const brands = JSON.parse(localStorage.getItem("myBrands"));
          if (page === "global") {
            item.campaign.marketId = item.campaign?.marketId;
            regionState = item.regionState;
            listitem.id = item.campaign?.marketId;
            listitem.countryCode = item.countryCode
              ? item.countryCode
              : item.groupName;
          }
          if (page === "segments") {
            listitem.name = item.segment.segmentName;
            listitem.id = item.segment.segmentId;
          }
          if (
            Number(listitem.id) === Number(id) &&
            listKey === "brand" &&
            showColor
          ) {
            item.brand.brandUpdation = "yes";
            item.brand.color = color;
            localStorage.setItem(
              "myBrands",
              JSON.stringify(
                brands.map((v) => (v.id === listitem.id ? listitem : v))
              )
            );
          }
          if (
            Number(listitem.id) === Number(id) &&
            listKey === "segment" &&
            showColor
          ) {
            item.color = color;
            item.segment.color = color;
          }

          const itemClass = cx("brandItemRightContainer", {
            noHidden: item?.id === -1,
            brandHidden:
              item?.id !== -1 && hiddenList.indexOf(listitem.id) > -1,
          });
          let samplesize = item.data?.pool || item.data?.base || null;
          let dailyAverageSampleSize = 0;
          const noData = get(item, "data", { filterOptions: {} }).length === 0;
          if (showSsize && Array.isArray(item.data)) {
            samplesize = 0;
            dailyAverageSampleSize = Number(
              item?.data[0]?.dailyAverageSampleSize
            );
            item.data.forEach((d) => {
              if ((d.pool || d.poolTotal) !== null) {
                samplesize += Number(movingAverage ? d.poolTotal : d.pool);
              }
            });
          }
          const defaultAgeSegmentNames = initData.getDefaultAgeSegmentNames();
          const brandKey = listitem?.marketName ? listitem?.marketName : listitem.name;
          return (
            <>
              <div className="brandItem" key={"litem" + listitem.id}>
                <div
                  key={index}
                  onMouseLeave={() => this.handleMouseLeave(index)}
                >
                  {listitem.id === id && showPicker ? (
                    <div
                      className={
                        index <= 2
                          ? "show-color-picker"
                          : "show-color-picker-conatiner"
                      }
                      onMouseLeave={() => this.handleMouseLeave(index)}
                    >
                      <div className="color-picker">
                        <ChromePicker
                          className="arrow-right"
                          color={color}
                          id="chrome-picker"
                          onChange={this.handleChangeComplete}
                          disableAlpha={true}
                          width="50%"
                          renderers={false}
                        />
                        <button
                          className="colorSaveButton"
                          onClick={() =>
                            this.onClose(listKey, listitem, item, index)
                          }
                        >
                          Save Color
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="colorButtonContainer"
                    onMouseOver={() => this.handleMouseOver(listitem.id)}
                    onMouseOut={this.handleMouseOut}
                    onClick={() => this.showColorPicker(listitem, item)}
                  >
                    <div
                      className="legend"
                      style={{
                        backgroundColor: this.getColor(
                          listColor ? listColor : bcolors[index % 10],
                          listitem
                        ),
                      }}
                    />
                    {showTooltipId === listitem.id && !showPicker && (
                      <span className="tooltipText">
                        Please click here to change color.
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={itemClass}
                  style={{ display: "block" }}
                  onClick={() => onClickShowOrHide(listitem.id)}
                >
                  <BrandTitle
                    brand={listitem}
                    customSegment={customSegment}
                    noLogo={listKey === "segment"}
                    noData={noData}
                    listClass={listClass}
                    ssize={samplesize}
                    dailyAverageSampleSize={dailyAverageSampleSize}
                    statTestingEnabled={statTestingEnabled}
                    charKeyMap={charKeyMap}
                    page={page}
                    regionState={regionState}
                    isFiltersExpiredInSegment={
                      listitem?.isFiltersExpiredInSegment
                    }
                    isAllFiltersPresentInMarket={listitem?.isActive}
                    customSegmentpage={customSegmentpage}
                    selectedTab={selectedTab}
                    brandHidden={
                      item?.id !== -1 && hiddenList.indexOf(listitem.id) > -1
                    }
                  />
                  <div
                    className={cx("showOrHideIconContainer", {
                      rightRem: statTestingEnabled,
                    })}
                    style={{ top: "24px" }}
                  >
                    <img src={hideIcon} alt="" className="showOrHideIcon" />
                  </div>
                </div>
                {listKey === "self" &&
                  defaultList.indexOf(index) < 1 &&
                  listitem.id !== -1 && (
                    <div className="operateContainer">
                      {!listitem?.isFiltersExpiredInSegment &&
                        listitem?.isAllFiltersPresentInMarket === true && (
                          <div
                            onClick={() =>
                              this.props.itemOprateClick("edit", listitem.id)
                            }
                          >
                            Edit
                          </div>
                        )}
                      <div
                        onClick={() =>
                          this.props.itemOprateClick("del", listitem.id)
                        }
                      >
                        Delete
                      </div>
                    </div>
                  )}
              </div>
            </>
          );
        })}
      </div>
    );
  }
}