import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import HeaderCard from "../ViewCard/HeaderCard";
import {
  getFormattedAgeRangeFilterLabel,
  getUpdatedViewDetails,
  getModuleDisplayName,
  getScreenTypeDetail,
  getSelectViewURL,
} from "../ViewCard/utils";
import {
  LIGHTEST_BORDER_GRAY_COLOR,
  SECONDARY_BORDER_GRAY_COLOR,
} from "../ViewCard/utils/constant";
import EntityListImageView from "../ViewCard/EntityListImageView";
import { CircleFlag } from "react-circle-flags";
import AudienceTypeIcon from "../../../assets/icons/icon_views_audience_type.svg";
import StatTestingIcon from "../../../assets/icons/icon_views_stat_testing.svg";
import PeriodTypeIcon from "../../../assets/icons/icon_views_periodtype.svg";
import MarketsIcon from "../../../assets/icons/icon_views_markets.svg";
import CloseIcon from "../../../assets/icons/icon_views_close.svg";
import BrandsIcon from "../../../assets/icons/icon_views_brands.svg";
import calendar from "../../../assets/icons/calendar.svg";
import FilterContainerIcon from "../../../assets/icons/icon_views_filter_parent_container.svg";
import FilterIcon from "../../../assets/icons/icon_views_filter.svg";
import FilterSegmentTypeIcon from "../../../assets/icons/icon_views_filter_segment.svg";
import FilterExpiredIcon from "../../../assets/icons/icon_views_filter_expired.svg";
import spiritDrinkersMarketLogo from "../../../assets/icons/spirit_drinkers.png";
import { DISPLAY_DATE_FORMAT } from "../../widgets/OptionFilters/CustomDateRangeSelector/utils";
import ApiData from "../../../utils/Api";
import Skeleton from "../Skeleton";
import { DateTime } from "luxon";
import initData from "../../../utils/initData";
import {
  getAgeSegmentsNameBasedOnMarket,
  getCountryCode,
} from "../../../utils/common";

const StyledViewDetailModalContent = styled.div`
  .view-detail-modal-header {
    .MuiCheckbox-root {
      visibility: hidden;
      position: absolute;
    }
    padding: 24px;
    border-bottom: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
  }
  .view-detail-card-content {
    & > div {
      padding: 18px 24px;
      border-bottom: 1px solid ${LIGHTEST_BORDER_GRAY_COLOR};
    }
    .MuiCheckbox-root {
      visibility: hidden;
      position: absolute;
    }
  }
  .view-detail-brand-market-list {
    margin-top: 8px;
  }
  .view-detail-card-content {
    height: calc(100vh - 182px);
    overflow: auto;
    & > div {
      width: auto;
    }
  }
  .header-card-container {
    gap: 18px;
  }
  .view-detail-card-footer {
    height: 80px;
    padding: 0px 24px;
    display: flex;
    gap: 24px;
    align-items: center;
    border-top: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
    button {
      width: 50%;
      font-family: "Inter", sans-serif;
      padding: 11px;
      font-weight: 500;
      font-size: 13px;
      border-radius: 3px;
      cursor: pointer;
      letter-spacing: 0.3px;
      &:active {
        opacity: 0.9;
      }
      &:focus-visible {
        outline: none;
      }
    }
    .view-detail-delete-button {
      border: 1px solid #d42620;
      background: white;
      color: #d42620;
      &:active {
        opacity: 0.8;
      }
    }
    .view-detail-select-button {
      background: #4aa578;
      color: white;
      border: 1px solid #4aa578;
      &:active {
        opacity: 1;
        background: #4aa578e8;
      }
      .select-view-angle-down {
        position: relative;
        top: 3.5px;
        left: 2px;
      }
    }
  }
  .views-edit-icon {
    cursor: pointer;
  }
  .views-detail-close-button {
    cursor: pointer;
  }
`;

const StyledViewDetailFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  .filter-segment-container,
  .filter-segment-category-container {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .filter-segment-category-container {
    gap: 8px;
  }
  .header-card-title {
    font-size: 14px;
    font-weight: 500;
  }
  .header-card-subtitle {
    font-size: 12px;
    font-weight: 400;
  }
  img {
    height: 16px !important;
    width: 16px !important;
  }
  .filter-segment-dot-in-views {
    height: 4px;
    width: 4px;
    display: inline-block;
    background: #475467;
    border-radius: 50%;
    position: relative;
  }

  .header-card-container {
    padding: 12px 0px;
    border-bottom: 1px solid ${LIGHTEST_BORDER_GRAY_COLOR};
  }
  & > div:last-child .header-card-container {
    border: none;
  }
  .views-filter-heading {
    display: flex;
    gap: 10px;
    align-items: center;
    .views-filter-expired-label {
      display: flex;
      color: #b42318;
      font-size: 12px;
      border-radius: 3px;
      padding: 1px 4px;
      border: 1px solid #fecdca;
      background-color: #fef3f2;
      gap: 5px;
      align-items: center;
    }
  }
`;
function ViewDetailModalContent({
  view,
  onClose = () => {},
  openViewDeleteModal = () => {},
}) {
  const screenTypeDetail = getScreenTypeDetail(view?.screenType);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});
  const periodTypes = initData.getPeriodOptions();
  view = getUpdatedViewDetails(view);

  useEffect(() => {
    setIsLoading(true);
    try {
      ApiData.getViewDetail(view.viewId).then((res) => {
        setViewDetailData(res);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, [view]);

  const filterSegmentCategoryAndStatusMapping = useMemo(() => {
    const updatedMapping = {
      filters: {},
      segments: {},
    };
    const _ = viewDetailData?.filters?.forEach((filter) => {
      updatedMapping.filters[filter.filterId] = {
        id: filter.filterId,
        categoryName: filter.categoryName,
        categoryId: filter.categoryId,
        isExpired: filter.isFilterExpired,
      };
    });
    const __ = viewDetailData?.segments?.forEach((segment) => {
      updatedMapping.segments[segment.segmentId] = {
        id: segment.segmentId,
        categoryName: segment.categoryName,
        categoryId: segment.categoryId,
        isExpired: segment.isFilterExpired,
      };
    });
    return updatedMapping;
  }, [viewDetailData]);

  function handleSelectView() {
    const url = getSelectViewURL(view.module, view.screenType, view.viewUrl);
    window.open(url, "_blank");
  }

  let statTestingOption = initData.getStatTestingOptions()?.find(({id})=> id === viewDetailData.statTesting)?.title?.replaceAll('-',' ')
  return (
    <StyledViewDetailModalContent>
      <div className="view-detail-modal-header">
        <HeaderCard
          title={view.viewName}
          subtitle={`${getModuleDisplayName(view.module)}/${
            screenTypeDetail?.displayName
          }`}
          enableTitleTooltip
          icon={screenTypeDetail.icon}
          variant="primaryLarge"
        >
          <img
            className="views-detail-close-button"
            src={CloseIcon}
            onClick={onClose}
            alt="Modal close"
          />
        </HeaderCard>
      </div>
      <div className="view-detail-card-content">
        <HeaderCard
          title="Audience Type"
          subtitle={
            isLoading ? (
              <Skeleton height={"12px"} width={"180px"} />
            ) : (
              viewDetailData.audienceType
            )
          }
          icon={AudienceTypeIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title="Stat Testing"
          subtitle={
            isLoading ? (
              <Skeleton height="12px" width="180px" />
            ) : statTestingOption ? (
                statTestingOption 
            ) : (
              "None"
            )
          }
          icon={StatTestingIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title={`Period Type ${view?.screenType === "PREPOST" ? "(Pre)" : ""}`}
          subtitle={
            <div>
              {view?.periodType === "DYNAMIC_DATE_RANGE"
                ? "Custom Range"
                : periodTypes?.find(
                    ({ name }) =>
                      view?.periodType?.replace(/_/g, " ")?.toLowerCase() ===
                      name?.toLowerCase()
                  )?.name}
            </div>
          }
          icon={PeriodTypeIcon}
          variant="primaryInvertedWithoutIconOutline"
        ></HeaderCard>
        <HeaderCard
          title={`Date Period ${view?.screenType === "PREPOST" ? "(Pre)" : ""}`}
          subtitle={
            <div>
              {`${DateTime.fromFormat(view?.startDate, "yyyy-MM-dd").toFormat(
                DISPLAY_DATE_FORMAT
              )} - ${DateTime.fromFormat(view?.endDate, "yyyy-MM-dd").toFormat(
                DISPLAY_DATE_FORMAT
              )}`}
            </div>
          }
          icon={calendar}
          variant="primaryInvertedWithoutIconOutline"
        ></HeaderCard>
        {view?.screenType === "PREPOST" && (
          <>
            <HeaderCard
              title="Period Type (Post)"
              subtitle={
                <div>
                  {view?.secondaryPeriodType === "DYNAMIC_DATE_RANGE"
                    ? "Custom Range"
                    : periodTypes?.find(
                        ({ name }) =>
                          view.secondaryPeriodType
                            ?.replace(/_/g, " ")
                            ?.toLowerCase() === name?.toLowerCase()
                      )?.name}
                </div>
              }
              icon={PeriodTypeIcon}
              variant="primaryInvertedWithoutIconOutline"
            ></HeaderCard>
            <HeaderCard
              title="Date Period (Post)"
              subtitle={
                <div>
                  {`${DateTime.fromFormat(
                    view?.secondaryStartDate,
                    "yyyy-MM-dd"
                  ).toFormat(DISPLAY_DATE_FORMAT)} - ${DateTime.fromFormat(
                    view?.secondaryEndDate,
                    "yyyy-MM-dd"
                  ).toFormat(DISPLAY_DATE_FORMAT)}`}
                </div>
              }
              icon={calendar}
              variant="primaryInvertedWithoutIconOutline"
            ></HeaderCard>
          </>
        )}
        <HeaderCard
          title="Market(s)"
          subtitle={
            <div className="view-detail-brand-market-list">
              <EntityListImageView
                list={view.markets?.map((market) => {
                  let countryCode = getCountryCode(
                    market?.displayName,
                    market?.code
                  );
                  return {
                    id: market.id,
                    image:
                      countryCode !== "spiritDrinkers" ? (
                        <CircleFlag countryCode={countryCode} height={32} />
                      ) : (
                        <img src={spiritDrinkersMarketLogo} alt="" />
                      ),
                    name: market.displayName,
                  };
                })}
                keyToUseForTooltip={"name"}
              />
            </div>
          }
          icon={MarketsIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title="Brand(s)"
          subtitle={
            <div className="view-detail-brand-market-list">
              <EntityListImageView
                list={view.brands?.map((brand) => ({
                  id: brand.id,
                  image: brand.logo,
                  name: brand.name,
                }))}
                keyToUseForTooltip={"name"}
              />
            </div>
          }
          icon={BrandsIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        {(view?.filters?.length > 0 || view?.segments?.length > 0) && (
          <HeaderCard
            title="Filter(s)"
            subtitle={
              <StyledViewDetailFilterSection>
                {view?.filters?.map((filter) => (
                  <HeaderCard
                    key={filter?.filterId}
                    title={
                      <div className="views-filter-heading">
                        {filter?.filterId === 8
                          ? getFormattedAgeRangeFilterLabel(filter)
                          : filter?.filterOptions
                              ?.map(
                                (filterOption) => filterOption?.filterOptionName
                              )
                              ?.join(", ")}
                        {filterSegmentCategoryAndStatusMapping?.filters[
                          filter?.filterId
                        ]?.isExpired && (
                          <span className="views-filter-expired-label">
                            <img src={FilterExpiredIcon} alt="Filter Expired" />
                            Filter Expired
                          </span>
                        )}
                      </div>
                    }
                    subtitle={
                      <>
                        <span className="filter-segment-container">
                          {filter?.filterName}
                          <span className="filter-segment-category-container">
                            <span className="filter-segment-dot-in-views" />
                            {isLoading ? (
                              <Skeleton height={"10px"} width={"80px"} />
                            ) : (
                              filterSegmentCategoryAndStatusMapping?.filters[
                                filter?.filterId
                              ]?.categoryName
                            )}
                          </span>
                          <span className="filter-segment-category-container">
                            <span className="filter-segment-dot-in-views" />
                            Filter
                          </span>
                        </span>
                      </>
                    }
                    icon={FilterIcon}
                    variant="primary"
                  />
                ))}
                {view?.segments?.map((segment) => (
                  <HeaderCard
                    key={segment?.segmentId}
                    title={
                      segment?.segmentName === "Age 18+"
                        ? getAgeSegmentsNameBasedOnMarket(view?.markets)
                        : segment?.segmentName
                    }
                    subtitle={
                      <span className="filter-segment-container">
                        <span className="filter-segment-category-container">
                          <span className="filter-segment-dot-in-views" />
                          {isLoading ? (
                            <Skeleton height={"10px"} width={"80px"} />
                          ) : segment?.segmentName === "Age 18+" ? (
                            getAgeSegmentsNameBasedOnMarket(view?.markets)
                          ) : (
                            filterSegmentCategoryAndStatusMapping?.segments[
                              segment?.segmentId
                            ]?.categoryName
                          )}
                        </span>
                        <span className="filter-segment-category-container">
                          <span className="filter-segment-dot-in-views" />
                          Segment
                        </span>
                      </span>
                    }
                    icon={FilterSegmentTypeIcon}
                    variant="primary"
                  />
                ))}
              </StyledViewDetailFilterSection>
            }
            icon={FilterContainerIcon}
            variant="primaryInvertedWithoutIconOutline"
          />
        )}
      </div>
      <div className="view-detail-card-footer">
        <button
          className="view-detail-delete-button"
          onClick={() => {
            openViewDeleteModal(view);
          }}
        >
          Delete view
        </button>
        <button
          className="view-detail-select-button"
          onClick={handleSelectView}
        >
          Select view{" "}
          {/* <FaAngleDown size={14} className="select-view-angle-down" /> */}
        </button>
      </div>
    </StyledViewDetailModalContent>
  );
}

export default ViewDetailModalContent;
