import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { addMessage,addSelectedValue, removeSelectedValue } from "../../../../reducers/actions/actions";

import axios from "axios";

const parseText = (text) => {
  const regex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
  let parts = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }
    parts.push({ text: match[1], link: match[2] });
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const generateUniqueId = () => {
  return `${Date.now().toString(36)}-${Math.random().toString(36).substring(2, 11)}`;
};

const parseOptionsMessage = (inputString) => {
  const regex = /"([^"]*?):\s*\[(.*?)\]"/g;
  const extractedData = {};
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    const key = match[1].trim(); // Extract the key
    const value = match[2]
      .split(",")
      .map((option) => option.trim().replace(/['"]/g, "")); // Remove quotes and clean the data
    extractedData[key] = value;
  }

  return extractedData;
};

const parseSingleOptionMessage = (inputString) => {
  const regex = /(.*?)\*\s*\/([^,]+(?:,\s*[^,]+)*)\s*\*\//g;
  const extractedData = {};
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    const key = match[1].trim(); 
    const options = match[2]
      .split(",")
      .map((option) => option.trim()); 
    extractedData[key] = options;
  }

  return extractedData;
};

const Message = ({ type = "You", text = "", previousType = "", scrollToBottom }) => {
  const messageRef = useRef(null);
  const dispatch = useDispatch(); 

  useEffect(() => {
    const messageElement = messageRef.current;
    if (messageElement) {
      setTimeout(() => {
        messageElement.classList.add("show");
      }, 100);
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    scrollToBottom();
    if (checked) {
      dispatch(addSelectedValue(value)); 
    } else {
      dispatch(removeSelectedValue(value));
    }
  };
  const handleSingleSelectButtonClick = async(event) => {
    const { value, checked } = event.target;
    scrollToBottom();
    dispatch(
      addMessage({
        type: "You",
        text: value,
      })
    );
    try {
      const response = await axios.post(
        process.env.REACT_APP_ASSISTANT_URL + "/usermessage/",
        {
          userInputMessage: value,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      dispatch(
        addMessage({
          type: "Harris",
          text: response.data.assistant_response,
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(
        addMessage({
          type: "Harris",
          text: "Due to some technical issue, the server is down. Please try again later.",
        })
      );
    }
  };

  const renderTypeOneMessage = (parsedText) => {
    return parsedText
      .filter((part) => (typeof part === "string" ? part.trim() !== "" : true))
      .map((part, index) => (
        <div key={index} className={type}>
          {typeof part === "string" ? (
            <span className="typing-effect">{part}</span>
          ) : (
            <a href={part.link} target="_blank" rel="noopener noreferrer">
              {part.text}
            </a>
          )}
        </div>
      ));
  };

  const renderTypeTwoMessage = (parsedData) => {
    return Object.entries(parsedData).map(([key, options], index) => (
      <React.Fragment key={index}>
        <div className={type}>
          <span className="typing-effect">{key}</span>
        </div>
        <div className="cont">
          <ul className="ks-cboxtags">
            {options.map((option) => {
              const uniqueId = generateUniqueId(); 
              return (
                <li key={uniqueId}>
                  <input
                    type="checkbox"
                    id={`checkbox-${uniqueId}`}
                    value={option}
                    onChange={handleCheckboxChange} 
                  />
                  <label htmlFor={`checkbox-${uniqueId}`}>{option}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    ));
  };
  
  const renderTypeThreeMessage = (parsedData) => {
    return Object.entries(parsedData).map(([key, options], index) => (
      <React.Fragment key={index}>
        <div className={type}>
          <span className="typing-effect">{key}</span>
        </div>
        <div className="options-single-click-button">
          <ul className="ks-cboxtags">
            {options.map((option) => {
              const uniqueId = generateUniqueId(); 
              return (
                <li key={uniqueId}>
                  <input
                    type="checkbox"
                    id={`checkbox-${uniqueId}`}
                    value={option}
                    onChange={handleSingleSelectButtonClick}
                  />
                  <label htmlFor={`checkbox-${uniqueId}`}>{option}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    ));
  };
  const isTypeTwoMessage = text.startsWith("[[") && text.endsWith("]]");
  const isTypeThreeMessage = text.includes("*/") && text.includes(",");

  return (
    <li
      ref={messageRef}
      className={`msg-text ${type === "You" ? "align-right" : "align-left"}`}
    >
      {type !== previousType && <p>{type}</p>}

      {isTypeThreeMessage
        ? renderTypeThreeMessage(parseSingleOptionMessage(text))
        : isTypeTwoMessage
        ? renderTypeTwoMessage(parseOptionsMessage(text))
        : renderTypeOneMessage(parseText(text))}
    </li>
  );
};

export default Message;
