import React, { useImperativeHandle, forwardRef } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeIcon from "../../../assets/icons/icon_views_close.svg";
import xlsxIcon from "../../../assets/icons/xlsx.svg";
import pptIcon from "../../../assets/icons/ppt.svg";
import "./Notification.scss"; 
 
const Notification = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showToast: (message) => {
      console.log(message, "message");
 
      const customContent = (
        <div>
          <span className="descriptionHeader">{message?.description}</span>
          <br />
          <span className="fileNameHeader">{message?.fileName}</span>
        </div>
      );
 
      toast(
        <div>
          <span
            className="toasterHeader"
            style={{
              color: message?.type === "error" ? "#D92D20" : "#101828",
            }}
          >
            {message?.message}
          </span>
          {message?.description && customContent}
        </div>,
        {
          position: "bottom-left",
          transition: Slide,
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          toastId: Math.random(),
          closeButton: ({ closeToast }) => (
            <img
              src={closeIcon}
              alt="close"
              onClick={closeToast}
              style={{
                cursor: "pointer",
                backgroundColor: "#fff",
                margin:
                  message?.type === "success"
                    ? "11px 8px 0px 0px"
                    : "8px 8px 0px 0px",
              }}
              height={12}
              width={12}
            />
          ),
          icon: message?.fileType && (
            <img
              src={message?.fileType?.includes("xlsx") ? xlsxIcon : pptIcon}
              alt="icon"
              height={20}
              width={20}
              className="exportIcon"
            />
          ),
          style:
            message.type === "success"
              ? {
                  border: "1px solid #D0D5DD",
                  color: "#101828",
                }
              : {
                  border: "1px solid #EC221F",
                  color: "#D92D20",
                },
        }
      );
    },
  }));
 
  return (
    <ToastContainer 
      stacked={true}
      position="bottom-left"
      transition={Slide}
      newestOnTop={false}
    />
  );
});
 
export default Notification;

 
