import React, { useState, useEffect, useRef } from "react";
import { ArrowForwardIos } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  userTyping,
  assistantResponding,
  addSelectedValue,
  removeSelectedValue,
  removeAllSelectedValues,
} from "../../../../reducers/actions/actions";

import axios from "axios";

const MessageInput = () => {
  const [messageInput, setMessageInput] = useState("");
  const dispatch = useDispatch();
  const typingTimeoutRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const selectedValues = useSelector((state) => state.chat.selectedValues);

  // Debounce typing status
  useEffect(() => {
    if (messageInput.length > 0 || isInputFocused) {
      dispatch(userTyping(true));
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        dispatch(userTyping(false));
      }, 3000); // 3 seconds debounce
    } else {
      dispatch(userTyping(false));
    }
    return () => clearTimeout(typingTimeoutRef.current);
  }, [messageInput, isInputFocused, dispatch]);

  useEffect(() => {
    const textarea = inputRef.current;
    if (!textarea) return;

    const resizeObserver = new ResizeObserver(() => {
      adjustTextareaHeight();
    });

    resizeObserver.observe(textarea);
    return () => resizeObserver.disconnect();
  }, []);

  const resetTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "40px";
      inputRef.current.style.overflowY = "hidden";
    }
  };

  const submitMessageButtonClickHandler = async () => {
    if (!messageInput.trim() && selectedValues.length === 0) return;

    const selectedValuesString = selectedValues.join(", ");
    const combinedMessage =
      selectedValuesString.length > 0
        ? `${messageInput} [${selectedValuesString}]`
        : messageInput;

    dispatch(addMessage({ type: "You", text: combinedMessage }));
    dispatch(removeAllSelectedValues());
    setMessageInput("");
    resetTextareaHeight();
    inputRef.current.blur();
    dispatch(assistantResponding(true));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ASSISTANT_URL}/usermessage/`,
        { userInputMessage: combinedMessage },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      dispatch(
        addMessage({
          type: "Harris",
          text:
            response.data.assistant_response ||
            "I didn't get that. Could you try again?",
        })
      );
    } catch (error) {
      console.error("API Error:", error);
      let errorMessage =
        "Sorry, I'm having trouble responding. Please try again later.";
      const errorData = error.response?.data || error.data || error;

      if (errorData.code) {
        errorMessage = `[${errorData.code}] ${
          errorData.title || errorData.message || "An error occurred"
        }`;
      } else if (error.response?.status) {
        errorMessage = `Error ${error.response.status}: ${
          error.response.statusText || "Request failed"
        }`;
      } else if (error.message === "Network Error") {
        errorMessage = "Network error: Please check your connection";
      }

      dispatch(addMessage({ type: "Harris", text: errorMessage }));
    } finally {
      dispatch(assistantResponding(false));
      dispatch(userTyping(false));
      dispatch(removeAllSelectedValues());
      inputRef.current.focus();
    }
  };

  const messageChangeHandler = (e) => {
    setMessageInput(e.target.value);
    adjustTextareaHeight();
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && messageInput.trim() !== "") {
      submitMessageButtonClickHandler();
    }
  };

  const toggleCheckbox = (value) => {
    if (selectedValues.includes(value)) {
      dispatch(removeSelectedValue(value));
    } else {
      dispatch(addSelectedValue(value));
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = inputRef.current;
    if (!textarea) return;
    textarea.style.height = "auto";
    const maxHeight = 80;
    const newHeight = Math.min(textarea.scrollHeight, maxHeight);
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY =
      textarea.scrollHeight > maxHeight ? "auto" : "hidden";
  };

  return (
    <form className="card-actions" onSubmit={(e) => e.preventDefault()}>
      <div className="card-footer">
        <textarea
          className="textarea"
          placeholder="Message"
          onKeyPress={handleKeyPress}
          ref={inputRef}
          onChange={messageChangeHandler}
          value={messageInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows={1}
        />
        <button
          className="submit-btn"
          type="button"
          onClick={submitMessageButtonClickHandler}
        >
          <ArrowForwardIos />
        </button>
      </div>
      <div
        className={`multi-select-checkbox-container ${
          selectedValues.length > 0 ? "visible" : "hidden"
        }`}
      >
        <ul className="ks-cboxtags">
          {selectedValues.map((value, index) => (
            <li key={index}>
              <input type="checkbox" id={`check${index}`} value={value} />
              <label htmlFor={`check${index}`}>{value}</label>
            </li>
          ))}
        </ul>
      </div>
    </form>
  );
};

export default MessageInput;
