import React, { memo, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import useFileDownload from "./hooks/useFileDownloadHook";
import DownloadIconSVG from "../../assets/icons/download.svg";
import CircleProgressBar from "../widgets/Shared/CircleProgressBar";
import DownloadFailed from "../../assets/icons/icon_close_red.svg";
import { FILE_STATUS } from "./utils/constant";
import CustomTooltip from "../CustomTooltip";
import useViewsReportsExportsStatus from "../../hooks/useViewsReportsExportsStatus";
import DownloadIcon from "../widgets/Shared/ExportButton/DownloadIcon";
import {
  StyledFileDownload,
  StyledFileDownloadPopup,
} from "./Styled.FileDownload";

function FileDownload({ open, toggleExportModal }) {
  const {
    downloadFileList,
    progressUpdate,
    overallProgressStatus,
    triggerFileDownload,
  } = useFileDownload();
  // const fileListMaxLength = 5;
  let { ids, values } = downloadFileList;
  const actionsModalref = useRef(null);
  // if( ids?.length > 5) ids = ids.slice(ids?.length - fileListMaxLength);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    const exportButton = document.querySelector("#file-download-header");
    const excelButton = document.querySelector("#ppt-button");
    const pptButton = document.querySelector("#excel-button");

    if (
      (exportButton && exportButton.contains(event.target)) ||
      (excelButton && excelButton.contains(event.target)) ||
      (pptButton && pptButton.contains(event.target))
    )
      return;
    else if (
      actionsModalref &&
      !actionsModalref?.current?.contains(event.target)
    ) {
      toggleExportModal(false);
    }
  };

  const { isReportAndViewsExportAvailable } = useViewsReportsExportsStatus();
  if (isReportAndViewsExportAvailable) {
    return (
      <StyledFileDownload>
        <div
          className="file-download-header"
          id="file-download-header"
          onClick={() => toggleExportModal(!open)}
        >
          {overallProgressStatus < 100 && overallProgressStatus ? (
            <div className="file-progress-loader-parent">
              <span className="file-progress-loader-parent-download-icon">
                <DownloadIcon iconColor="#DFE2EA" />
              </span>
              {true && (
                <CircleProgressBar
                  progress={overallProgressStatus}
                  strokeWidth={6}
                  pathColor="#1BA379"
                  trailColor="#DFE2EA"
                />
              )}
            </div>
          ) : (
            <img
              src={DownloadIconSVG}
              className="file-download-trigger"
              height={22}
            />
          )}
        </div>
        {open && (
          <StyledFileDownloadPopup ref={actionsModalref}>
            <div className="download-file-heading">FILES</div>
            <ul>
              {ids.length > 0 &&
                ids.map((jobId) => {
                  const value = values[jobId];
                  return (
                    <li key={jobId}>
                      <div>
                        <div> {value.fileName}</div>
                        <div className="file-download-date">
                          {DateTime.fromJSDate(value?.date).toFormat(
                            "MMM dd, yyyy"
                          )}
                        </div>
                      </div>
                      <div className="file-progress-status">
                        {value.status === FILE_STATUS.PENDING && (
                          <div className="file-progress-loader">
                            <CircleProgressBar
                              progress={progressUpdate[jobId]}
                              strokeWidth={6}
                              pathColor="#1BA379"
                              trailColor="#DFE2EA"
                            />
                          </div>
                        )}
                        {value.status === FILE_STATUS.FAILED && (
                          <CustomTooltip
                            contentList={[value.errorMessage]}
                            classname="file-download-error"
                            placement="bottom-end"
                          >
                            <img
                              src={DownloadFailed}
                              alt="Download Failed"
                              height={22}
                            />
                          </CustomTooltip>
                        )}
                        {value.status === FILE_STATUS.SUCCESS && (
                          <div
                            className="file-download-link"
                            onClick={() => {
                              triggerFileDownload(value.url, value.fileName);
                            }}
                          >
                            Download
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              {ids.length === 0 && (
                <li className="no-file-to-download">No file to download</li>
              )}
            </ul>
          </StyledFileDownloadPopup>
        )}
      </StyledFileDownload>
    );
  }

  return null;
}

export default memo(FileDownload);
