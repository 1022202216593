import styled from "styled-components";
import { SECONDARY_BORDER_GRAY_COLOR } from "../utils/constant";

const StyledViewsActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  img {
    cursor: pointer;
  }
  .view-buttons{
    padding: 6px;
    height: 25px;
  }
  .views-button{
    padding: 6px;
  }
  li {
    margin: 0px 5px;
    border-radius: 5px;
  }
  #view-delete-action {
    color: #bf6a02;
    margin: 0px;
    padding: 0px;
    padding-top: 4px;
    margin-top: 4px;
    border-radius: 0px;
    &:hover {
      background-color: white;
    }
    border-top: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
    .view-delete-action-button {
      margin: 0px 5px;
      width: 100%;
      padding: 10px 10px;
      display: flex;
      gap: 12px;
      align-items: center;
      border-radius: 5px;
      &:hover {
        background-color: #f9fafb;
      }
    }
  }
  li:hover {
    &:hover {
      input {
        visibility: visible;
      }
    }
  }

  li input {
    visibility: hidden;
  }

  li input:checked {
    visibility: visible;
  }

  input[type="checkbox"]:not(:checked) {
    border: 1.5px solid #d0d5dd !important;
  }
  input[type="checkbox"] {
    height: 13px;
  }
  input[type="checkbox"]:checked {
    accent-color: #7556d9;
    border: 2px solid #7556d9;
  }
  .view-export-download-main-container {
    width: auto;
    ul {
      li {
        padding-right: 16px;
      }
    }
  }
`;

export { StyledViewsActions };