import iconConfig from "./iconConfig";

export const config = {
    segments: {
        MAX_ALLOWED_SEGMENTS: Number(process.env.REACT_APP_MAX_ALLOWED_SEGMENTS) || 10,
    },
    CDN: {
        BASE_URL: process.env.REACT_APP_CDN_BASE_URL || "https://assets.theharrispoll.com",
        ICONS: iconConfig,
    },
};
