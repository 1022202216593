import { config } from "./config";

/**
 * @typedef {Object} SegmentsConfig
 * @property {number} MAX_ALLOWED_SEGMENTS
 */

/**
 * @typedef {Object} ConfigType
 * @property {SegmentsConfig} segments
 */

/** @type {ConfigType} */
const typedConfig = config;

/**
 * Fetches a config value safely with auto-completion.
 * Wish this could be done with TypeScript, but it's not worth the effort.
 *
 * @param {keyof ConfigType} group - The config category (e.g., "segments").
 * @param {string} key - The specific config key inside the category.
 * @returns {*} The config value.
 */
export function getConfig(group, key) {
    if (!typedConfig[group] || typedConfig[group][key] === undefined) {
        console.warn(`[CONFIG-WARNING] Missing config value for ${group}.${key}, using fallback.`);
    }
    return typedConfig[group][key];
}

/**
 * Fetches the full path of an icon based on its name from the configuration.
 *
 * @param {string} name - The name of the icon to retrieve the path for.
 * @return {string} The full URL of the icon if found, or the fallback icon URL if the given name is not found.
 */
export function getIconPath(name) {
    const base = getConfig("CDN", "BASE_URL");
    const icons = getConfig("CDN", "ICONS");
    return icons[name] ? `${base}${icons[name]}` : `${base}${icons["FALLBACK"]}`;
}
