import React, { Component } from "react";
import searchIcon from "../../../../assets/icons/icon_search.svg";
import searchIconBlue from "../../../../assets/icons/icon_search_blue.svg";
import BrandItemList from "./GeoItemList";
import GeoItemCard from "./GeoItemCard";
import ApiData from "../../../../utils/Api";
import "./AddGeoComponent.scss";

export default class AddBrandComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: undefined,
      //mygeos: [],
      mygeos: [],
      searchBrand: "",
      cost: "0.00",
      showCreateScreen: false,
      saveBrands: false,
      saveSuccess: 0,
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.searchBrandChanged = this.searchBrandChanged.bind(this);
    this.onSelectGeo = this.onSelectGeo.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.removeMyBrandItem = this.removeMyBrandItem.bind(this);
    this.onCreateFinish = this.onCreateFinish.bind(this);
  }

  renderMyGeoItems() {
    const { mygeos } = this.state;
    let cells = [];
    mygeos.forEach((item, i) => {
      cells.push(
        <div key={item.region} className="mybrandCardContainer">
          <GeoItemCard
            index={i}
            id={item.id}
            region={item.region}
            name={item.name}
            onRemove={this.removeMyBrandItem}
          />
        </div>
      );
    });

    return <div>{cells}</div>;
  }

  onClickCreate() {
    this.setState({
      showCreateScreen: true,
      suggestions: undefined,
    });
  }

  onInputChanged(name) {
    const { selectedGeoList } = this.props;

    let currentGeoIds =  this.props.currentGeos? this.props.currentGeos.map((geo)=>geo.id) : []
    const selectedGeosIds = selectedGeoList ? selectedGeoList.map(geo => geo.id) : [];

    ApiData.searchCampaignByNameOrRegion(encodeURI(name))
      .then((result) => {
        this.setState({
          suggestions: result.filter(function(item) {
            return currentGeoIds.indexOf(item.id) === -1 && selectedGeosIds.indexOf(item.id) === -1
          }),
        });
      })
      .catch((err) => {
        console.log("error=" + err);
      });
  }

  /**
   * triggerred by user selected from the suggestion list
   * @param {*} id
   */
  onSelectGeo(id) {
    const { suggestions, mygeos } = this.state;

    let item = suggestions.find((s) => {
      return s.id === id;
    });
    let selectedGeos = [];
    if (mygeos) {
      var found = mygeos.find(function (element) {
        return (element.name === item.name && element.region === item.region);
      });
      if (found) {
        // already in mygeos, cannot add again
        return;
      }
      // up to 10 brands
      // if (mygeos.length >= 10) {
      //   return;
      // }
      selectedGeos = mygeos;
    }
    selectedGeos.push(item);
    this.setState({
      searchBrand: "",
      suggestions: undefined,
      mygeos: selectedGeos,
    });
    this.props.setGeos(this.state.mygeos);
    let field = document.getElementById("search");
    field.focus();
    field.value = "";
  }

  /**
   * triggerred by user manually input
   * @param {*} brand
   */
  searchBrandChanged(e) {
    const brand = e.target.value;
    this.onInputChanged(brand);
    this.setState({
      searchBrand: brand,
    });
  }

  removeMyBrandItem(i) {
    var { mygeos } = this.state;

    if (mygeos && mygeos.length > i) {
      let temp = mygeos;
      temp.splice(i, 1);
      this.setState({
        mygeos: temp,
      });
    }
  }

  onCreateFinish() {
    this.setState({
      showCreateScreen: false,
    });
  }

  renderMyGeos() {
    const { mygeos } = this.state;

    if (!mygeos || mygeos.length === 0) {
      return;
    }

    return (
      <div className="mybrandsContainer" ref="brands">
        <div className="brands-list">{this.renderMyGeoItems()}</div>
      </div>
    );
  }

  render() {
    const {
      searchBrand,
      suggestions,
      mygeos,
    } = this.state;
    let icon = searchBrand !== "" ? searchIconBlue : searchIcon;
    let available_suggestions = [];

    if (suggestions && suggestions.length > 0) {
      suggestions.forEach((s) => {
        if (mygeos && mygeos.length > 0) {
          if (
            mygeos.findIndex((mb) => {
              return (mb.name === s.name && mb.region === s.region);
            }) < 0
          ) {
            available_suggestions.push(s);
          }
        } else {
          available_suggestions.push(s);
        }
      });
    }

    return (
      <>
        <div className="selectAddBrandContainer">
          <div className="selectBrandContent">
            {/* <div className="divider additionBottom" /> */}
            <div className="selectBrandSelectionContainer">
              <div className="selectBrandInputContainer">
                <div className="searchIconContainer">
                  <img src={icon} className="prefixIcon" alt="" />
                </div>
                <input
                  id="search"
                  placeholder={"Search for markets to add…"}
                  label="Search for brand…"
                  type="text"
                  autoFocus={true}
                  onChange={this.searchBrandChanged}
                  // readOnly={mygeos.length > 9}
                  /* value={this.state.searchBrand} */
                />
              </div>
              {available_suggestions && available_suggestions.length > 0 && (
                <BrandItemList
                  onSelect={this.onSelectGeo}
                  data={available_suggestions}
                />
              )}
            </div>
          </div>
        </div>
        {this.renderMyGeos()}
      </>
    );
  }
}
