import styled from "styled-components";

const StyledFileDownload = styled.div`
  display: flex;
  align-items: center;
  .tippy-box {
    width: auto;
    border-radius: 4px;
  }
  .file-download-trigger {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 7px;
    border-radius: 10px;
    transition: 0.4s all;
    &:hover {
      border-color: #6941c6;
    }
  }
  .file-download-header {
    position: relative;
    display: flex;
    align-items: center;
  }
  .file-download-error {
    ul {
      min-height: auto;
      margin: 0;
      li {
        cursor: default;
      }
      li:hover {
        background-color: inherit;
      }
    }
  }
  .file-progress-loader-parent {
    min-height: 22px;
    min-width: 22px;
    cursor: pointer;
    max-height: 22px;
    max-width: 22px;
    border: 1px solid transparent;
    padding: 5.5px 7px 8.5px 7px;
    border-radius: 8px;
    position: relative;
    transition: 0.4s all;
    .file-progress-loader-parent-download-icon {
      position: absolute;
      top: 7.2px;
      left: 7px;
    }
    &:hover {
      border-color: #6941c6;
    }
  }
`;

const StyledFileDownloadPopup = styled.div`
  width: 320px;
  padding: 12px 0px;
  width: 292px;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
  position: fixed;
  top: 110px;
  right: 100px;
  border-radius: 5px !important;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, .0196078431), 0 3px 2px -2px rgba(0, 0, 0, .0588235294) !important;
  border: 1px solid #e4e7ec;

  .download-file-heading {
    font-size: 14px;
    padding: 4px 0px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #1f68dc;
    letter-spacing: 2px;
  }
  ul {
    display: flex;
    flex-direction: column;
    max-height: 260px;
    min-height: 180px;
    overflow: auto;
    padding: 0px 0px;
    margin: 0px;
    li {
      padding: 4px 0px;
      display: flex;
      cursor: default;
      font-size: 13px;
      text-wrap: wrap;
      line-height: 16px;
      justify-content: space-between;
      align-items: center;
      color: #1d2739;
      border-bottom: 1px solid #f7f9fc;
      .file-download-date {
        color: #71767d;
        font-size: 12px;
        font-weight: 400;
      }
      .file-progress-status{
        cursor: pointer;
      }
      .file-progress-loader {
        min-height: 22px;
        min-width: 22px;
        max-height: 22px;
        max-width: 22px;
      }
      .file-download-link {
        color: #1f68dc;
        text-decoration: underline;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
      }
      &:last-child {
        border: none;
      }
    }
    .no-file-to-download {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
      color: #98a2b3;
    }
  }
`;

export {
    StyledFileDownload,
    StyledFileDownloadPopup,
}
