export const ADD_MESSAGE = "ADD_MESSAGE";
export const USER_TYPING = "USER_TYPING";
export const ASSISTANT_RESPONDING = "ASSISTANT_RESPONDING";
export const ADD_BULK_MESSAGE = "ADD_BULK_MESSAGE";
export const DELETE_ALL_MESSAGES = "DELETE_ALL_MESSAGES";
export const SET_HAS_INITIALIZED_MESSAGES = "SET_HAS_INITIALIZED_MESSAGES";
export const ADD_SELECTED_VALUE = "ADD_SELECTED_VALUE";
export const REMOVE_SELECTED_VALUE = "REMOVE_SELECTED_VALUE";
export const REMOVE_ALL_SELECTED_VALUE = "REMOVE_ALL_SELECTED_VALUE";
export const DELETE_MESSAGES_KEEPING_INITIAL = "DELETE_MESSAGES_KEEPING_INITIAL";
