import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import "./BrandsMarketsDropDownSelect.scss";
import ddIcon from "../../../assets/icons/icon_arrow_down.svg";
import spiritDrinkersMarketLogo from "../../../assets/icons/spirit_drinkers.png";
import BrandTitle from "./BrandTitle";
import { getURLPathParams } from "../../../utils/URLParamsValidations";
import { getCountryCode } from "../../../utils/common";
import { CircleFlag } from "react-circle-flags";
import Tooltip from "./Tooltip";
import { getIconPath } from "../../../config/configHelper";

const BrandsDropDownSelect = (props) => {
    let [validateMessage, setValidateMessage] = useState(null);
    let [showTooltip, setShowTooltip] = useState(false);
    let [selected, setSelected] = useState(props.selected);
    let dropDownModalref = useRef(null);

    useEffect(() => {
        document.addEventListener("click", outsideClickListener);
        return () => {
            document.removeEventListener("click", outsideClickListener);
        };
    });

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected, props.open]);

    useEffect(() => {
        setShowTooltip(false);
        props.open && displayValidateMessage();
    }, [props.open]);

    useEffect(() => {
        if (showTooltip) {
            // Hide the tooltip after 3 seconds
            const timer = setTimeout(() => {
                setShowTooltip(false);
            }, 3000);
        }
    }, [showTooltip]);

    const onHeaderClick = () => {
        props.onOpenSelect();
        props.open && (dropDownModalref = null)
    };

    const outsideClickListener = (event) => {
        if (
            dropDownModalref?.current &&
            !dropDownModalref?.current?.contains(event.target)
        ) {
            props.open && props.onOpenSelect();
        }
    };

    const handleSelect = (index) => {
        if (
            (!multiSelect && index !== selected) ||
            (multiSelect &&
                (selected?.length > multiSelect ||
                    (Array.isArray(selected) && selected?.indexOf(index) < 0)))
        ) {
            setShowTooltip(false);
            const { sIndex } = props;
            let selectedList = Array.isArray(selected)
                ? [...selected]
                : selected;
            if (props.multiSelect) {
                const selectedIndex = selected?.indexOf(index);
                if (selectedIndex !== -1) selectedList.splice(selectedIndex, 1);
                else selectedList = [...selectedList, index];
                setSelected([...selectedList]);
            } else {
                selectedList = index;
                props.onSelectFunction(index, sIndex);
                props.onOpenSelect();
                setSelected(selectedList);
            }
        } else {
            setShowTooltip(true);
        }
    };

    const onSelectAll = (allBrandsSelected) => {
        setShowTooltip(false);
        let { module, screenType } = getURLPathParams();

        if (allBrandsSelected) {
            if (module === "competitive") {
                setSelected(
                    props.data?.filter((_, ind) => ind < 1)?.map((_, i) => i)
                );
                setValidateMessage("You must keep at least one brand.");
                setShowTooltip(true);
            } else if (module === "advanced") {
                if (screenType === "DIFFERENTIATION") {
                    let selectedAdvancedBrandIndex = selected?.find(
                        (ind) => props.hideList[0] == ind
                    );
                    let selectedCompetitiorBrandsIndex = props.data?.map(
                        (_, ind) => ind
                    );
                    selectedCompetitiorBrandsIndex =
                        selectedCompetitiorBrandsIndex?.filter(
                            (ind) => selectedAdvancedBrandIndex != ind
                        );
                    setSelected([
                        selectedAdvancedBrandIndex,
                        ...selectedCompetitiorBrandsIndex.filter(
                            (_, i) => i < 2
                        ),
                    ]);
                    setValidateMessage("You must keep at least two brands.");
                    setShowTooltip(true);
                } else if (screenType === "PERCEPTUAL") {
                    setSelected(
                        props.data
                            ?.filter((_, ind) => ind < 3)
                            ?.map((_, i) => i)
                    );
                    setValidateMessage("You must keep at least three brands.");
                    setShowTooltip(true);
                }
            } else if (module === "global") {
                setSelected(
                    props.data?.filter((_, ind) => ind < 1)?.map((_, i) => i)
                );
                setValidateMessage("You must keep at least one market.");
                setShowTooltip(true);
            }
        } else {
            if (module === "advanced" && screenType === "DIFFERENTIATION") {
                let selectedAdvancedBrandIndex = selected?.find(
                    (ind) => props.hideList[0] == ind
                );
                let selectedCompetitiorBrandsIndex = props.data?.map(
                    (_, ind) => ind
                );
                selectedCompetitiorBrandsIndex =
                    selectedCompetitiorBrandsIndex?.filter(
                        (ind) => selectedAdvancedBrandIndex != ind
                    );

                setSelected([
                    selectedAdvancedBrandIndex,
                    ...selectedCompetitiorBrandsIndex,
                ]);
            } else {
                setSelected(props.data?.map((_, ind) => ind));
            }
            setShowTooltip(false);
        }
        //show validation message when user unselects the select all checkbox
        if (allBrandsSelected) displayValidateMessage();
    };

    const handleApply = () => {
        props.onOpenSelect();
        props.handleSelectBrandsMarkets([...selected]);
    };

    const handleReset = () => {
        setSelected([...props.selected]);
    };

    const displayValidateMessage = () => {
        let { module, screenType } = getURLPathParams();

        if (module === "competitive") {
            setValidateMessage("You must keep at least one brand.");
        } else if (module === "advanced") {
            if (screenType === "DIFFERENTIATION") {
                setValidateMessage("You must keep at least two brands.");
            } else if (screenType === "PERCEPTUAL") {
                setValidateMessage("You must keep at least three brands.");
            }
        }
    };

    const renderMarketLogo = (obj, style) => {
        let countryCode = getCountryCode(obj?.marketName, obj?.countryCode);
        return (
            <>
                {countryCode !== "spiritDrinkers" ? (
                    countryCode ? (
                        <CircleFlag
                            countryCode={countryCode}
                            height="30"
                            className={style}
                        />
                    ) : (
                        <div className="roundedTextLogo">
                            {obj?.marketName?.slice(0, 1)?.toUpperCase()}
                        </div>
                    )
                ) : (
                    <img
                        src={spiritDrinkersMarketLogo}
                        className={style}
                        alt=""
                        height={style ? "" : "30px"}
                    />
                )}
            </>
        );
    };

    const renderMarkets = (obj, style) => {
        return (
            <div className="elementConatiner">
                <div className="elementRoundedLogo">
                    {renderMarketLogo(obj, style)}
                </div>
                {props.multiSelect ? (
                    <div className="elementTitle">{obj?.marketName}</div>
                ) : (
                    <div className="campaignTitle">
                        <div className={"campaignRegion"}>
                            {selected?.region}
                        </div>
                        <div className="campaignName">{obj?.marketName}</div>
                    </div>
                )}
            </div>
        );
    };

    const renderBrands = (obj, index) => {
        const brandName = obj.displayName ? obj.displayName : obj.name;
        return (
            <div className="elementConatiner">
                {obj.logo ? (
                    <div className="elementRoundedLogo">
                        <img
                            src={obj.logo}
                            className="icon"
                            alt={"logo" + index}
                            height={33}
                            width={33}
                        />
                    </div>
                ) : (
                    <div className="icon textIcon elementRoundedLogo">
                        <div>{brandName?.slice(0, 1).toUpperCase()}</div>
                    </div>
                )}
                <div className="elementTitle">
                    {brandName}
                    {brandSsize && brandSsize[obj.id] && (
                        <div className="sSize">
                            n={brandSsize[obj.id].toLocaleString("en-En")}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getSelectedData = (selectedIndexList) => {
        let selectedData = [];
        let { hideList, data } = props;
        selectedIndexList?.length > 0 &&
            selectedIndexList.forEach((s) => {
                if (
                    !hideList ||
                    (Array.isArray(hideList) && hideList.indexOf(s) < 0)
                ) {
                    selectedData.push(data[s]);
                }
            });

        return selectedData;
    };

    const {
        data,
        open,
        hideList,
        multiSelect,
        brandSsize,
        page,
        selectedTab,
        filterOn,
    } = props;
    let selectedData = multiSelect ? [] : data[selected];
    let prevSelectedData = [];

    if (multiSelect) {
        selectedData = getSelectedData(selected);
        //get previous selected list data to show logos/icons for multiselect dropdown
        prevSelectedData = getSelectedData(props.selected);
    }

    let brandORMarketNames =
        prevSelectedData?.length > 4 &&
        prevSelectedData
            .slice(4)
            .map((obj) =>
                obj?.displayName
                    ? obj?.displayName
                    : obj?.name
                    ? obj?.name
                    : obj?.marketName
            );
    return (
        <div className={cx("dropDown", { open: open })}>
            <div className="dropDownHeaderContainer" onClick={onHeaderClick}>
                <img className="ddArrow" src={ddIcon} alt="" />
                {multiSelect ? (
                    <div className="multiSelected">
                        <div className="selectedListConatiner">
                            {prevSelectedData?.map((obj, index) => {
                                let selectedIndex = data.findIndex(
                                    (ele, ind) =>
                                        obj?.marketId === ele.marketId ||
                                        obj.brandId === ele.brandId
                                );

                                const logosStyle = {
                                    zIndex: 1 - index,
                                    left: 30 * index + "px",
                                };

                                return (
                                    index < 4 &&
                                    (obj?.marketName ? (
                                        <div className="marketItem">
                                            <div
                                                className="marketTitle"
                                                style={logosStyle}
                                            >
                                                {renderMarketLogo(
                                                    obj,
                                                    "marketLogo"
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="brandItem"
                                            key={index}
                                            style={logosStyle}
                                        >
                                            <BrandTitle brand={obj} />
                                        </div>
                                    ))
                                );
                            })}
                        </div>
                        {prevSelectedData?.length > 4 && (
                            <div className="selectedNum">
                                + {prevSelectedData?.length - 4}
                                {brandORMarketNames?.length > 0 && (
                                    <span className="tooltiptext">
                                        {brandORMarketNames?.join(", ")}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                ) : selectedData?.id ? (
                    <BrandTitle
                        brand={selectedData}
                        page={page}
                        selectedTab={selectedTab}
                        filterOn={filterOn}
                        dropdown="dropdown"
                    />
                ) : (
                    <>{renderMarkets(selected, "marketLogo")}</>
                )}
            </div>
            <div className="dropDownContainer" ref={dropDownModalref}>
                {multiSelect && (
                    <>
                        <div className="dropDownHeader">
                            <div className="dropDownTitleContainer">
                                <span>
                                    {selectedData?.length}{" "}
                                    {selectedData && selectedData[0]?.id
                                        ? selected?.length > 1
                                            ? "Brand(s)"
                                            : "Brand"
                                        : selected?.length > 1
                                        ? "Market(s)"
                                        : "Market"}{" "}
                                    Selected
                                </span>
                                <div className="iconContainer">
                                    <Tooltip
                                        showTooltip={showTooltip}
                                        elementStyle={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                        tooltipContent={validateMessage}
                                    />
                                </div>
                            </div>

                            <div className="dropDownTitleContainer">
                                <div
                                    className="actionIcon"
                                    onClick={(e) => onHeaderClick(e)}
                                >
                                    <img
                                        src={getIconPath("CLOSE")}
                                        className="svgIcon"
                                        alt="Close Selector"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="dropdownListContainer">
                    {data.map((obj, index) => {
                        if (
                            !hideList ||
                            (Array.isArray(hideList) &&
                                hideList?.indexOf(index) < 0)
                        ) {
                            const logo = obj?.id
                                ? renderBrands(obj)
                                : renderMarkets(obj);
                            return (
                                <>
                                    {obj?.id || multiSelect ? (
                                        <div
                                            key={index}
                                            className={cx("selectItem", {
                                                multi: multiSelect,
                                                selected:
                                                    !multiSelect &&
                                                    index === selected,
                                            })}
                                            onClick={() => handleSelect(index)}
                                        >
                                            {multiSelect &&
                                                selected?.length > 0 && (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={selected?.includes(
                                                                index
                                                            )}
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            {logo}
                                        </div>
                                    ) : (
                                        <div
                                            key={index}
                                            className={
                                                "selectCampaignContainer"
                                            }
                                        >
                                            <div className={"campaignNameBox"}>
                                                <span>{obj.groupName}</span>
                                            </div>
                                            <div
                                                className={"campaignRegionBox"}
                                            >
                                                {obj?.marketGroupsList?.map(
                                                    (subCampaign, ind) => (
                                                        <span
                                                            key={ind}
                                                            className={cx(
                                                                "campaignRegion",
                                                                {
                                                                    selected:
                                                                        selected.id ===
                                                                        subCampaign.id,
                                                                }
                                                            )}
                                                            id={subCampaign.id}
                                                            onClick={() =>
                                                                props.onSelectCampaign(
                                                                    subCampaign
                                                                )
                                                            }
                                                        >
                                                            {subCampaign.region}
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }
                    })}
                </div>
                {multiSelect && (
                    <div className="dropDownFooter">
                        <div
                            className="elementConatiner"
                            onClick={() =>
                                onSelectAll(data?.length === selected?.length)
                            }
                        >
                            <input
                                type="checkbox"
                                checked={data?.length === selected?.length}
                            />
                            <div className="selectText">Select All</div>
                        </div>
                        <div
                            className="buttonContainer"
                            style={{ display: "flex" }}
                        >
                            <button
                                className="resetButton"
                                onClick={handleReset}
                            >
                                Reset
                            </button>

                            <button
                                className="applyButton"
                                onClick={handleApply}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrandsDropDownSelect;
