import React, { useEffect, useState } from "react";
import Chat from "./components/Chat";
import { getLocalStorage } from "../../utils/storageHelpers";
const assistantAllowedEmails = [
 "vignesh.rajan@harrispoll.com",
 "nitish.y@harrispoll.com",
 "pragna.venkata@harrispoll.com",
 "rajitha.mudigonda@harrispoll.com",
 "pradeep.mudham@harrispoll.com"
];
const restrictedPages = ["/admin"];
const ChatBox = () => {
 const [userDetail, setUserDetail] = useState(null);
 const [shouldRenderChat, setShouldRenderChat] = useState(false);
 useEffect(() => {
   if (userDetail) return;
   const checkUserInfo = () => {
     console.log("Checking localStorage for userInfo...");
     const user = JSON.parse(getLocalStorage("userInfo"));
     if (user) {
       console.log("User found:", user);
       setUserDetail(user);
     }
   };
  
   const interval = setInterval(() => {
     checkUserInfo();
   }, 1000);
   return () => clearInterval(interval);
 }, [userDetail]);
 
 useEffect(() => {
   if (!userDetail) return;
   console.log("User details set, checking conditions for rendering Chat...");
   const currentPath = window.location.pathname;
   const isAdminPage = restrictedPages.some((page) =>
     currentPath.startsWith(page)
   );
   console.log("Environment variable:", process.env.REACT_APP_FLAG_AI_ASSISTANT_ENABLED);
   console.log("User email:", userDetail?.email);
   if (
     process.env.REACT_APP_FLAG_AI_ASSISTANT_ENABLED &&
     userDetail?.email &&
     assistantAllowedEmails.includes(userDetail.email) &&
     !isAdminPage
   ) {
     console.log("Rendering Chat...");
     setShouldRenderChat(true);
   } else {
     console.log("Not rendering Chat.");
     setShouldRenderChat(false);
   }
 }, [userDetail]);
 if (!userDetail) {
   console.log("User details not set yet, returning null...");
   return null;
 }
 return <>{shouldRenderChat && <Chat />}</>;
};
export default ChatBox;