import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import cx from "classnames";
import { DateTime } from "luxon";
import HeaderCard from "./HeaderCard";
import EntityListImageView from "./EntityListImageView";
import ViewCardFooter from "./ViewCardFooter/ViewCardFooter";
import {
  getModuleDisplayName,
  getPeriodDisplayName,
  getScreenTypeDetail,
} from "./utils";
import ViewsActions from "./ViewsActions";
import StyledViewCard from "./Styled.ViewCard";
import { CircleFlag } from "react-circle-flags";
import { getCountryCode } from "../../../utils/common";
import spiritDrinkersLogo from "../../../assets/icons/spirit_drinkers.png";

function ViewCard({
  id,
  reportsIdsSelectedOnView,
  view,
  isChecked,
  openViewDetailModal,
  openViewDeleteModal,
  handleDefaultView,
  openCloneViewModal,
  openEditViewModal,
  handleSelectedViews,
  toggleExportModal,
  showToastHandler,
  isDraggingItem,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "320px",
    backgroundColor: "white",
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.5 : 1,
    transformOrigin: "50% 50%",
    cursor: isDraggingItem ? "grabbing" : "grab",
    boxShadow: isDraggingItem 
      ? "0 0 10px #10182808, 0 0 10px #10182814"
      : "0 5px 3px -2px rgba(0,0,0,.0196078431),0 3px 2px -2px rgba(0,0,0,.0588235294)"
  };

  const screenTypeDetail = getScreenTypeDetail(view?.screenType);
  const handleCheckboxChange = () => {
    handleSelectedViews(view?.viewId);
  };

  const renderViewCard = () => {
    return (
      <>
        <div className="view-card-container">
          <HeaderCard
            title={`${getModuleDisplayName(view.module)}/${
              screenTypeDetail?.displayName
            }`}
            subtitle={view.reportName}
            icon={screenTypeDetail?.icon}
            enableSubtitleTooltip
            variant="secondary"
            isChecked={isChecked}
          >
            <ViewsActions
              reportsIdsSelectedOnView={reportsIdsSelectedOnView}
              view={view}
              onClickDetailView={openViewDetailModal}
              onClickDeleteView={openViewDeleteModal}
              handleDefaultView={handleDefaultView}
              onClickCloneView={openCloneViewModal}
              onClickEditView={openEditViewModal}
              toggleExportModal={toggleExportModal}
              showToastHandler={showToastHandler}
            />
          </HeaderCard>
          <HeaderCard
            title={view.viewName}
            enableTitleTooltip
            subtitle={
              !(
                view?.periodType === "CUSTOM_RANGE" ||
                view?.periodType === "DYNAMIC_DATE_RANGE"
              ) ? (
                getPeriodDisplayName(view.periodType)
              ) : (
                <>
                  {DateTime.fromFormat(view.startDate, "yyyy-MM-dd").toFormat(
                    "MM/dd/yyyy"
                  )}{" "}
                  -{" "}
                  {DateTime.fromFormat(view.endDate, "yyyy-MM-dd").toFormat(
                    "MM/dd/yyyy"
                  )}
                </>
              )
            }
            variant="primary"
          />
          <div className="brand-and-market-list">
            <EntityListImageView
              list={view.brands?.map((brand) => ({
                id: brand.id,
                image: brand.logo,
                name: brand.name,
              }))}
              keyToUseForTooltip={"name"}
            />
            <EntityListImageView
              list={view.markets?.map((market) => {
                let countryCode = getCountryCode(
                  market?.displayName,
                  market?.code
                );
                return {
                  id: market.id,
                  image:
                    countryCode !== "spiritDrinkers" ? (
                      <CircleFlag countryCode={countryCode} height={32} />
                    ) : (
                      <img src={spiritDrinkersLogo} alt="" />
                    ),
                  name: market.displayName,
                };
              })}
              keyToUseForTooltip={"name"}
            />
          </div>
        </div>
        <ViewCardFooter
          view={view}
          filters={view.filters}
          segments={view.segments}
        />
      </>
    );
  };

  return (
    <>
      {isDraggingItem ? (
        <StyledViewCard
          className={cx("view-card-main-container", {
            active: isChecked,
          })}
        >
          {renderViewCard()}
        </StyledViewCard>
      ) : (
        <StyledViewCard
          className={cx("view-card-main-container", {
            active: isChecked,
          })}
          {...listeners}
          {...attributes}
          ref={setNodeRef}
          style={style}
          onClick={handleCheckboxChange}
        >
          {renderViewCard()}
        </StyledViewCard>
      )}
    </>
  );
}

export default ViewCard;