import React, { useState } from "react";
import styled from "styled-components";
import Switch from "../widgets/Shared/Switch";
import ButtonWithDropdownOption from "../ButtonWithDropdownOption";
import ArrowIcon from "../../assets/icons/icon_arrow_white.svg";
import CustomTooltip from "../CustomTooltip";
import initData from "../../utils/initData";

const StyledStatTestingPopup = styled.ul`
  font-family: "Inter", sans-serif;
`;

const StyledStatTesting = styled.div`
  font-family: "Inter", sans-serif;
  .stat-testing-popup-container {
    width: 272px;
    padding: 8px;
  }
  ul {
    margin: 0px;
  }
  li {
    align-items: flex-start !important;
    white-space: break-spaces !important;
    height: 64px;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 8px !important;
    margin-bottom: 2px;
    user-select: none;
    gap: 8px !important;
    &:last-child {
      margin-bottom: 0px;
    }
    img {
      padding: 3px 6px 0px 6px;
    }
  }
  .active-stat-testing {
    border: 1px solid #7f56d9;
  }
  .statTesting {
    cursor: pointer;
    font-family: "Inter", sans-serif !important;
    font-weight: 600;
    font-size: 13px !important;
    user-select: none;
    padding: 8px 0px;
    gap: 12px;
  }
  .stat-testing-default-cursor {
    cursor: default;
  }
  .switch {
    margin-right: 0px !important;
  }
  .stat-testing-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
    color: #101828;
  }
  .stat-testing-description {
    font-size: 12px;
    font-weight: 400;
    color: #475467;
    line-height: 19px;
  }
  .stat-testing-arrow {
    transition: 0.4s all;
    transform: rotate(180deg);
  }
  .stat-testing-arrow-open {
    transform: rotate(0deg);
  }
  .stat-testing-disabled-message ul li {
    font-size: 12px;
    font-weight: 400;
    color: #475467;
  }
  .stat-testing-disabled-option {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

function StatTesting({
  statTestingEnabled,
  switchStatTesting,
  disabledYOYForStatTesting,
  disabledMessageForStatTesting,
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const STAT_TESTING_OPTIONS = initData.getStatTestingOptions();
  return (
    <StyledStatTesting>
      <ButtonWithDropdownOption
        className="stat-testing-popup-container"
        isDisabled={
          !(statTestingEnabled === "POP" || statTestingEnabled === "YOY")
        }
        trigger={
          <div
            className={
              statTestingEnabled === "POP" || statTestingEnabled === "YOY"
                ? "statTesting"
                : "statTesting stat-testing-default-cursor"
            }
          >
            <Switch
              checked={
                statTestingEnabled === "POP" || statTestingEnabled === "YOY"
              }
              onChange={() =>
                statTestingEnabled === "POP" || statTestingEnabled === "YOY"
                  ? switchStatTesting("NONE")
                  : switchStatTesting("POP")
              }
            />
            <span>
              Stat Testing{" "}
              {statTestingEnabled === "POP"
                ? `(Period-P)`
                : statTestingEnabled === "YOY"
                ? `(Year-Y)`
                : ""}
            </span>
            {(statTestingEnabled === "POP" || statTestingEnabled === "YOY") && (
              <img
                height={8}
                src={ArrowIcon}
                alt={"Arrow"}
                className={
                  isTooltipVisible
                    ? "stat-testing-arrow stat-testing-arrow-open"
                    : "stat-testing-arrow"
                }
              />
            )}
          </div>
        }
        onShow={() => {
          setIsTooltipVisible(true);
        }}
        onHide={() => {
          setIsTooltipVisible(false);
        }}
      >
        <StyledStatTestingPopup>
          {STAT_TESTING_OPTIONS?.map((option) =>
            disabledYOYForStatTesting && option.id === "YOY" ? (
              <CustomTooltip
                contentList={[disabledMessageForStatTesting]}
                placement="left"
                classname="stat-testing-disabled-message"
                offset={[0, 20]}
              >
                <li
                  key={option.id}
                  className={
                    option.id === statTestingEnabled
                      ? "active-stat-testing stat-testing-disabled-option"
                      : "stat-testing-disabled-option"
                  }
                >
                  <div>
                    <img height={18} src={option.icon} alt={option.title} />
                  </div>
                  <div>
                    <div className="stat-testing-title">{option.title}</div>
                    <div className="stat-testing-description">
                      {option.description}
                    </div>
                  </div>
                </li>
              </CustomTooltip>
            ) : (
              <li
                key={option.id}
                className={
                  option.id === statTestingEnabled ? "active-stat-testing" : ""
                }
                onClick={() => switchStatTesting(option.id)}
              >
                <div>
                  <img height={18} src={option.icon} alt={option.title} />
                </div>
                <div>
                  <div className="stat-testing-title">{option.title}</div>
                  <div className="stat-testing-description">
                    {option.description}
                  </div>
                </div>
              </li>
            )
          )}
        </StyledStatTestingPopup>
      </ButtonWithDropdownOption>
    </StyledStatTesting>
  );
}

export default StatTesting;
